"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetStakeProduct = void 0;
const index_1 = require("./index");
const assets_1 = require("../assets");
const YEarnStakeProduct_1 = require("./YEarnStakeProduct");
const GetStakeProduct = (product, wallet) => {
    const platformName = assets_1.PlatformMap[product.PlatformId].Platform;
    if (platformName) {
        switch (platformName) {
            case 'AAVE':
                return new index_1.AaveStakeProduct(product, wallet);
            case 'COMPOUND':
                return new index_1.CompoundStakeProduct(product, wallet);
            case 'YEARN':
                return new YEarnStakeProduct_1.YEarnStakeProduct(product, wallet);
            default:
                throw new Error(`Invalid stakeing platform, value: ${platformName}`);
        }
    }
    else {
        throw new Error(`No platform in product, productId: ${product.Id}`);
    }
};
exports.GetStakeProduct = GetStakeProduct;

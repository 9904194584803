"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLFiatRate = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLFiatRate {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLFiatRate created');
    }
    Add(r) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ctx.fiat_rate.create({
                data: {
                    Currency: r.Currency,
                    Spread: r.Spread,
                    Bid: 0,
                    Ask: 0,
                    Mid: 0,
                    EarnType: r.EarnType,
                    IconURL: r.IconURL,
                },
            });
        });
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ctx.fiat_rate.findUnique({
                where: { Id: id },
            });
        });
    }
    GetByCurrencies(currencies) {
        return __awaiter(this, void 0, void 0, function* () {
            const fiat = yield this.ctx.fiat_rate.findMany({
                where: { Currency: { in: currencies } },
            });
            return fiat;
        });
    }
    GetByCurrency(currency) {
        return __awaiter(this, void 0, void 0, function* () {
            const fiat = yield this.ctx.fiat_rate.findFirst({
                where: { Currency: currency },
            });
            return fiat;
        });
    }
    GetList() {
        return __awaiter(this, void 0, void 0, function* () {
            const fiats = yield this.ctx.fiat_rate.findMany();
            // console.log(fiats);
            return fiats;
        });
    }
    Update(fiatRate) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fiat_rate.update({
                where: { Id: fiatRate.Id },
                data: {
                    Bid: fiatRate.Bid,
                    Ask: fiatRate.Ask,
                    Mid: fiatRate.Mid,
                    Spread: fiatRate.Spread,
                    ValidSec: fiatRate.ValidSec,
                    EarnType: fiatRate.EarnType,
                },
            });
            return res;
        });
    }
    Delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ctx.fiat_rate.delete({
                where: { Id: id },
            });
        });
    }
    GetListByEarnType(earnType) {
        return __awaiter(this, void 0, void 0, function* () {
            const fiats = yield this.ctx.fiat_rate.findMany({
                where: {
                    EarnType: {
                        in: [earnType, types_1.EarnType.Both],
                    },
                },
            });
            return fiats;
        });
    }
}
exports.PrismaMySQLFiatRate = PrismaMySQLFiatRate;

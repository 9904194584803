import './options.css'
import { useContext, useEffect, useState } from 'react'
import WalletMono from '../../assets/images/wallet_off_black.svg'
import BankcardMono from '../../assets/images/bankcard-mono.svg'
import Metamask from '../../assets/images/metamask.svg'
import ArrowRightBlue from '../../assets/images/arrow-right-blue.svg'
import { Bankcard, KYCStatus, PaymentChannel } from 'depro.common'
import { maskCondential, trimAddress } from '../../common/utils'
import { useTranslation } from 'react-i18next'
import AppContext from '../../context/AppContext'
import { ManageCardModal } from '../ManageCard'
import { getBankcard } from '../../api/account.api'

interface IRedeemOptions {
  channel: PaymentChannel
  onSelectAccount: (account: string) => void
  onCurrencyChanged: (currency: string) => void
  onVerify: () => void
}

export const RedeemOptions = (props: IRedeemOptions) => {
  const { channel, onSelectAccount, onCurrencyChanged, onVerify } = props
  const { connectWallet, address, profile } = useContext(AppContext)
  const { t } = useTranslation(['redemption', 'common'])
  const [bankcards, setBankcards] = useState<Bankcard[]>([])
  const [openBankCard, setOpenBank] = useState(false)
  const [currentCard, setCurrentCard] = useState<Bankcard>()
  const [addCardMode, setAddCardMode] = useState(false)

  const selectOption = async (card?: Bankcard) => {
    switch (channel) {
      case PaymentChannel.Bank:
        if (card && card.Id) {
          onSelectAccount(card.Id.toString())
          if (card.Currency) onCurrencyChanged(card.Currency)
        } else {
          onSelectAccount('')
        }
        break
      case PaymentChannel.Crypto:
        if (address) {
          onSelectAccount(address)
        }
        break
      default:
        throw new Error(`Invalid channel ${channel}`)
    }
  }

  const fetchBankcards = async () => {
    const res: Bankcard[] = await getBankcard()
    if (res && res.length > 0) {
      setAddCardMode(false)
      setBankcards(res)
      setCurrentCard(res[0])
      if (res[0].Currency) onCurrencyChanged(res[0].Currency)
      if (res[0].Id && channel === PaymentChannel.Bank) {
        onSelectAccount(res[0].Id.toString())
      }
    } else {
      setAddCardMode(true)
    }
  }

  useEffect(() => {
    fetchBankcards()
  }, [])

  useEffect(() => {
    selectOption(currentCard)
  }, [channel])

  return (
    <div className="redeem-options-wrapper">
      <label>Redeem to</label>
      {/* //* Bank */}
      {channel === PaymentChannel.Bank && (
        <div className="time-wrapper">
          <div className="option bank">
            <img className="icon" src={BankcardMono} />
            {currentCard ? (
              <div className="account">
                {maskCondential(currentCard.Number)}
              </div>
            ) : (
              <div className="text">{t('card:Bank Card')}</div>
            )}
            <a
              href="#"
              className="action"
              onClick={() => {
                if (profile?.KYCStatus === KYCStatus.Success) {
                  setOpenBank(true)
                } else {
                  onVerify()
                }
              }}
            >
              {bankcards.length === 0 && (
                <div className="change">{t('card:Add card')}</div>
              )}
              {bankcards.length > 0 && !currentCard && (
                <div className="change">{t('card:Choose card')}</div>
              )}
              {bankcards.length > 0 && currentCard && (
                <div className="change">Change card</div>
              )}
              <img src={ArrowRightBlue} />
            </a>
          </div>
          <div className="bank-time">T+1</div>
        </div>
      )}
      {channel === PaymentChannel.Crypto && (
        <div className="option bank">
          {!address && (
            <>
              <img className="icon" src={WalletMono} />
              <div className="text">{t('Crypto Wallet')}</div>
              <a href="#" className="action" onClick={() => connectWallet()}>
                <div className="change">{t('Connect wallet')}</div>
              </a>
            </>
          )}

          {address && (
            <>
              <img className="icon" src={Metamask} />
              <div className="account">{trimAddress(address)}</div>
              <a href="#" className="action" onClick={() => connectWallet()}>
                <div className="change">{t('Switch wallet')}</div>
              </a>
            </>
          )}
          <img src={ArrowRightBlue} />
        </div>
      )}

      <ManageCardModal
        add={addCardMode}
        open={openBankCard}
        currentCardId={currentCard?.Id}
        onClose={() => setOpenBank(false)}
        onSelect={(card) => {
          setCurrentCard(card as Bankcard)
          selectOption(card as Bankcard)
          if (card && !bankcards.find((f) => f.Id === card?.Id)) {
            setBankcards([...bankcards, card as Bankcard])
          }
        }}
        onRemove={(cardId) => {
          setBankcards(bankcards.filter((f) => f.Id !== cardId))
          if (currentCard?.Id === cardId) {
            setCurrentCard(undefined)
          }
        }}
        embeddedHeight="480px"
        back
      />
    </div>
  )
}

import { DashboardHeader } from './dashboard.header'
import { Tab } from 'semantic-ui-react'
import { MyAssets } from './assets'
import { Fund } from './fund'
import './dashboard.css'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { DashboardDisconnected } from './disconnected'
import { useTranslation } from 'react-i18next'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { SubscriptionHistory } from './subscriptionHistory'
import { RedemptionHistory } from './redemptionHistory'
import { getFiatRates, getTokenRates } from '../../api/exrate.api'
import { Earning, EarnGroup } from 'depro.common'
import { getUserEarnings, getUserRedeeming } from '../../api/earn.api'
import { DeproHeader } from '../../components'
import StakeOption from "../../assets/images/stake-option.svg"
import VaultsOption from "../../assets/images/vaults-option.svg"

export const Dashboard = () => {
  const [searchPara, _] = useSearchParams()
  const navigate = useNavigate()
  const view = searchPara.get('view')
  const { t } = useTranslation('dashboard')
  const { profile } = useContext(AppContext)
  const [earningRecord, setEarningRecord] = useState<Earning[]>([])
  const [tokenRateMap, setTokenRateMap] = useState<Record<string, number>>({})
  const [fiatRateMap, setFiatRateMap] = useState<Record<string, number>>({})
  const [totalEarningsUsd, setTotalEarningsUsd] = useState<{
    balance?: number,
    earning?: number
  }>({ balance: undefined, earning: undefined })
  const [redeeming, setRedeeming] = useState<number[]>([])
  const [group, setGroup] = useState(EarnGroup.Stake)

  const groupOptions = [
    {
      key: EarnGroup.Stake,
      text: t("Stake"),
      icon: StakeOption
    },
    {
      key: EarnGroup.Vault,
      text: t("Vaults"),
      icon: VaultsOption
    }
  ]

  const fetchRedeeming = async () => {
    try {
      const res = await getUserRedeeming()
      setRedeeming(res)
    } catch (e) {
      console.error('Exception fetchRedeeming', e)
    }
  }

  const fetchRates = async () => {
    try {
      const [resToken, resFiat] = await Promise.all([
        getTokenRates([], true),
        getFiatRates(undefined, true),
      ])
      if (resToken && !resToken.error) {
        setTokenRateMap(resToken)
      } else {
        console.error(resToken.error)
      }

      if (resFiat && !resFiat.error) {
        setFiatRateMap(resFiat)
      } else {
        console.error(resFiat.error)
      }
    } catch (e) {
      console.error('No fetch rates', e)
    }
  }

  const fetchEarnings = async () => {
    try {
      const res = await getUserEarnings(group)
      if (res && !res.error) {
        setEarningRecord(res)
      } else {
        console.error(res.error)
      }
    } catch (e) {
      console.error('No earnings', e)
    }
  }

  useEffect(() => {
    if (earningRecord && tokenRateMap) {
      const balanceUsd = earningRecord.reduce(
        (total: number, r: Earning) =>
          total +
          Number(r.ATokenBalance) / tokenRateMap[r.products?.TokenName!],
        0
      )

      const earnUsd = earningRecord.reduce(
        (total: number, r: Earning) =>
          total + Number(r.InterestEarned) / tokenRateMap[r.products?.TokenName!],
        0
      )

      setTotalEarningsUsd({
        balance: balanceUsd,
        earning: earnUsd
      })
    }
  }, [earningRecord, tokenRateMap])

  useEffect(() => {
    if (profile) {
      fetchEarnings()
      fetchRates()
      fetchRedeeming()
    }
  }, [profile, group])

  const panes = [
    {
      menuItem: t('My Assets'),
      render: () => (
        <Tab.Pane attached={false}>
          <div className='asset-group-filter'>
            {
              groupOptions.map(({ key, text, icon }) => {
                return (
                  <div
                    role='button'
                    key={key}
                    className={group === key ? "active" : "inactive"}
                    onClick={() => {
                      setGroup(key)
                    }}>
                    <img src={icon} />
                    <p>{text}</p>
                  </div>
                )
              })
            }
          </div>
          {profile &&
            <MyAssets
              earnings={earningRecord}
              tokenRates={tokenRateMap}
              redeemingProductIds={redeeming}
              reload={() => {
                fetchRedeeming()
                fetchEarnings()
              }}
            />}

        </Tab.Pane>
      ),
      view: 'assets',
    },
    {
      menuItem: t('Subscription History'),
      render: () => (
        <Tab.Pane attached={false}>
          {profile && <SubscriptionHistory
            fiatRates={fiatRateMap}
            tokenRates={tokenRateMap}
          />
          }
        </Tab.Pane>
      ),
      view: 'subscription',
    },
    {
      menuItem: t('Redemption History'),
      render: () => (
        <Tab.Pane attached={false} className=''>
          {profile && <RedemptionHistory
            tokenRates={tokenRateMap}
            fiatRates={fiatRateMap}
          />}
        </Tab.Pane>
      ),
      view: 'redemption',
    },
    {
      menuItem: t('Fund Record'),
      render: () => (
        <Tab.Pane attached={false}>
          {profile && <Fund fiatRates={fiatRateMap} tokenRates={tokenRateMap} />}
        </Tab.Pane>
      ),
      view: 'fund',
    },
  ]

  return (
    <div className="dashboard app-bg">
      <div className="app-padding">
        <DeproHeader />
        <DashboardHeader
          balance={totalEarningsUsd.balance}
          earning={totalEarningsUsd.earning}
        />
        <Tab
          renderActiveOnly
          className="views"
          menu={{ secondary: true }}
          panes={panes}
          activeIndex={view ? panes.findIndex((f) => f.view === view) : 0}
          onTabChange={(_, v) => {
            const toView = panes[Number(v.activeIndex || 0)].view
            const time = Date.now()
            navigate({ pathname: '/dashboard', search: `?view=${toView}&time=${time}` })
          }}
        />

        {!profile && <DashboardDisconnected />}
      </div>
    </div>
  )
}

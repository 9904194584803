import { providers } from 'ethers'
import AppContext from './AppContext'
import { useEffect, useState, useCallback } from 'react'
import { ISwapProvider } from '../swaps/ISwap'
import { Profile, Trade } from '../common/types'
import { IToken, User } from 'depro.common'
import { getChainData } from '../common/utils'
import { useWeb3Modal } from '../hooks/useWeb3Modal'
import useSessionStorage from '../hooks/useSessionStorage'
import { getAuthUser } from '../api/auth.api'

const AppProvider = (props: any) => {
  const [provider, setProvider] = useState<any>()
  const [web3Provider, setWeb3Provider] = useState<any>()
  const [address, setAddress] = useState<string | undefined>()
  const [chainId, setChainId] = useState<number | undefined>()
  const [swapProvider, setSwapProvider] = useState<ISwapProvider | undefined>()
  const [tokens, setTokens] = useState<IToken[]>([])
  const [nativeCurrency, setNativeCurrency] = useState<IToken | undefined>()
  const [wrapNativeCurrency, setWrapNativeCurrency] = useState<
    IToken | undefined
  >()
  const [user, setUser] = useState<User | undefined>()
  const [profile, setProfile] = useState<Profile | null>(null)
  const { web3Modal } = useWeb3Modal()
  const [sessionProfile, setSessionProfile] = useSessionStorage('profile', null)
  const [trade, setTrade] = useState<Trade>()

  useEffect(() => {
    const INFURA_ID = process.env.REACT_APP_INFURA_ID
    const chainData = getChainData(chainId, INFURA_ID)

    if (chainId && web3Provider) {
      setNativeCurrency({
        address: chainData?.native_currency.contractAddress || 'ETH',
        name: chainData?.native_currency?.name || 'ETH',
        symbol: chainData?.native_currency?.symbol || 'ETH',
        decimals: Number(chainData?.native_currency.decimals) || 18,
      })
      setWrapNativeCurrency({
        address: chainData?.wrap_native_currency?.contractAddress || 'WETH',
        name: chainData?.wrap_native_currency?.name || 'Wrapped ETH',
        symbol: chainData?.wrap_native_currency?.symbol || 'WETH',
        decimals: Number(chainData?.wrap_native_currency?.decimals) || 18,
      })
    } else {
      setNativeCurrency(undefined)
      setWrapNativeCurrency(undefined)
    }
  }, [chainId, web3Provider])

  const connectWallet = useCallback(
    async (isAutoConnect = false) => {
      if (web3Modal) {
        let provider: any = null
        try {
          if (!isAutoConnect) web3Modal.clearCachedProvider()
          provider = await web3Modal.connect()
        } catch (e) {
          //! when use not select nothing from provider list
          console.warn('connectWallet exception', e)
          return
        }
        const newWeb3Provider = new providers.Web3Provider(provider)
        const signer = newWeb3Provider.getSigner()
        const address = await signer.getAddress()
        const network = await newWeb3Provider.getNetwork()
        setProvider(provider)
        setWeb3Provider(newWeb3Provider)
        setAddress(address)
        setChainId(network.chainId)
      }
    },
    [
      setProvider,
      setWeb3Provider,
      setAddress,
      setChainId,
      setNativeCurrency,
      web3Modal,
    ]
  )

  const disconnectWallet = useCallback(async () => {
    await web3Modal?.clearCachedProvider()
    if (provider?.disconnect && typeof provider.disconnect === 'function') {
      await provider.disconnect()
    }
    setProvider(null)
    setWeb3Provider(null)
    setAddress(undefined)
    setChainId(undefined)
  }, [
    provider,
    setProvider,
    setWeb3Provider,
    setAddress,
    setChainId,
    web3Modal,
  ])

  const logout = () => {
    setProfile(null)
    setSessionProfile(null)
    disconnectWallet()
  }

  useEffect(() => {
    if (web3Modal?.cachedProvider && profile) {
      connectWallet(true)
    }
  }, [web3Modal, profile])

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.info('web3 accounts changed', accounts)
        setAddress(accounts[0])
      }
      const handleChainChanged = (_hexChainId: string) => {
        console.info("chain changed", _hexChainId)
        window.location.reload()
      }

      const handleDisconnect = (error: { code: number; message: string }) => {
        console.info('web3 account disconnected', error)
        // disconnectWallet()
      }
      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnectWallet, connectWallet])

  const initProfile = async () => {
    if (sessionProfile) {
      const res = await getAuthUser()
      if (res) {
        setProfile(sessionProfile)
      } else {
        logout()
      }
    } else {
      disconnectWallet()
    }
  }

  useEffect(() => {
    if (profile) {
      setSessionProfile(profile)
    }
  }, [profile])

  useEffect(() => {
    initProfile()
  }, [])

  return (
    <AppContext.Provider
      value={{
        user,
        provider,
        web3Provider,
        address,
        chainId,
        setProvider,
        setWeb3Provider,
        setAddress,
        setChainId,
        swapProvider,
        setSwapProvider,
        tokens,
        setTokens,
        nativeCurrency,
        setNativeCurrency,
        wrapNativeCurrency,
        setWrapNativeCurrency,
        setUser,
        connectWallet,
        disconnectWallet,
        profile,
        setProfile,
        logout,
        trade,
        setTrade,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider

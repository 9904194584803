"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EtherScanServices = void 0;
const AbsNetworkScan_1 = require("./AbsNetworkScan");
class EtherScanServices extends AbsNetworkScan_1.AbsNetworkScan {
    constructor(chainId, etherKey) {
        super(chainId, etherKey);
        switch (chainId) {
            case 1:
                this.endpoint = `https://api.etherscan.io`;
                break;
            case 5:
                this.endpoint = `https://api-goerli.etherscan.io`;
                break;
            default:
                throw new Error(`Invalid chain id for etherscan, chainid: ${chainId}`);
        }
    }
}
exports.EtherScanServices = EtherScanServices;

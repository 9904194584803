import './icoButton.css'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import { trimAddress } from '../../common/utils'
import { useTranslation } from 'react-i18next'

interface ConnectWalletButtonProp {
  theme: 'primary' | 'transparent'
}

export const ConnectWalletButton = (prop: ConnectWalletButtonProp) => {
  const { theme } = prop
  const { t } = useTranslation('common')
  const { address, web3Provider, disconnectWallet, connectWallet } =
    useContext(AppContext)
  return (
    <div
      className={'connect-wallet-wrapper ' + theme}
      onClick={() => {
        if (!web3Provider) connectWallet()
      }}
    >
      <div className={'icon ' + (web3Provider ? 'on' : 'off')}></div>
      <div className="text">
        {web3Provider && address ? trimAddress(address) : t('Connect wallet')}
      </div>
      {web3Provider && (
        <div className={'icon minus'} onClick={disconnectWallet}></div>
      )}
    </div>
  )
}

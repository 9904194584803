import { useEffect, useContext } from 'react'
import { GoogleLogin } from 'react-google-login'
import { gapi } from 'gapi-script'
import { authGoogle } from '../../../api/auth.api'
import AppContext from '../../../context/AppContext'
import { ProfileType } from '../../../common/enums'

export const GoogleLoginButton = ({ text }: { text: string }) => {
  const { setProfile } = useContext(AppContext)
  const { REACT_APP_GOOGLE_CLIENT_ID } = process.env
  const onSuccess = async (res: any) => {
    console.info('Login success! Current user: ', res.profileObj)
    const result = await authGoogle(res.tokenId)
    // console.debug('token:', result)
    setProfile({
      Type: ProfileType.Google,
      Name: result.Email,
      DeproId: result.Id,
      KYCStatus: result.KYCStatus,
      Token: result.Token,
      Email: result.Email,
    })
  }

  const onFailure = (res: any) => {
    console.error('Login failed! res:', res)
  }

  useEffect(() => {
    function start() {
      gapi.client.init({ clientId: REACT_APP_GOOGLE_CLIENT_ID, scope: '' })
    }

    gapi.load('client:auth2', start)
  }, [])

  return (
    <>
      <GoogleLogin
        clientId={REACT_APP_GOOGLE_CLIENT_ID!}
        buttonText={text}
        onSuccess={onSuccess}
        onFailure={onFailure}
        isSignedIn={false}
      />
    </>
  )
}

import './pending.css'
import { Button, Modal } from 'semantic-ui-react'
import { IToken, PaymentChannel } from 'depro.common'

import { RedemptionData } from '.'
import { useTranslation } from 'react-i18next'
import RedeemPending from '../../assets/images/redeem-pending.svg'
import { maskCondential, ToFixNum } from '../../common/utils'

interface IRedemptionPending {
  inputData: RedemptionData
  onClose: () => void
}

export const RedemptionPending = (props: IRedemptionPending) => {
  const { inputData, onClose } = props
  const { t } = useTranslation(['redemption', 'common'])

  // fetch rates here?
  return (
    <>
      <Modal.Header>
        <div className="title">
          <div>Confirm redemption</div>
        </div>
        <div className="btn-modal-close" onClick={onClose} />
      </Modal.Header>

      <Modal.Content>
        <div className="redemption-modal pending">
          <div className="status">
            <img className="icon" src={RedeemPending} />
            <div className="text">{t('Your redemption is pending')}</div>
            <div className="desc">
              {t('You can get your assets in your account in ')}
              {inputData.channel === PaymentChannel.Bank
                ? t('T+1')
                : t('2 hours')}
            </div>
          </div>
          <div className="stats">
            <div className="item">
              <div className="label">{t('Redeem to')}</div>
              <div className="value">
                {inputData.channel === PaymentChannel.Bank
                  ? t('Bank card')
                  : t('Crypto wallet')}{' '}
                | <strong>{maskCondential(inputData.account)}</strong>
              </div>
            </div>
            <div className="item">
              <div className="label">{t('Funds returned time')}</div>
              <div className="value">
                {inputData.channel === PaymentChannel.Bank
                  ? t('T+1')
                  : t('In 2 hours')}
              </div>
            </div>
            <div className="item total">
              <div className="label">{t('You will receive')}</div>
              <div className="value">
                ${ToFixNum(inputData.receiveAmount)}{' '}
                {inputData.channel === PaymentChannel.Bank
                  ? inputData.currency.toString()
                  : (inputData.currency as IToken).name}
              </div>
            </div>
          </div>
          <div className="action">
            <Button className="primary" onClick={onClose}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Content>
    </>
  )
}

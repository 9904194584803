import './payment.status.css'
import { Button, Modal } from 'semantic-ui-react'
import { Trade } from '../../common/types'
import { TokenAmount } from '../../components/TokenAmount'
import TxnPending from '../../assets/images/txn-pending.svg'
import { useTranslation } from 'react-i18next'

interface IPaymentStatus {
  trade: Trade
  open: boolean
  onClose: () => void
}

export const PaymentStatus = (props: IPaymentStatus) => {
  const { t } = useTranslation(['payment', 'common'])
  const { trade, open, onClose } = props
  const currentTime = new Date()

  return (
    <Modal
      onClose={() => onClose()}
      open={open}
      className="payment-status"
      size="tiny"
    >
      <Modal.Header>
        <div className="btn-modal-close" onClick={onClose} />
      </Modal.Header>
      <Modal.Content className="content">
        <div className="main">
          <div className="status">
            <img src={TxnPending} className="icon" />
            <div className="title">{t('Your Subscription is pending')}</div>
          </div>
          <div className="details">
            <TokenAmount
              icon={trade.proudctData.IconURL}
              tokenName={trade.proudctData.TokenName}
              chainId={trade.proudctData.ChainId}
              amount={trade.receiveAmount}
              amountUsd={trade.receiveAmountUsd}
            />
          </div>
        </div>
        <div className="time">
          <div className="label">{t('Subscription time')}</div>
          <div className="value">{currentTime.toLocaleString()}</div>
        </div>
        <div className="actions">
          <Button className="primary btn" onClick={onClose}>
            {t('common:Close')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
}

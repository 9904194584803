"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbsNetworkScan = void 0;
const utils_1 = require("../common/utils");
class AbsNetworkScan {
    constructor(chainId, key) {
        this.chainId = chainId;
        this.apikey = key;
        this.endpoint = '';
    }
    ProxyBlockNumber() {
        return __awaiter(this, void 0, void 0, function* () {
            const reqUrl = `${this.endpoint}/api?module=proxy&action=eth_blockNumber&apikey=${this.apikey}`;
            const data = yield (0, utils_1.AxiosRequest)(reqUrl);
            if (data.result) {
                return Number(data.result);
            }
            else {
                throw new Error(`Error getting proxy block number, endpoint: ${this.endpoint}`);
            }
        });
    }
    GetBlockByTs(ts, closest = 'before') {
        return __awaiter(this, void 0, void 0, function* () {
            const reqUrl = `${this.endpoint}/api?module=block&action=getblocknobytime` +
                `&timestamp=${ts}&closest=${closest}&apikey=${this.apikey}`;
            const data = yield (0, utils_1.AxiosRequest)(reqUrl);
            if (Number(data.status) === 1) {
                return Number(data.result);
            }
            else {
                throw new Error(`Error getting block number by timestamp: ${ts}, endpoint: ${this.endpoint}`);
            }
        });
    }
    GetAccountTransaction(address, option) {
        return __awaiter(this, void 0, void 0, function* () {
            const fromBlock = option.startBlock || 0;
            const endBlock = option.endBlock || 99999999;
            const page = option.page || 1;
            const offset = option.offset || 10000;
            const action = option.erc20 ? 'tokentx' : 'txlist';
            const reqUrl = `${this.endpoint}/api?module=account&action=${action}&address=${address}` +
                `&startblock=${fromBlock}&endBlock=${endBlock}&page=${page}&offset=${offset}&sort=desc&apikey=${this.apikey}`;
            const data = yield (0, utils_1.AxiosRequest)(reqUrl);
            const result = [];
            if (data && Number(data.status) === 1 && data.result) {
                data.result.forEach((txn) => {
                    result.push(txn);
                });
                return result;
            }
            else {
                return [];
            }
        });
    }
    GetIndexed(address, option) {
        return __awaiter(this, void 0, void 0, function* () {
            const fromBlock = option.startBlock || 0;
            const endBlock = option.endBlock || 99999999;
            const page = option.page || 1;
            const offset = option.offset || 10000;
            const action = option.erc20 ? 'tokentx' : 'txlist';
            const reqUrl = `${this.endpoint}/api?module=account&action=${action}&address=${address}` +
                `&startblock=${fromBlock}&endBlock=${endBlock}&page=${page}&offset=${offset}&sort=desc&apikey=${this.apikey}`;
            const data = yield (0, utils_1.AxiosRequest)(reqUrl);
            const result = [];
            if (data && Number(data.status) === 1 && data.result) {
                data.result.forEach((txn) => {
                    result.push(txn);
                });
                return result;
            }
            else {
                return [];
            }
        });
    }
}
exports.AbsNetworkScan = AbsNetworkScan;

import './message.css'
import { useTranslation } from 'react-i18next'
import WalletIcon from '../../assets/images/wallet.svg'
import { Button } from 'semantic-ui-react'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'

export const ConnectWalletMessage = () => {
  const { web3Provider, connectWallet } = useContext(AppContext)
  const { t } = useTranslation('common')
  return (
    <div className="connect message-wrapper">
      <img className="icon" src={WalletIcon} />
      <div className="text">{t('Please, connect your wallet')}</div>

      {!web3Provider && (
        <Button primary type="button" onClick={() => connectWallet()}>
          {t('Connect wallet')}
        </Button>
      )}
    </div>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLProducts = void 0;
const __1 = require("../..");
class PrismaMySQLProducts {
    constructor(dbContext) {
        this.Add = (r) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.products.create({
                data: {
                    ChainId: r.ChainId,
                    TokenName: r.TokenName,
                    TokenAddress: r.TokenAddress,
                    TokenDecimal: r.TokenDecimal,
                    ContractAddress: r.ContractAddress,
                    PlatformId: r.PlatformId,
                    ATokenAddress: r.ATokenAddress,
                    ATokenDecimal: r.ATokenDecimal,
                    SubscriptionCharge: r.SubscriptionCharge,
                    RedemptionCharge: r.RedemptionCharge,
                    Status: r.Status,
                    IconURL: r.IconURL,
                },
            });
            return res;
        });
        this.Update = (r) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.products.update({
                where: {
                    Id: r.Id,
                },
                data: {
                    TokenName: r.TokenName,
                    TokenAddress: r.TokenAddress,
                    TokenDecimal: r.TokenDecimal,
                    ContractAddress: r.ContractAddress,
                    ATokenAddress: r.ATokenAddress,
                    ATokenDecimal: r.ATokenDecimal,
                    SubscriptionCharge: r.SubscriptionCharge,
                    RedemptionCharge: r.RedemptionCharge,
                    Status: r.Status,
                    IconURL: r.IconURL,
                },
            });
            return res;
        });
        this.Delete = (id) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ctx.products.delete({
                    where: { Id: Number(id) },
                });
                return true;
            }
            catch (e) {
                console.error('Exception in delete product', e);
            }
            return false;
        });
        this.GetListCount = (options) => __awaiter(this, void 0, void 0, function* () {
            const { chainIds, platformId, platform, version, earnGroup, status, statusExclude, tokenName, isReservedToken, } = options;
            let platformPattern = undefined;
            if (!platformId) {
                if (platform) {
                    platformPattern = `-${platform}`;
                }
                if (version) {
                    platformPattern = platformPattern
                        ? platformPattern + `-${version}`
                        : `-${version}`;
                }
            }
            // earn group
            const groupPlatform = (0, __1.GetEarnGroupPlatforms)(earnGroup);
            const earnGroupCondition = groupPlatform === null || groupPlatform === void 0 ? void 0 : groupPlatform.map((platform) => {
                return { PlatformId: { contains: `-${platform}-` } };
            });
            const products = yield this.ctx.products.count({
                where: {
                    AND: [
                        {
                            ChainId: { in: chainIds },
                            Status: { in: status, notIn: statusExclude },
                            PlatformId: platformId,
                            TokenName: tokenName,
                            ReservedToken: isReservedToken,
                        },
                        {
                            PlatformId: {
                                contains: platformPattern,
                            },
                        },
                        {
                            OR: earnGroupCondition,
                        },
                    ],
                },
            });
            return products || 0;
        });
        this.BulkUpdateStatus = (statuts) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.$transaction(statuts.map((m) => {
                return this.ctx.products.update({
                    where: {
                        Id: m.ProductId,
                    },
                    data: {
                        Status: m.Status,
                    },
                });
            }));
            return res.length;
        });
        this.GetReservedProduct = (chainId) => __awaiter(this, void 0, void 0, function* () {
            return this.ctx.products.findFirst({
                where: {
                    ChainId: chainId,
                    ReservedToken: true,
                },
                include: {
                    product_balance: true,
                },
            });
        });
        this.GetbyChain = (chainId) => __awaiter(this, void 0, void 0, function* () {
            return this.ctx.products.findMany({
                where: {
                    ChainId: chainId,
                },
            });
        });
        this.UpdateReserved = (id, reserveStatus) => __awaiter(this, void 0, void 0, function* () {
            return this.ctx.products.update({
                where: { Id: id },
                data: { ReservedToken: reserveStatus },
            });
        });
        this.GetByAddresses = (tokenAddress, contractAddress, aTokenAddress) => __awaiter(this, void 0, void 0, function* () {
            return this.ctx.products.findFirst({
                where: {
                    TokenAddress: tokenAddress,
                    ContractAddress: contractAddress,
                    ATokenAddress: aTokenAddress,
                },
            });
        });
        this.ctx = dbContext;
        console.log('PrismaMySQLProduct created');
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const maxLenTime = new Date().getTime() - 60 * 24 * 60 * 60 * 1000; //60days only
            const product = yield this.ctx.products.findUnique({
                where: { Id: Number(id) },
                include: {
                    apy_log: {
                        where: {
                            Timestamp: {
                                gte: new Date(maxLenTime),
                            },
                        },
                        orderBy: {
                            Timestamp: 'desc',
                        },
                    },
                    product_market: true,
                },
            });
            if (product) {
                return product;
            }
            return null;
        });
    }
    GetByIds(ids) {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield this.ctx.products.findMany({
                where: {
                    Id: { in: ids },
                },
                include: {
                    apy_log: {
                        orderBy: {
                            Timestamp: 'desc',
                        },
                    },
                },
            });
            return results;
        });
    }
    GetByChainIdAndTokenName(chainId, tokenName) {
        return __awaiter(this, void 0, void 0, function* () {
            const product = yield this.ctx.products.findFirst({
                where: { ChainId: chainId, TokenName: tokenName },
            });
            if (product) {
                return product;
            }
            return null;
        });
    }
    GetAll() {
        return __awaiter(this, void 0, void 0, function* () {
            const products = yield this.ctx.products.findMany();
            return products;
        });
    }
    GetReserveList(chainIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const reserves = yield this.ctx.products.findMany({
                where: { ChainId: { in: chainIds }, ReservedToken: true },
            });
            return reserves;
        });
    }
    GetListSortBalance(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { chainIds, status, offset, size, sortByBalance, platformId, tokenName, isReservedToken, } = options;
            let platformPattern = undefined;
            if (platformId) {
                platformPattern = `-${platformId}-`;
            }
            let query = {
                skip: offset,
                take: size,
                orderBy: {},
                where: {
                    ChainId: { in: chainIds },
                    Status: { in: status },
                    PlatformId: { contains: platformPattern },
                    TokenName: tokenName,
                    ReservedToken: isReservedToken,
                },
                include: {
                    product_balance: true,
                },
            };
            if (!sortByBalance) {
                query.orderBy = { Id: 'desc' };
            }
            else {
                query.orderBy = {
                    product_balance: { TokenBalance: sortByBalance ? 'desc' : undefined },
                };
            }
            return yield this.ctx.products.findMany(query);
        });
    }
    GetList(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { chainIds, status, offset, size, sortByApy, sortBySupply, sortByPrice, platformId, tokenName, isReservedToken, platform, version, earnGroup, } = options;
            let platformPattern = undefined;
            if (!platformId) {
                if (platform) {
                    platformPattern = `-${platform}`;
                }
                if (version) {
                    platformPattern = platformPattern
                        ? platformPattern + `-${version}`
                        : `-${version}`;
                }
            }
            // earn group
            const groupPlatform = (0, __1.GetEarnGroupPlatforms)(earnGroup);
            const earnGroupCondition = groupPlatform === null || groupPlatform === void 0 ? void 0 : groupPlatform.map((platform) => {
                return { PlatformId: { contains: `-${platform}-` } };
            });
            const marketOrder = sortByPrice
                ? { UnitPriceUSD: sortByPrice }
                : sortByApy
                    ? { Apy: sortByApy }
                    : sortBySupply
                        ? { SupplyUSD: sortBySupply }
                        : undefined;
            return yield this.ctx.products.findMany({
                skip: offset,
                take: size,
                orderBy: {
                    product_market: marketOrder,
                    Id: sortByPrice || sortByApy || sortBySupply ? undefined : 'desc',
                },
                where: {
                    AND: [
                        {
                            ChainId: { in: chainIds },
                            Status: { in: status },
                            PlatformId: platformId,
                            TokenName: tokenName,
                            ReservedToken: isReservedToken,
                        },
                        {
                            PlatformId: {
                                contains: platformPattern,
                            },
                        },
                        {
                            // with earn group
                            OR: earnGroupCondition,
                        },
                    ],
                },
                include: {
                    apy_log: {
                        distinct: 'ProductId',
                        orderBy: {
                            Timestamp: 'desc',
                        },
                    },
                    product_balance: true,
                    product_market: true,
                },
            });
        });
    }
}
exports.PrismaMySQLProducts = PrismaMySQLProducts;

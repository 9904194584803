import { useTranslation } from 'react-i18next'
import './details.faq.css'

const PlatformFaqKeys: Record<string, { q: string, a: string }[]> = {
  "AAVE": [
    { q: "FAQ-What-Apy", a: "FAQ-What-Apy-Desc" },
    { q: "FAQ-Revenue", a: "FAQ-Revenue-Desc" },
    { q: "FAQ-Rdm-Rule", a: "FAQ-Rdm-Rule-Desc" },
    { q: "FAQ-AAVE-What", a: "FAQ-AAVE-What-Desc" },
    { q: "FAQ-AAVE-Why-Earn", a: "FAQ-AAVE-Why-Earn-Desc" },
    { q: "FAQ-AAVE-Risk", a: "FAQ-AAVE-Risk-Desc" },
  ],
  "COMPOUND": [
    { q: "FAQ-What-Apy", a: "FAQ-What-Apy-Desc" },
    { q: "FAQ-Revenue", a: "FAQ-Revenue-Desc" },
    { q: "FAQ-Rdm-Rule", a: "FAQ-Rdm-Rule-Desc" },
    { q: "FAQ-COMPOUND-What", a: "FAQ-COMPOUND-What-desc" },
    { q: "FAQ-COMPOUND-Why-Earn", a: "FAQ-COMPOUND-Why-Earn-Desc" },
    { q: "FAQ-COMPOUND-Risk", a: "FAQ-COMPOUND-Risk-Desc" },
  ],
  "INDEX_FUND": [
    { q: "FAQ-IndexFund-What", a: "FAQ-IndexFund-What-Desc" },
    { q: "FAQ-IndexFund-How", a: "FAQ-IndexFund-HowDesc" },
    { q: "FAQ-Rdm-Rule", a: "FAQ-Rdm-Rule-Desc" },
    { q: "FAQ-IndexFund-Yield", a: "FAQ-IndexFund-Yield-Desc" },
    { q: "FAQ-IndexFund-Risk", a: "FAQ-IndexFund-Risk-Desc" },
  ],
  "YEARN": [
    { q: "FAQ-Yearn-What", a: "FAQ-Yearn-What-Desc" },
    { q: "FAQ-Yearn-How", a: "FAQ-Yearn-How-Desc" },
    { q: "FAQ-Rdm-Rule", a: "FAQ-Rdm-Rule-Desc" },
    { q: "FAQ-Yearn-yv", a: "FAQ-Yearn-yv-Desc" },
    { q: "FAQ-Yearn-Revenue", a: "FAQ-Yearn-Revenue-Desc" },
    { q: "FAQ-Yearn-Risk", a: "FAQ-Yearn-Risk-Desc" },
  ]
}

export const ProductDetailsFaq = ({ platform }: { platform: string }) => {
  const { t } = useTranslation('faq')

  const faq = PlatformFaqKeys[platform]

  return (
    <div className="details-faq">
      <h2>FAQs</h2>
      <ul>
        {
          faq && faq.map(m => (
            <li key={m.q}>
              <details>
                <summary>
                  <div>{t(m.q)}</div>
                  <div className='toggle'>&nbsp;</div>
                </summary>
                <div dangerouslySetInnerHTML={{ __html: t(m.a) }}></div>
              </details>
            </li>
          ))
        }
      </ul>
    </div>
  )
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolygonScanServices = void 0;
const AbsNetworkScan_1 = require("./AbsNetworkScan");
class PolygonScanServices extends AbsNetworkScan_1.AbsNetworkScan {
    constructor(chainId, polygonKey) {
        super(chainId, polygonKey);
        switch (chainId) {
            //polygon mainnet
            case 137:
                this.endpoint = `https://api.polygonscan.com`;
                break;
            // testnet polygon
            case 80001:
                this.endpoint = `https://api-mumbai.polygonscan.com`;
                break;
            default:
                throw new Error(`Invalid chain id for polygonscan, chainid: ${chainId}`);
        }
    }
}
exports.PolygonScanServices = PolygonScanServices;

import { request } from './request.api'
import { FiatPaymentParams, DefiPaymentParams } from '../common/types'

export const getWalletAddress = async (
  chainId: number
): Promise<{ address: string }> => {
  const res = await request(`/earn/walletAddress/${chainId}`)
  return res
}

export const stakeByCrypto = async (defiPaymentDetail: DefiPaymentParams) => {
  const res = await request(`/earn/stake/crypto`, {
    method: 'POST',
    body: JSON.stringify(defiPaymentDetail),
  })
  return res
}

export const stakeByFiat = async (paymentDetail: FiatPaymentParams) => {
  const res = await request(`/earn/stake/fiat`, {
    method: 'POST',
    body: JSON.stringify(paymentDetail),
  })
  return res
}

export const getSufficientBalance = async (
  productId: number,
  amount: number
) => {
  return true
  const res = await request(`/earn/walletBalance`, {
    method: 'POST',
    body: JSON.stringify({
      amount,
      productId,
    }),
  })
  return res
}

import './allProducts.css'
import { useState, useEffect } from 'react'
import {
  getPagedProductInfo,
  getAllProductCount,
  getChainData
} from '../../../api/product.api'
import { useNavigate } from 'react-router-dom'
import { Button, Loader, Table, Pagination, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SortAsc } from '../../../assets/images/sort-asc.svg'
import { ReactComponent as SortDesc } from '../../../assets/images/sort-desc.svg'
import { SortDirection } from '../../../common/enums'
import { formatDollar, ToFixNum, ToFixPercent } from '../../../common/utils'
import { Product, GetChainName, PlatformMap, ChainData, GetEarnGroupPlatforms, EarnGroup } from 'depro.common'
import { Dropdown } from 'semantic-ui-react'
import { ReactComponent as MarketSvg } from '../../../assets/images/markets.svg'
import MarketIcon from '../../../assets/images/markets.svg'
import AllPlatforms from '../../../assets/images/all-platforms.svg'

const pageSize = Number(process.env.REACT_APP_PRODUCT_PAGE_SIZE) || 10


type FilterOption<T> = {
  key: T
  value: string
  icon?: string
}

interface IAllProducts {
  earnGroup: EarnGroup
}

export const AllProducts = (props: IAllProducts) => {
  const { earnGroup } = props
  const { t, i18n } = useTranslation(['product', 'common'])
  const navigate = useNavigate()
  const [productData, setProductData] = useState<Product[]>()
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [sortByApy, setSortByApy] = useState<SortDirection | undefined>(
    SortDirection.DESC
  )
  const [sortBySupply, setSortBySupply] = useState<SortDirection>()
  const [sortByPrice, setSortByPrice] = useState<SortDirection>()

  const [platformOptions, setPlatformOptions] = useState<FilterOption<string>[]>([])
  const [networkOptions, setNetworkOptions] = useState<FilterOption<number>[]>([])
  const [selectedPlatformOption, setSelectedPlatformOption] = useState<string>()
  const [selectedNetworkOption, setSelectedNetworkOption] = useState<number>()


  const fetchPageProducts = async () => {
    const [platform, version] =
      selectedPlatformOption && selectedPlatformOption !== "all"
        ? selectedPlatformOption.split("_")
        : [undefined, undefined]

    const res = await getPagedProductInfo(
      (page - 1) * pageSize,
      pageSize,
      sortByPrice,
      sortByApy,
      sortBySupply,
      selectedNetworkOption,
      platform,
      version,
      earnGroup
    )
    if (res && !res.error) {
      setProductData(res)
    }
  }

  const fetchProductCount = async () => {
    const [platform, version] =
      selectedPlatformOption && selectedPlatformOption !== "all"
        ? selectedPlatformOption.split("_")
        : [undefined, undefined]
    const res = await getAllProductCount(selectedNetworkOption, platform, version, earnGroup)
    if (res && !res.error && res.total) {
      const totalPage = Math.ceil(Number(res.total) / pageSize)
      setTotalPage(totalPage)
    }
  }

  const onPageChange = async (_: any, data: any) => {
    setPage(data.activePage)
  }

  const handleSortByPrice = () => {
    switch (sortByPrice) {
      case SortDirection.DESC:
        setSortByPrice(SortDirection.ASC)
        break
      default:
        setSortByPrice(SortDirection.DESC)
    }
    setSortBySupply(undefined)
    setSortByApy(undefined)
    setPage(1)
  }

  const handleSortByApy = () => {
    switch (sortByApy) {
      case SortDirection.DESC:
        setSortByApy(SortDirection.ASC)
        break
      default:
        setSortByApy(SortDirection.DESC)
    }
    setSortBySupply(undefined)
    setSortByPrice(undefined)
    setPage(1)
  }

  const handleSortBySupply = () => {
    switch (sortBySupply) {
      case SortDirection.DESC:
        setSortBySupply(SortDirection.ASC)
        break
      default:
        setSortBySupply(SortDirection.DESC)
    }
    setSortByApy(undefined)
    setSortByPrice(undefined)
    setPage(1)
  }

  const getPlatformOptions = async () => {
    const pOptions: FilterOption<string>[] = []
    const displayPlatforms = GetEarnGroupPlatforms(earnGroup as EarnGroup) || []

    for (const platformId in PlatformMap) {
      const platform = PlatformMap[platformId]
      if (displayPlatforms.includes(platform.Platform)) {
        const platformKey = platform.Platform + "_" + platform.Version
        if (!pOptions.find(f => f.key === platformKey)) {
          const platformName = platform.Platform + " " + platform.Version
          pOptions.push({
            key: platformKey,
            value: platformName,
            icon: platform.IconURL
          })
        }
      }
    }

    // if no platform options, also show all platforms
    if (pOptions.length !== 1) {
      pOptions.unshift({ key: "all", value: "All platforms", icon: AllPlatforms })
    }
    setPlatformOptions(pOptions)
  }

  const getChainOptions = async () => {
    const chainData = await getChainData()
    const chainOptions = chainData.map((data: ChainData) => {
      return { key: data.chain_id, value: data.name, icon: data.chain_icon }
    })
    setNetworkOptions(chainOptions)
  }

  useEffect(() => {
    getPlatformOptions()
    setSelectedNetworkOption(undefined)
    setSelectedPlatformOption(undefined)
    setSelectedNetworkOption(undefined)
    setPage(1)
  }, [earnGroup])

  useEffect(() => {
    if (platformOptions?.length > 0) {
      setSelectedPlatformOption(platformOptions[0].key)
    }
  }, [platformOptions])

  useEffect(() => {
    fetchProductCount()
  }, [selectedNetworkOption, selectedPlatformOption])

  useEffect(() => {
    fetchPageProducts()
  }, [page, sortBySupply, sortByApy, sortByPrice, selectedNetworkOption, selectedPlatformOption, selectedNetworkOption])

  useEffect(() => {
    getChainOptions()
  }, [])

  return (
    <div className="all-products">
      <div className='filters'>
        <div className='platform'>
          {
            platformOptions.map(p => {
              const dyClass = selectedPlatformOption ? selectedPlatformOption === p.key ? " active" : " inactive" : ""
              return (
                <div
                  role='button'
                  key={p.key}
                  className={'platform-option' + dyClass}
                  onClick={() => {
                    setPage(1)
                    setSelectedPlatformOption(p.key)
                  }}>
                  <img src={p.icon} />
                  <p>{p.value}</p>
                </div>
              )
            })
          }
        </div>
        <div className='network'>
          <Dropdown
            basic
            style={{ border: 'none' }}
            // className='button icon'
            floating
            trigger={
              <span className='action'><MarketSvg className='ico' />
                {networkOptions.find(f => f.key === selectedNetworkOption)?.value || "All Networks"}
              </span>
            }
            onChange={(_, d) => { console.log(d.value) }}
          >
            <Dropdown.Menu>
              <Dropdown.Item text="All networks" value={undefined} onClick={() => {
                setPage(1)
                setSelectedNetworkOption(undefined)
              }}
                image={{ avatar: true, src: MarketIcon }} />
              <>
                {networkOptions.map(p => {
                  return (
                    <Dropdown.Item key={p.key}
                      text={p.value} value={p.key}
                      image={{ avatar: true, src: p.icon }}
                      onClick={() => setSelectedNetworkOption(p.key)}
                    />
                  )
                })}
              </>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* TODO here */}
      </div>
      {
        productData ? (
          <div className='data-list'>
            <Table basic="very" className="depro-table">
              <Table.Header>
                <Table.Row className="depro-table-heading">
                  <Table.HeaderCell>{t('common:Token')}</Table.HeaderCell>
                  <Table.HeaderCell onClick={handleSortByPrice}>
                    <div className="sortable">
                      <div className="col-text">{t('common:Price')}</div>
                      <div className="col-sort">
                        <SortAsc
                          className={
                            sortByPrice === SortDirection.ASC ? 'active' : undefined
                          }
                        />
                        <SortDesc
                          className={
                            sortByPrice === SortDirection.DESC ? 'active' : undefined
                          }
                        />
                      </div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell onClick={handleSortByApy}>
                    <div className="sortable">
                      <div className="col-text">{t('APY')}</div>
                      <div className="col-sort">
                        <SortAsc
                          className={
                            sortByApy === SortDirection.ASC ? 'active' : undefined
                          }
                        />
                        <SortDesc
                          className={
                            sortByApy === SortDirection.DESC ? 'active' : undefined
                          }
                        />
                      </div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell onClick={handleSortBySupply}>
                    <div className="sortable">
                      <div className="col-text">{t('Total supplied')}</div>
                      <div className="col-sort">
                        <SortAsc
                          className={
                            sortBySupply === SortDirection.ASC
                              ? 'active'
                              : undefined
                          }
                        />
                        <SortDesc
                          className={
                            sortBySupply === SortDirection.DESC
                              ? 'active'
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    {t('common:Action')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {productData.map((data: Product) => {
                  return (
                    <Table.Row key={data.Id} verticalAlign="middle" role="button" onClick={() => navigate(`/product/${data.Id}`)}>
                      <Table.Cell className="token">
                        <img src={data.IconURL} className="ico" />
                        <div>
                          <div className="name">{data.TokenName}</div>
                          <div className="network">
                            {GetChainName(data.ChainId)}
                          </div>
                        </div>
                      </Table.Cell>
                      <Table.Cell>{data.product_market?.UnitPriceUSD ? "$" + ToFixNum(data.product_market?.UnitPriceUSD, 2, true) : "-"}</Table.Cell>
                      <Table.Cell>
                        {ToFixPercent(data.product_market?.Apy)}
                      </Table.Cell>
                      <Table.Cell>
                        <div>${formatDollar(data.product_market?.SupplyUSD || 0, i18n.language)}</div>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Button
                          className="subscribe-button primary"
                          onClick={() => {
                            navigate(`/product/${data.Id}`)
                          }}
                        >
                          {t('Subscribe')}
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
            <div className="paging-container">
              <Pagination
                className="paging"
                borderless
                activePage={page}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={null}
                lastItem={null}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={totalPage}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        ) : (
          <Loader active inline="centered" />
        )
      }
    </div >
  )
}

import { GetChainDataById, ChainData, KYCStatus } from 'depro.common'
import { Profile } from './types'

export const trimAddress = (address?: string) => {
  if (!address) return ''

  const match = address.match(
    /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  )
  if (!match) return address
  return `${match[1]}…${match[2]}`
}

export const maskCondential = (secretStr?: string, pre = 4, post = 3) => {
  if (secretStr) {
    if (secretStr.length > 3) {
      return secretStr.slice(0, pre) + '***' + secretStr.slice(-post)
    } else {
      return '***' + secretStr.slice(-1)
    }
  } else {
    return ''
  }
}

export const getChainData = (
  chainId?: number,
  apikey?: string
): ChainData | null => {
  if (!chainId) {
    return null
  }
  const chainData = GetChainDataById(chainId)
  if (!chainData) {
    console.warn('ChainId missing or not supported')
    return null
  }

  const API_KEY = apikey
  if (
    chainData.rpc_url.includes('infura.io') &&
    chainData.rpc_url.includes('%API_KEY%') &&
    API_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace('%API_KEY%', API_KEY)

    return {
      ...chainData,
      rpc_url: rpcUrl,
    }
  }

  return chainData
}

export enum NATIVE_SWAP {
  NIL = 0,
  NATIVE_TO_TOKEN = 1,
  TOKEN_TO_NATIVE = 2,
}

export const CheckLoginValid = async (profile: Profile | null) => {
  if (profile?.Token) {
    return true
  }
  return false
}

export const CheckKYCStatus = async (profile: Profile | null) => {
  if (profile?.KYCStatus === KYCStatus.Success) {
    return true
  }
  return false
}

export const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const formatDollar = (
  num?: string | number | null,
  i18n: string = 'en-US'
) => {
  if (num === undefined || num === null) {
    return 'N/A'
  }
  const langs = GetAvailableLangauge()
  //prevent browser detect other languages
  if (!langs.find((f) => f.value === i18n)) {
    i18n = 'en-US'
  }
  return Intl.NumberFormat(i18n, {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(Number(num))
}

export const ToFixNum = (
  amount?: string | number | null,
  dp: number = 6,
  keepZero = false
) => {
  if (amount === undefined || amount === null) {
    return ''
  } else {
    const num = Number(amount)
    const strDp = num.toFixed(dp)
    const numDp = Number(strDp)
    if (num !== 0 && numDp === 0) {
      return Intl.NumberFormat('en', {
        maximumSignificantDigits: 1,
      }).format(num)
    } else {
      if (keepZero) return strDp
      else return numDp.toString()
    }
  }
}

export const ToFixPercent = (
  amount?: string | number | null,
  dp: number = 2
) => {
  if (amount === undefined || amount === null) {
    return '-'
  } else {
    const num = Number(amount) * 100
    const ret = Number(num.toFixed(dp))
    if (num !== 0 && ret === 0) {
      return (
        Intl.NumberFormat('en', {
          maximumSignificantDigits: 1,
        }).format(num) + '%'
      )
    } else {
      return ret.toString() + '%'
    }
  }
}

export const GetAvailableLangauge = () => {
  return [
    { text: 'EN', value: 'en-US' },
    { text: '中', value: 'zh-CN' },
  ]
}

import './signin.css'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export const SigninButton = () => {
  const { t } = useTranslation('common')
  return (
    <Link
      to={{
        pathname: '/login',
        search: 'from=' + window.location.pathname,
      }}
    >
      <Button className="signin">{t('Signup')}</Button>
    </Link>
  )
}

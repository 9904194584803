"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLTransaction = void 0;
class PrismaMySQLTransaction {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLTransactionSwap Created');
    }
    BizSubscirption(earning, amount, subscription, fund) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.$transaction((prisma) => __awaiter(this, void 0, void 0, function* () {
                // 1. earnings
                const earningToUpdate = yield prisma.earnings.findUnique({
                    where: {
                        UserId_ProductId: {
                            UserId: earning.UserId,
                            ProductId: earning.ProductId,
                        },
                    },
                });
                if (!earningToUpdate) {
                    throw new Error('Record not found');
                }
                const oldValue = Number(earningToUpdate.ATokenBalance || 0);
                const newValue = oldValue - amount; // subsciprtion make reserve decreased
                const updatedEarning = yield prisma.earnings.update({
                    where: {
                        UserId_ProductId: {
                            UserId: earning.UserId,
                            ProductId: earning.ProductId,
                        },
                    },
                    data: {
                        ATokenBalance: newValue.toString(),
                    },
                });
                // 2. subscription
                const newSub = yield this.ctx.subscriptions.create({
                    data: {
                        UserId: subscription.UserId,
                        ProductId: subscription.ProductId,
                        Amount: subscription.Amount,
                        Status: subscription.Status,
                    },
                });
                // 3. fund
                const newFund = yield this.ctx.fund.create({
                    data: {
                        Note: fund.Note,
                        Channel: fund.Channel,
                        Account: fund.Account,
                        Txn: fund.Txn,
                        Currency: fund.Currency,
                        Amount: fund.Amount,
                        OrderId: newSub.Id,
                        Status: fund.Status,
                        UserId: fund.UserId,
                        IsBatchProcessing: fund.IsBatchProcessing,
                        CurrencyAddress: fund.CurrencyAddress,
                    },
                });
                return {
                    updatedEarning,
                    newSub,
                    newFund,
                };
            }));
            if (result && result.newFund && result.updatedEarning && result.newSub) {
                return true;
            }
            return false;
        });
    }
    BizRedemption(redemption, fund) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.$transaction((prisma) => __awaiter(this, void 0, void 0, function* () {
                // 1. redemption
                const newRedemption = yield this.ctx.redemptions.create({
                    data: {
                        UserId: redemption.UserId,
                        ProductId: redemption.ProductId,
                        Amount: redemption.Amount,
                        Status: redemption.Status,
                    },
                });
                // 2. fund
                const newFund = yield this.ctx.fund.create({
                    data: {
                        Note: fund.Note,
                        Channel: fund.Channel,
                        Account: fund.Account,
                        Txn: fund.Txn,
                        Currency: fund.Currency,
                        Amount: fund.Amount,
                        OrderId: newRedemption.Id,
                        Status: fund.Status,
                        UserId: fund.UserId,
                        IsBatchProcessing: fund.IsBatchProcessing,
                        CurrencyAddress: fund.CurrencyAddress,
                    },
                });
                return {
                    newRedemption,
                    newFund,
                };
            }));
            if (result && result.newFund && result.newRedemption) {
                return true;
            }
            return false;
        });
    }
}
exports.PrismaMySQLTransaction = PrismaMySQLTransaction;

import './payment.page.css'
import { PaymentHeader, PaymentOptions, PaymentStatus } from '.'
import { BackButton } from '../../components/Btn/BackButton'
import { useState, useContext } from 'react'
import { FiatPaymentParams } from '../../common/types'
import { PaymentChannel, Bankcard } from 'depro.common'
import AppContext from '../../context/AppContext'
import { Button, Message } from 'semantic-ui-react'
import { stakeByFiat } from '../../api/cefi.api'
import { useNavigate } from 'react-router-dom'
import { DeproHeader } from '../../components'
import { useTranslation } from 'react-i18next'

export const Payment = () => {
  const { t } = useTranslation(['payment', 'common'])
  const { trade, setTrade } = useContext(AppContext)
  const [fiatChannel, setFiatChannel] = useState<PaymentChannel>(
    PaymentChannel.Credit
  )
  // const [card, setCard] = useState<IBankcard | ICreditcard>()
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const [error, setError] = useState<string>()
  const naviagte = useNavigate()

  const handlePayment = async () => {
    if (fiatChannel && trade && trade.currency) {
      const params: FiatPaymentParams = {
        productId: trade.proudctData.Id,
        payAmount: trade.amount,
        stakeAmount: trade.receiveAmount,
        currency: trade.currency.Currency,
        backHost: window.location.protocol + '//' + window.location.host,
        noticeUrl: process.env.REACT_APP_API?.includes('://')
          ? process.env.REACT_APP_API || ''
          : window.location.protocol +
          '//' +
          window.location.host +
          process.env.REACT_APP_API || '',
        fiatChannel: fiatChannel,
        fiatCardId: 0,
      }

      const response = await stakeByFiat(params)
      //block atm
      // if (response && response.response === 0 && response.event_code === 0) {
      if (response && response.res === 1 && response.ec === 0) {
        setOpenStatusModal(true)
        // window.open(response['data']['object'])  // blockATM
        window.open(response['dt']['lst'])
      } else {
        setError(t('Subscription is failed'))
        console.error('scusciprtion error: ', response)
      }
    }
  }

  return (
    <div className="payment">
      <div className="app-bg-plain">
        <div className="banner app-padding">
          <DeproHeader />
          <PaymentHeader />
        </div>
      </div>

      <div className="app-padding">
        <div className="float-back-wrapper">
          <div className="back">
            <BackButton />
          </div>
        </div>
        {trade && (
          <div className="content">
            <div className="main">
              <div>
                <div className="paywith">{t('Pay with')}</div>
                <PaymentOptions
                  onBankCardSelect={(card: Bankcard) => {
                    setFiatChannel(PaymentChannel.Bank)
                    // setCard(card)
                  }}
                />
              </div>
              <div className="summary">
                <div className="token">
                  <img src={trade.proudctData.IconURL} className="icon" />
                  <div className="text">{trade.proudctData.TokenName}</div>
                </div>

                <div className="subscription">
                  <div className="item">
                    <div className="label">{t('Subscription amount')}</div>
                    <div className="value">{trade.receiveAmount}</div>
                  </div>
                  <div className="item">
                    <div className="label">{t('common:Rate')}</div>
                    <div className="value">
                      {`1 ${trade.proudctData.TokenName} ≈ ${trade.currencyPerProductRate?.toPrecision(2)} ${trade.currency?.Currency}`}</div>
                  </div>
                </div>

                <div className="item pay-amount">
                  <div className="label">{t('Payment amount')}</div>
                  <div className="value">
                    {trade.amount + ' ' + trade.currency?.Currency}
                  </div>
                </div>
              </div>
              {error && (
                <Message negative>
                  <Message.Header>{error}</Message.Header>
                </Message>
              )}
              <div className="actions">
                <Button
                  className="primary confirm"
                  disabled={!fiatChannel || !trade || error !== undefined}
                  onClick={handlePayment}
                >
                  {t('common:Confirm')}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      {trade && (
        <PaymentStatus
          trade={trade}
          open={openStatusModal}
          onClose={() => {
            setTrade(undefined)
            setOpenStatusModal(false)
            naviagte('/dashboard')
          }}
        />
      )}
    </div>
  )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvaScanServices = void 0;
const AbsNetworkScan_1 = require("./AbsNetworkScan");
class AvaScanServices extends AbsNetworkScan_1.AbsNetworkScan {
    constructor(chainId, avaKey) {
        super(chainId, avaKey);
        switch (chainId) {
            //polygon mainnet
            case 43114:
                this.endpoint = `https://api.snowtrace.io`;
                break;
            // testnet polygon
            case 43113:
                this.endpoint = `https://api-testnet.snowtrace.io`;
                break;
            default:
                throw new Error(`Invalid chain id for Avalanche, chainid: ${chainId}`);
        }
    }
}
exports.AvaScanServices = AvaScanServices;

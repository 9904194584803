import './details.css'
import { ProductDetailsFaq } from './details.faq'
import { ProductDetailsApy } from './details.apy'
import { ProductDetailsTrade } from './details.trade'
import { useParams, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import AppContext from '../../context/AppContext'
import { EarnGroup, PlatformMap, Product } from 'depro.common'
import { getProductById } from '../../api/product.api'
import { useTranslation } from 'react-i18next'
import { DeproHeader } from '../../components'
import { BackButton } from '../../components/Btn'
import { TokenInfo } from './details.token'
import { ProductDetailsInformation } from './details.information'
import { ProductDetailsYearnApy } from './details.yearn'

export const ProductDetails = () => {
  const { t } = useTranslation('detail')
  const { profile } = useContext(AppContext)
  const navigate = useNavigate()
  const { productId } = useParams()
  const [productData, setProductData] = useState<Product>()

  const fetchProductData = async () => {
    if (productId) {
      const res = await getProductById(productId)
      if (res && !res.error) {
        setProductData(res)
      }
    }
  }

  useEffect(() => {
    if (profile && !profile.Email) {
      navigate({
        pathname: '/bind-email',
        search: `?from=/product/${productId}`,
      })
    }
  }, [profile])

  useEffect(() => {
    fetchProductData()
  }, [])

  if (!productId || !productData) return <>product not found</>

  return (
    <div className="product-details app-bg app-padding">
      <div>
        <DeproHeader />
        <div className="back">
          <BackButton />
        </div>
      </div>
      <div className='main'>
        <div className='info'>
          <div className='token-apy'>
            <TokenInfo product={productData} />
            <ProductDetailsApy product={productData} />
          </div>
          <ProductDetailsInformation product={productData} />
          {
            PlatformMap[productData.PlatformId].EarnGroup === EarnGroup.Vault &&
            <ProductDetailsYearnApy product={productData} />
          }

          <ProductDetailsFaq platform={PlatformMap[productData.PlatformId].Platform} />
        </div>
        <div className="trade">
          {productData && <ProductDetailsTrade data={productData} />}
        </div>
      </div>

    </div>
  )
}

const APP_API_URL = process.env.REACT_APP_API || '/api'
const pathsSkip401 = ['/login', '/pingAuth']
const locationSkip401 = ['/dashboard']

export const request = (
  url: string,
  options?: RequestInit,
  isFileUpload = false
) => {
  const fullUrl = url.includes('://') ? url : APP_API_URL + url
  const onSuccess = (response: Response) => {
    if (response.ok) {
      if (response.status === 200) {
        return response.json()
      }
    } else {
      if (response.status === 400) {
        return response.json()
      } else if (response.status === 401) {
        if (
          !pathsSkip401.includes(url) &&
          !locationSkip401.includes(window.location.pathname)
        ) {
          window.location.href = '/'
        }
      }

      return response.text().then((text) => {
        throw new Error(text)
      })
    }
  }
  const onError = (error: Error) => {
    console.error(error)
  }

  const deproToken =
    JSON.parse(sessionStorage.getItem('profile') || '{}')?.Token || ''

  if (!options) options = {} as RequestInit
  options.credentials = 'include'
  options.headers = {
    'depro-access-token': deproToken,
    ...options.headers,
  }
  if (!isFileUpload) {
    options.headers = {
      'content-type': 'application/json',
      ...options.headers,
    }
  }
  return fetch(fullUrl, options).then(onSuccess).catch(onError)
}

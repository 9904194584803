import { request } from './request.api'
import { EmailCodeType } from 'depro.common'

export const uploadImage = async (f: File) => {
  const formData = new FormData()
  formData.append('file', f)

  const res = await request(
    `/kyc/record-files`,
    {
      method: 'POST',
      body: formData,
    },
    true
  )
  return res
}

export const kycFormSend = async (record: any) => {
  const res = await request(`/kyc/record`, {
    method: 'POST',
    body: JSON.stringify({ form: record }),
  })
  return res
}

export const getUserRecord = async () => {
  const res = await request(`/kyc/record`)
  return res
}

/**
 * Get Verification code
 * @param codeType
 * @param email Only used in bind email for telegram
 * @returns
 */
export const getVerificationCodeByEmail = async (
  codeType: EmailCodeType,
  email?: string
) => {
  const res = await request(`/kyc/email-code`, {
    method: 'POST',
    body: JSON.stringify({
      email: email,
      codeType,
    }),
  })
  return res
}

/**
 * Verifiy the validity of verification code
 * @param codeType
 * @param code
 * @param email Only used in bind email for telegram
 * @returns
 */
export const verifyEmailCode = async (
  codeType: EmailCodeType,
  code: string,
  email?: string
) => {
  const res = await request(`/kyc/email-code-verify`, {
    method: 'POST',
    body: JSON.stringify({
      code: code,
      email: email,
      codeType,
    }),
  })
  return res
}

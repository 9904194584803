"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLTransactionStake = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLTransactionStake {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLTransactionStake Created');
    }
    Add(stake) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_stake.create({
                data: {
                    OrderId: stake.OrderId,
                    ProductId: stake.ProductId,
                    Amount: stake.Amount,
                    Hash: stake.Hash,
                    Status: stake.Status,
                    Note: stake.Note,
                },
            });
            return res;
        });
    }
    BulkUpdateStatus(stakes) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.$transaction(stakes.map((m) => {
                return this.ctx.transaction_stake.update({
                    where: {
                        Id: m.stakeId,
                    },
                    data: {
                        Status: m.status,
                        Hash: m.hash,
                    },
                });
            }));
            return res.length;
        });
    }
    GetByNoteAndId(note, orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_stake.findFirst({
                where: {
                    OrderId: orderId,
                    Note: note,
                },
                orderBy: {
                    CreatedAt: 'desc',
                },
            });
            return res;
        });
    }
    GetListByNoteAndId(note, orderIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_stake.findMany({
                where: {
                    Note: note,
                    OrderId: { in: orderIds },
                },
            });
            return res;
        });
    }
    GetLongPendingTransactions(timestamp) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_stake.findMany({
                where: {
                    Status: types_1.TransactionStatus.Pending,
                    UpdatedAt: {
                        lte: timestamp,
                    },
                },
            });
            return res;
        });
    }
    AddWithRedemptionUpdate(stake, redemptionId, newStatus) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.ctx.$transaction([
                this.ctx.transaction_stake.create({
                    data: {
                        OrderId: stake.OrderId,
                        ProductId: stake.ProductId,
                        Amount: stake.Amount,
                        Hash: stake.Hash,
                        Status: stake.Status,
                        Note: stake.Note,
                    },
                }),
                this.ctx.redemptions.update({
                    where: {
                        Id: redemptionId,
                    },
                    data: {
                        Status: newStatus,
                    },
                }),
            ]);
        });
    }
}
exports.PrismaMySQLTransactionStake = PrismaMySQLTransactionStake;

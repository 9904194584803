import './payment.option.css'
import { useEffect, useState } from 'react'
import { Radio } from 'semantic-ui-react'
import { getBankcard } from '../../api/account.api'
import { Bankcard, PaymentChannel } from 'depro.common'
import { ManageCardModal } from '../../components/ManageCard'
import WalletMono from '../../assets/images/wallet_off_black.svg'
import BankcardMono from '../../assets/images/bankcard-mono.svg'
import BankcardNormal from '../../assets/images/bankcard.svg'

import { useTranslation } from 'react-i18next'

interface IPaymentOptionProps {
  onBankCardSelect: (card: Bankcard) => void
}

export const PaymentOptions = (props: IPaymentOptionProps) => {
  const { t } = useTranslation(['payment', 'card'])
  const { onBankCardSelect } = props
  const [bankcards, setBankcards] = useState<Bankcard[]>([])

  const [fiatChannel, setFiatChannel] = useState<PaymentChannel>(
    PaymentChannel.Credit
  ) //later may have credit card
  const [openManageModal, setOpenManageModal] = useState(false)

  const [chosenBankCard, setChosenBankCard] = useState<Bankcard>()

  const fetchBankcards = async () => {
    const res = await getBankcard()
    if (res && !res.error) {
      setBankcards(res)
    }
  }

  useEffect(() => {
    fetchBankcards()
  }, [])

  return (
    <div className="payment-options">
      <div className="channel-options">
        <div
          className={
            'bank channel-option' +
            (fiatChannel === PaymentChannel.Bank ? ' active' : '')
          }
          onClick={() => setFiatChannel(PaymentChannel.Bank)}
        >
          <div className="details">
            <div className="heading">
              <img className="icon" src={BankcardMono} />
              <div className="text">{t('card:Bank Card')}</div>
              <div className="badge-hot">{t('Hot')}</div>
            </div>

            <div className="action">
              <img className="ico" src={BankcardNormal} />
              {/* <div className="manage" onClick={() => setOpenManageModal(true)}>
                {bankcards.length === 0 && (
                  <div className="change">{t('card:Add card')}</div>
                )}
                {bankcards.length > 0 && !chosenBankCard && (
                  <div className="change">{t('card:Choose card')}</div>
                )}
                {bankcards.length > 0 && chosenBankCard && (
                  <>
                    <div className="account">
                      {maskCondential(chosenBankCard.Number)}
                    </div>
                    <div className="change">{t('card:Change card')}</div>
                  </>
                )}
                <img src={ArrowRightBlue} />
              </div> */}
            </div>
          </div>
          <Radio
            className="opt-radio"
            name="opt-group"
            checked={fiatChannel === PaymentChannel.Bank}
          />
        </div>

        {/* temp not avaiable */}
        <div
          className={
            'credit channel-option' +
            (fiatChannel === PaymentChannel.Credit ? ' active' : '')
          }
          onClick={() => setFiatChannel(PaymentChannel.Credit)}
        >
          <div className="details">
            <div className="heading">
              <img className="icon" src={WalletMono} />
              <div className="text">{t('Third-party Payment')}</div>
              <div className="badge-hot">{t('Hot')}</div>
            </div>
          </div>
          {/* <Radio
            className="opt-radio"
            name="opt-group"
            checked={fiatChannel === PaymentChannel.Credit}
          /> */}
        </div>
      </div>
      <ManageCardModal
        add={bankcards.length === 0}
        open={openManageModal}
        onClose={() => setOpenManageModal(false)}
        onSelect={(card?: Bankcard) => {
          if (card) {
            setChosenBankCard(card)
            onBankCardSelect(card)
            if (!bankcards.find((b) => b.Id === card.Id)) {
              setBankcards([...bankcards, card])
            }
          }
        }}
        onRemove={(cardId: number) => {
          setBankcards(bankcards.filter((f) => f.Id !== cardId))
        }}
      />
    </div>
  )
}

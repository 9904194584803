import { useState, useEffect, FocusEvent } from 'react'
import { Input, Dropdown, DropdownProps, Button } from 'semantic-ui-react'
import { EarnType } from '../../common/types'
import { Bankcard, FiatRate, Kyc } from 'depro.common'
import { getFiatRates } from '../../api/exrate.api'
import { addBankcard } from '../../api/account.api'
import { getUserRecord } from '../../api/kyc.api'
import './bankcard.add.css'
import countries from '../../assets/country.json'
import { useTranslation } from 'react-i18next'

interface AddBankCardProp {
  onAdd: (card: Bankcard) => void
}

export const AddBankCard = (prop: AddBankCardProp) => {
  const { t } = useTranslation('card')
  const { onAdd } = prop
  const [country, setCountry] = useState<string>()
  const [holder, setHolder] = useState<string>()
  const [currency, setCurrency] = useState<string>()
  const [bank, setBank] = useState<string>()
  const [number, setNumber] = useState<string>()
  const [swiftCode, setSwiftCode] = useState<string>()

  const [errCountry, setErrCountry] = useState<string>()
  const [errHolder, setErrHolder] = useState<string>()
  const [errCurrency, setErrCurrency] = useState<string>()
  const [errBank, setErrBank] = useState<string>()
  const [errNumber, setErrNumber] = useState<string>()

  const [disabledConfirm, setDisableConfirm] = useState(true)
  const [optionCurrencies, setOptionCurrencies] = useState<any[]>([])
  const [kycRecord, setKycRecord] = useState<Kyc>()

  const validateCard = () => {
    let isPass = true

    if (!country || country.length === 0) {
      setErrCountry(t('Country / Region is blank'))
      isPass = false
    }
    if (!holder || holder.length === 0) {
      setErrHolder(t('Holder is blank'))
      isPass = false
    }

    if (!currency || currency.length === 0) {
      setErrCurrency(t('Currency is blank'))
      isPass = false
    }

    if (!number || number.length === 0) {
      setErrNumber(t('Number is blank'))
      isPass = false
    }
    if (!bank || bank.length === 0) {
      setErrBank(t('Bank is blank'))
      isPass = false
    }

    return isPass
  }

  const addCard = async () => {
    const isResultPass = validateCard()
    if (isResultPass && holder && number && currency && country && bank) {
      const res = await addBankcard({
        Id: 0,
        Holder: holder,
        Number: number,
        Currency: currency,
        Bank: bank,
        SwiftCode: swiftCode || null,
        CountryRegion: country,
        CreatedAt: new Date(),
        LastFundTime: null,
        CardPhoto: null,
        BranchName: null,
        Address: null,
        UserId: 0, //updated by backend
      })
      if (res.Id) {
        onAdd(res)
      } else {
        console.error(res.error)
      }
    } else {
      console.warn('Not pass validation')
      return
    }
  }

  const fetchFiatOptions = async () => {
    const fiatRates: FiatRate[] = await getFiatRates(EarnType.Redemption)

    if (fiatRates) {
      const options = fiatRates.map((rate) => {
        return { key: rate.Currency, value: rate.Currency, text: rate.Currency }
      })
      setOptionCurrencies(options)
    }
  }

  useEffect(() => {
    if (bank && currency && country && holder && number) {
      setDisableConfirm(false)
    } else {
      setDisableConfirm(true)
    }
  }, [bank, currency, country, holder, number])

  const fetchKyc = async () => {
    const record: Kyc = await getUserRecord()
    if (record) {
      setKycRecord(record)
      setHolder(record.FirstMiddleName + ' ' + record.LastName)
    }
  }

  useEffect(() => {
    fetchFiatOptions()
    fetchKyc()
  }, [])

  return (
    <div className="bankcard add">
      <div className="text-input">
        <div className="item">
          <div className="label required">{t('Country / Region')}</div>
          <div className="control">
            <Dropdown
              placeholder={t('Country / Region')}
              selection
              search
              options={countries}
              className="dropdown-input"
              onChange={(_: any, data: DropdownProps) =>
                setCountry(data.value as string)
              }
            />
            {errCountry && <small>{errCountry}</small>}
          </div>
        </div>
        <div className="item">
          <div className="label required">Card holder</div>
          <div className="control">
            <Input
              placeholder={t('Card holder')}
              className="fw"
              readOnly
              value={holder || ''}
            />
            {errHolder && <small>{errHolder}</small>}
          </div>
        </div>
        <div className="item">
          <div className="label required">Card Currency</div>
          <div className="control">
            <Dropdown
              placeholder={t('Bank Currency')}
              fluid
              selection
              search
              options={optionCurrencies}
              className="dropdown-input"
              onChange={(_: any, data: DropdownProps) =>
                setCurrency(data.value as string)
              }
            />
            {errCurrency && <small>{errCurrency}</small>}
          </div>
        </div>
        <div className="item">
          <div className="label required">{t('Bank name')}</div>
          <div className="control">
            <Input
              placeholder={t('Bank name')}
              className="fw"
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                setBank(e.target.value)
              }}
            />
            {errBank && <small>{errBank}</small>}
          </div>
        </div>
        <div className="item">
          <div className="label required">{t('Card number')}</div>
          <div className="control">
            <Input
              placeholder={t('Card number')}
              className="fw"
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                setNumber(e.target.value)
              }}
            />
            {errNumber && <small>{errNumber}</small>}
          </div>
        </div>
        <div className="item">
          <div className="label">{t('Swift Code')}</div>
          <div className="control">
            <Input
              placeholder={t('Swift Code')}
              className="fw"
              onBlur={(e: FocusEvent<HTMLInputElement>) => {
                setSwiftCode(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <div className="submit">
        <Button
          className="confirm"
          onClick={addCard}
          disabled={disabledConfirm}
        >
          {t('Confirm')}
        </Button>
      </div>
    </div>
  )
}

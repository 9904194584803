import './back-button.css'
import { Link, useSearchParams } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

export const BackButton = () => {
  const { t } = useTranslation('common')
  const [params, _] = useSearchParams()

  const orderId = params.get('order')
  const search = orderId ? `id=${orderId}` : ''
  const path = params.get('from') || '/'

  return (
    <div className="back-btn">
      <Link to={{ pathname: path, search }}>
        <Icon name="arrow left" />
        <span>{t('Back')}</span>
      </Link>
    </div>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLProductInterestLog = void 0;
class PrismaMySQLProductInterestLog {
    constructor(dbContext) {
        this.Add = (item) => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.ctx.product_interest_log.create({
                    data: {
                        ProductId: item.ProductId,
                        PreviousBalance: item.PreviousBalance,
                        CurrentBalance: item.CurrentBalance,
                        OrderCount: item.OrderCount,
                        BlockNumber: item.BlockNumber,
                    },
                });
                return res;
            }
            catch (e) {
                console.error('Exception add interest log', e);
                return null;
            }
        });
        this.GetLatestBlockNumberPerChain = (products) => __awaiter(this, void 0, void 0, function* () {
            try {
                const result = {};
                const latestProductInterestLogs = yield this.ctx.product_interest_log.findMany({
                    where: {
                        ProductId: { in: products.map((m) => m.Id) },
                    },
                    distinct: ['ProductId'],
                    orderBy: { Timestamp: 'desc' },
                });
                products.forEach((product) => {
                    const log = latestProductInterestLogs.find((f) => f.ProductId === product.Id);
                    const blockNum = log ? log.BlockNumber : null;
                    if (blockNum) {
                        if (!result[product.ChainId] || result[product.ChainId] < blockNum) {
                            result[product.ChainId] = blockNum;
                        }
                    }
                });
                return result;
            }
            catch (e) {
                console.error('Error while getting latest updated block number per chain', e);
                return {};
            }
        });
        this.GetLatestByProductId = (pid) => __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.ctx.product_interest_log.findFirst({
                    where: {
                        ProductId: Number(pid),
                    },
                    orderBy: {
                        Timestamp: 'desc',
                    },
                });
                return res;
            }
            catch (e) {
                console.error(`Exception get interest log with product id ${pid}`, e);
                return null;
            }
        });
        this.DeleteById = (id) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ctx.product_interest_log.delete({
                    where: {
                        Id: Number(id),
                    },
                });
                return true;
            }
            catch (e) {
                return false;
            }
        });
        this.MigrateToBackup = (limit, recordToKeep) => __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.ctx
                    .$queryRaw `show table status where name='product_interest_log';`;
                if (result && result.length > 0) {
                    if (Number(result[0].Rows) > limit) {
                        const ts = Date.now();
                        // * backup by renaming table `adfairway.product_interest_log`
                        yield this.ctx.$executeRawUnsafe(`RENAME TABLE adfairway.product_interest_log TO adfairway.product_interest_log_${ts};`);
                        // * recreate empty table `adfairway.product_interest_log`
                        yield this.ctx.$executeRawUnsafe(`CREATE TABLE adfairway.product_interest_log LIKE adfairway.product_interest_log_${ts};`);
                        // * insert latest log
                        const resImportData = yield this.ctx.$executeRawUnsafe(`
            INSERT INTO adfairway.product_interest_log (ProductId, PreviousBalance, CurrentBalance, OrderCount, Timestamp, BlockNumber)
            SELECT ProductId, PreviousBalance, CurrentBalance, OrderCount, Timestamp, BlockNumber
            FROM adfairway.product_interest_log_${ts}
            WHERE Id IN (${recordToKeep.join(',')})`);
                        if (Number(resImportData) === recordToKeep.length) {
                            return true;
                        }
                        else {
                            throw Error(`Insertion of logs not match. expect: ${recordToKeep.length} vs actual: ${Number(resImportData)}`);
                        }
                    }
                    else {
                        return true;
                    }
                }
                else {
                    throw Error(`Cannot query table interest_log`);
                }
            }
            catch (e) {
                console.error('Exception interest_log count', e);
            }
            return false;
        });
        this.ctx = dbContext;
        console.log('PrismaMySQLInterestLog created');
    }
}
exports.PrismaMySQLProductInterestLog = PrismaMySQLProductInterestLog;

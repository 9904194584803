import { request } from './request.api'

export const authGoogle = async (token: string) => {
  const res = await request(`/auth/google`, {
    method: 'POST',
    body: JSON.stringify({ token }),
  })
  return res
}

export const authTelegram = async (hash: string, data: any) => {
  const res = await request(`/auth/telegram`, {
    method: 'POST',
    body: JSON.stringify({ hash, data }),
  })
  return res
}

export const getAuthUser = async () => {
  const res = await request(`/auth/user`)
  return res
}

import { t } from 'i18next'
import { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'
import { AddBankCard, ManageBankCard, RemoveBankCard } from '.'
import { Bankcard } from 'depro.common'
import './managecard.modal.css'
import { ReactComponent as IcoBack } from '../../assets/images/back.svg'

type ManageMode = 'ADD' | 'MANAGE' | 'REMOVE'
interface IManageBankCardModal {
  open: boolean
  onSelect: (card?: Bankcard) => void
  onClose: (card?: Bankcard) => void
  currentCardId?: number
  add?: boolean
  onRemove?: (cardId: number) => void
  embeddedHeight?: string
  back?: boolean
}

export const ManageCardModal = (props: IManageBankCardModal) => {
  const {
    open,
    onClose,
    onSelect,
    currentCardId,
    add,
    onRemove,
    embeddedHeight,
    back,
  } = props

  const close = () => {
    setMode('MANAGE')
    onClose()
  }

  const [mode, setMode] = useState<ManageMode>()
  const [removingCard, setRemovingCard] = useState<Bankcard>()

  const height = embeddedHeight ? embeddedHeight : 'unset'

  const handleBack = () => {
    switch (mode) {
      case 'ADD':
        setMode('MANAGE')
        break
      case 'REMOVE':
        setMode('MANAGE')
        break
      default:
        close()
    }
  }

  useEffect(() => {
    if (add) {
      setMode('ADD')
    } else {
      setMode('MANAGE')
    }
  }, [add])

  return (
    <Modal className="managecard-modal" onClose={close} open={open} size="tiny">
      <Modal.Header className="header">
        <div className="modal-header-title">
          {(back || mode === 'ADD') && (
            <a href="#" onClick={handleBack}>
              <IcoBack />
            </a>
          )}
          <>{mode === 'MANAGE' && t('Card Management')}</>
          <>{mode === 'ADD' && t('Add Bank Card')}</>
          <>{mode === 'REMOVE' && t('Remove Card')}</>
        </div>
        <div className="btn-modal-close" onClick={close} />
      </Modal.Header>
      <Modal.Content>
        <div className="bankcard" style={{ height }}>
          {mode === 'ADD' && (
            <AddBankCard
              onAdd={(card: Bankcard) => {
                setMode('MANAGE')
                onSelect(card)
                onClose(card)
              }}
            />
          )}
          {mode === 'MANAGE' && (
            <ManageBankCard
              currentCardId={currentCardId}
              onSelect={(card) => {
                onSelect(card)
                onClose(card)
              }}
              onModeChange={(mode: string) => {
                setMode(mode as ManageMode)
              }}
              onRemoveSelect={(card: Bankcard) => {
                setRemovingCard(card)
                setMode('REMOVE')
              }}
            />
          )}
          {mode === 'REMOVE' && removingCard && (
            <RemoveBankCard
              card={removingCard}
              onRemove={() => {
                if (onRemove) {
                  onRemove(removingCard.Id!)
                }
                setMode('MANAGE')
              }}
              onBack={handleBack}
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompoundStakeProduct = exports.CompoundAbi = void 0;
const ethers_1 = require("ethers");
const __1 = require("..");
exports.CompoundAbi = [
    'function redeem(uint redeemTokens) returns (uint)',
    'function mint(uint mintAmount) returns (uint)',
    'function supplyRatePerBlock() view returns (uint)',
    'function borrowRatePerBlock() view returns (uint)',
    'function balanceOfUnderlying(address) view returns (uint)',
    'function exchangeRateCurrent() view returns (uint)',
    'function borrowBalanceStored(address) view returns (uint)',
    'function decimals() view returns (uint8)',
    'function balanceOf(address) view returns (uint)',
    'function redeemUnderlying(uint redeemAmount) returns (uint)',
];
class CompoundStakeProduct {
    constructor(product, wallet) {
        this.product = product;
        this.contractAddress = product.ContractAddress;
        this.wallet = wallet;
        if (this.wallet.provider instanceof ethers_1.ethers.providers.BaseProvider) {
            this.stakeContract = new ethers_1.Contract(this.contractAddress, exports.CompoundAbi, this.wallet.provider);
        }
        else {
            throw new Error('Compound not support non-ethers-providers');
        }
    }
    GetAPY() {
        return __awaiter(this, void 0, void 0, function* () {
            // v3 https://docs.compound.finance/interest-rates/#get-supply-rate
            // v2 https://docs.compound.finance/v2/#protocol-math
            // const ethMantissa = 1e18
            const ethMantissaDP = 18;
            const blocksPerDay = 7200; // 12 seconds per block
            const daysPerYear = 365;
            const supplyRatePerBlock = yield this.stakeContract.supplyRatePerBlock();
            const bn = ethers_1.BigNumber.from(supplyRatePerBlock);
            const rate = Number(ethers_1.ethers.utils.formatUnits(bn, ethMantissaDP));
            const supplyApy = Math.pow(rate / blocksPerDay + 1, daysPerYear) - 1;
            return supplyApy;
        });
    }
    GetATokenUnderlyingBalance(wei, blockNo) {
        return __awaiter(this, void 0, void 0, function* () {
            //* this will return the value of atoken in terms of underlying tokens
            const balance = yield this.stakeContract.balanceOfUnderlying(this.wallet.address, { blockTag: blockNo });
            if (wei) {
                return balance;
            }
            else {
                return ethers_1.ethers.utils.formatUnits(balance, this.product.TokenDecimal);
            }
        });
    }
    Deposit(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const amountWei = (0, __1.SafeParseUnits)(amount, this.product.TokenDecimal);
            const nonce = yield this.wallet.getNonce();
            const gasPrice = yield this.wallet.getGasPriceWei();
            const iface = new ethers_1.ethers.utils.Interface(exports.CompoundAbi);
            var data, stakeTxn;
            if (this.product.TokenAddress !== 'ETH') {
                data = iface.encodeFunctionData('mint', [amountWei]);
                stakeTxn = {
                    nonce: nonce,
                    to: this.product.ContractAddress,
                    from: this.wallet.address,
                    data: data,
                    gasPrice: gasPrice,
                    gasLimit: 450000,
                };
            }
            else {
                console.log({ amountWei });
                // data = iface.encodeFunctionData("mint");
                // console.log({data})
                stakeTxn = {
                    nonce: nonce,
                    to: this.product.ContractAddress,
                    from: this.wallet.address,
                    value: amountWei,
                    data: '0x1249c58b',
                    gasPrice: gasPrice,
                    gasLimit: 450000,
                };
            }
            console.log(stakeTxn);
            const txnHash = yield this.wallet.createContractTransaction(stakeTxn);
            return txnHash;
        });
    }
    Withdraw(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const amountWei = (0, __1.SafeParseUnits)(amount, this.product.TokenDecimal);
            const nonce = yield this.wallet.getNonce();
            const gasPrice = yield this.wallet.getGasPriceWei();
            const iface = new ethers_1.ethers.utils.Interface(exports.CompoundAbi);
            const data = iface.encodeFunctionData('redeemUnderlying', [amountWei]);
            const unstakeTxn = {
                nonce: nonce,
                to: this.product.ContractAddress,
                from: this.wallet.address,
                data: data,
                gasPrice: gasPrice,
                gasLimit: 450000,
            };
            const txnHash = this.wallet.createContractTransaction(unstakeTxn);
            return txnHash;
        });
    }
    /**
     * cToken amount * rate = token amount
     * @returns
     */
    GetExchangeRate() {
        return __awaiter(this, void 0, void 0, function* () {
            const compoundContract = new ethers_1.ethers.Contract(this.contractAddress, exports.CompoundAbi, this.wallet.provider);
            const exchangerateWei = yield compoundContract.callStatic.exchangeRateCurrent();
            //* https://docs.compound.finance/v2/ctokens/#exchange-rate
            // The current exchange rate as an unsigned integer, scaled by 1 * 10^(18 - 8 + Underlying Token Decimals)
            const exchangeRate = ethers_1.ethers.utils.formatUnits(exchangerateWei, 18 - 8 + this.product.TokenDecimal);
            return Number(exchangeRate) || 0;
        });
    }
}
exports.CompoundStakeProduct = CompoundStakeProduct;

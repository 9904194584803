import { Dropdown } from 'semantic-ui-react'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import './depro.profile.css'
import { SigninButton, VerifyButton } from '../Btn'
import { ConnectWalletButton } from '../ConnectWallet'

export const Profile = () => {
  const { profile, logout } = useContext(AppContext)

  if (profile) {
    return (
      <Dropdown
        floating
        button
        text={profile.Name}
        className="profile-wrapper user"
        pointing="top right"
        onMouseEnter={(event: any) => {
          const target = event.currentTarget;
          target?.click();
          target?.focus();
        }}
      >
        <Dropdown.Menu className="profile-menu">
          <Dropdown.Item>
            <VerifyButton />
          </Dropdown.Item>
          <Dropdown.Item>
            <ConnectWalletButton theme="transparent" />
          </Dropdown.Item>
          <Dropdown.Item className="logout" text="Logout" onClick={logout} />
        </Dropdown.Menu>
      </Dropdown>
    )
  } else {
    return (
      <div className="profile-wrapper">
        <SigninButton />
      </div>
    )
  }
}

import { ToFixNum } from "../../common/utils";
import "./details.yearn.css"
import { Product } from "depro.common";


export const ProductDetailsYearnApy = ({ product }: { product: Product }) => {

  if (!product || !product.product_market) {
    return <></>
  }
  return (
    <div className="details-yearn">
      <h2>APY</h2>
      <ul>
        <li>
          <label>Weekly APY</label>
          <p>{ToFixNum((product.product_market.WeeklyApy || NaN * 100), 2)}%</p>
        </li>
        <li>
          <label>Monthly APY</label>
          <p>{ToFixNum(product.product_market.MonthlyApy || NaN * 100, 2)}%</p>
        </li>
        <li>
          <label>Inception APY</label>
          <p>{ToFixNum(product.product_market.InceptionApy || NaN * 100, 2)}%</p>
        </li>
        <li>
          <label>Gross APR</label>
          <p><strong>{ToFixNum(product.product_market.GrossApr || NaN * 100, 2)}%</strong></p>
        </li>
        <li>
          <label>Net APY</label>
          <p><strong>{ToFixNum(product.product_market.NetApy || NaN * 100, 2)}%</strong></p>
        </li>
      </ul>
    </div>
  )
}
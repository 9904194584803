import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import 'chartjs-adapter-moment'
import { ApyChartProps } from '.'
import { ToFixNum } from '../../common/utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
)

export const ApyChart = (props: ApyChartProps) => {
  const { data, color, width, height, border, legend } = props
  return (
    <Line
      style={{ maxWidth: width, maxHeight: height, border: border }}
      data={{
        datasets: [
          {
            label: 'APY',
            data: data,
            fill: false,
            borderColor: color,
            tension: 1,
            pointRadius: 0,
          },
        ],
      }}
      options={{
        scales: {
          x: {
            type: 'time',
            time: { unit: 'week' },
            grid: { display: false }
          },
          y: {
            min: 0,
            ticks: {
              stepSize: 0.1,
              maxTicksLimit: 5,
              callback: (value, _idx, _) => {
                return ToFixNum(value, 2) + "%"
              }
            }
          },
        },

        plugins: {
          legend: {
            display: legend ? true : false,
            labels: {
              usePointStyle: true,
              pointStyle: "line"
            }
          }
        },
      }}
    />
  )
}

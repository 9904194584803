import './bankcard.manage.css'
import { Button, Radio } from 'semantic-ui-react'
import { Bankcard } from 'depro.common'
import { maskCondential } from '../../common/utils'
import { useState, useEffect } from 'react'
import { getBankcard } from '../../api/account.api'
import BankcardLogo from '../../assets/images/bankcard.svg'
import TrashIco from '../../assets/images/trash.svg'
import AddIco from '../../assets/images/add-blue.svg'
import { useTranslation } from 'react-i18next'

interface ManageBankCardProp {
  onSelect: (card?: Bankcard) => void
  onModeChange: (newNode: string) => void
  onRemoveSelect: (card: Bankcard) => void
  currentCardId?: number
}

export const ManageBankCard = (props: ManageBankCardProp) => {
  const { t } = useTranslation(['card', 'common'])
  const { onSelect, onModeChange, onRemoveSelect, currentCardId } = props
  const [cards, setCards] = useState<Bankcard[]>([])
  const [selectedCard, setSelectedCard] = useState<Bankcard>()

  const fetchCards = async () => {
    const cardRes: Bankcard[] = await getBankcard()
    if (cardRes && cardRes.length > 0) {
      setCards(cardRes)
      cardRes.forEach((card) => {
        if (card.Id == currentCardId) {
          setSelectedCard(card)
        }
      })
    }
  }

  const confirmClose = () => {
    onSelect(selectedCard)
  }

  useEffect(() => {
    console.log('fetch cards')
    fetchCards()
  }, [])

  return (
    <div className="bankcard manage">
      <div className="cards">
        {cards.map((m: Bankcard) => {
          return (
            <div
              className="card-item"
              key={m.Number}
              onClick={() => {
                setSelectedCard(m)
              }}
            >
              <div className="left">
                <Radio
                  className="radio"
                  name="bank-group"
                  // checked
                  checked={selectedCard?.Number === m.Number}
                />
                <img className="ico" src={BankcardLogo} />
                <div className="number">{maskCondential(m.Number)}</div>
              </div>
              <div className="right">
                <img
                  src={TrashIco}
                  className="action-remove"
                  onClick={(e: any) => {
                    e.stopPropagation()
                    onRemoveSelect(m)
                  }}
                />
              </div>
            </div>
          )
        })}
        <div
          className="add-wrapper"
          onClick={() => {
            onModeChange('ADD')
          }}
        >
          <img src={AddIco} />
          <div className="text">{t('Add card')}</div>
        </div>
      </div>

      <Button className="confirm" onClick={() => confirmClose()}>
        {t('common:Confirm')}
      </Button>
    </div>
  )
}

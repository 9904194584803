import { Product, GetChainName } from 'depro.common'
import './productCard.css'
import { Button } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { formatDollar, ToFixPercent } from '../../../common/utils'
import { PlatformMap } from 'depro.common'

interface Prop {
  data: Product
}
export const ProductCard = (prop: Prop) => {
  const { t, i18n } = useTranslation(['product', 'common'])
  const { data } = prop
  const navigate = useNavigate()

  return (
    <div className="product-card">
      <div className="header">
        <img className="platform" src={PlatformMap[data.PlatformId].IconURL} />
        <div className="group">{t(`common:${PlatformMap[data.PlatformId].EarnGroup}`)}</div>
      </div>

      <div className="main">
        <img className="icon" src={data.IconURL} />
        <div className="wrapper">
          <div className="name">{data.TokenName}</div>
          <div className="network">{GetChainName(data.ChainId)}</div>
        </div>
      </div>

      <div className="info">
        <div className="item">
          <div className="label">{t('APY')}</div>
          <div className="value">
            {ToFixPercent(data.product_market?.Apy)}
          </div>
        </div>
        <div className="item">
          <div className="label">{t('Total supplied')}</div>
          <div className="value">
            ${formatDollar(data.product_market?.SupplyUSD || 0, i18n.language)}
          </div>
        </div>
      </div>

      <div className="action">
        <Button
          className="primary"
          onClick={() => {
            navigate(`/product/${data.Id}`)
          }}
        >
          {t('Subscribe')}
        </Button>
      </div>
    </div>
  )
}

import { useTranslation } from 'react-i18next'
import { GoogleLoginButton, TelegramLoginButton } from '.'

export const DeproLogin = () => {
  const { t } = useTranslation('login')
  return (
    <div className="deproLoginContainer">
      <div className="head modal-header-title">
        <div>{t('Welcome to De-Pro')}</div>
        <a className="btn-modal-close" href="/" />
      </div>
      <div className="body">
        <GoogleLoginButton text={t('Continue with Google')} />
        <TelegramLoginButton />
      </div>
    </div>
  )
}

import { useState, useEffect, useRef } from 'react'

const useCountDown = (seconds = 60) => {
  const [sessionId, setSessionId] = useState(0)
  const [timeLeft, setTimeLeft] = useState<number | null>(null)
  const [isfirstAttempt, setfirstAttempt] = useState(true)
  const id = useRef<number>()

  const clear = () => {
    window.clearInterval(id.current)
  }

  const start = () => {
    setTimeLeft(seconds)
    setSessionId(sessionId + 1)
    setfirstAttempt(false)
  }

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimeLeft((time) => (time || 0) - 1)
    }, 1000)
    return () => clear()
  }, [sessionId])

  useEffect(() => {
    if (timeLeft !== null) {
      if (timeLeft <= 0) {
        clear()
        setTimeLeft(null)
      }
    }
  }, [timeLeft])

  return {
    start,
    timeLeft,
    isfirstAttempt,
  }
}

export default useCountDown

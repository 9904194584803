"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLKycs = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLKycs {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLKyc created');
    }
    Add(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.kyc.create({
                data: {
                    UserId: record.UserId,
                    Nationality: record.Nationality,
                    FirstMiddleName: record.FirstMiddleName,
                    LastName: record.LastName,
                    IdType: record.IdType,
                    IdNumber: record.IdNumber,
                    IdProofFront: record.IdProofFront,
                    IdProofBack: record.IdProofBack,
                    Status: record.Status,
                },
            });
            return res;
        });
    }
    Upsert(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.kyc.upsert({
                where: {
                    UserId: record.UserId,
                },
                create: {
                    UserId: record.UserId,
                    Nationality: record.Nationality,
                    FirstMiddleName: record.FirstMiddleName,
                    LastName: record.LastName,
                    IdType: record.IdType,
                    IdNumber: record.IdNumber,
                    IdProofFront: record.IdProofFront,
                    IdProofBack: record.IdProofBack,
                    Status: record.Status,
                },
                update: {
                    Nationality: record.Nationality,
                    FirstMiddleName: record.FirstMiddleName,
                    LastName: record.LastName,
                    IdType: record.IdType,
                    IdNumber: record.IdNumber,
                    IdProofFront: record.IdProofFront,
                    IdProofBack: record.IdProofBack,
                    Status: types_1.TransactionStatus.Pending,
                },
            });
            return res;
        });
    }
    GetByUserId(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const r = yield this.ctx.kyc.findFirst({
                where: { UserId: Number(uid) },
            });
            return r;
        });
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const record = yield this.ctx.kyc.findUnique({
                where: { Id: Number(id) },
                include: {
                    user: true,
                },
            });
            if (record) {
                return record;
            }
            return null;
        });
    }
    Update(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.kyc.update({
                where: { Id: record.Id },
                data: {
                    Nationality: record.Nationality,
                    FirstMiddleName: record.FirstMiddleName,
                    LastName: record.LastName,
                    IdType: record.IdType,
                    IdNumber: record.IdNumber,
                    IdProofFront: record.IdProofFront,
                    IdProofBack: record.IdProofBack,
                    Status: record.Status,
                },
            });
            return res;
        });
    }
    Delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ctx.kyc.delete({
                    where: {
                        Id: Number(id),
                    },
                });
                return true;
            }
            catch (e) {
                console.log('Delete Kyc error from db', e);
                return false;
            }
        });
    }
    List(offset, status, id, lastName, firstMiddleName) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.kyc.findMany({
                where: {
                    LastName: { contains: lastName === null || lastName === void 0 ? void 0 : lastName.toString() },
                    FirstMiddleName: { contains: firstMiddleName === null || firstMiddleName === void 0 ? void 0 : firstMiddleName.toString() },
                    Status: status === null || status === void 0 ? void 0 : status.toString(),
                    IdNumber: { contains: id === null || id === void 0 ? void 0 : id.toString() },
                },
                skip: offset,
                take: 10,
                orderBy: { UpdatedAt: 'desc' },
            });
            return result;
        });
    }
    UpdateStatus(id, newStatus) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.kyc.update({
                where: {
                    Id: id,
                },
                data: {
                    Status: newStatus,
                },
            });
            return result;
        });
    }
}
exports.PrismaMySQLKycs = PrismaMySQLKycs;

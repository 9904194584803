import { useTranslation } from 'react-i18next'
import './payment.header.css'

export const PaymentHeader = () => {
  const { t } = useTranslation('payment')
  return (
    <div className="payment-header">
      <div className="title">{t('Pay with card')}</div>
    </div>
  )
}

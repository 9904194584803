"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLUtil = void 0;
//TODO test required
class PrismaMySQLUtil {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLUtil Created');
    }
    GetDateFromDB(unixTimeStamp) {
        return __awaiter(this, void 0, void 0, function* () {
            let ts = '';
            if (unixTimeStamp) {
                const unixSec = unixTimeStamp.toString().length > 10
                    ? Math.floor(unixTimeStamp / 1000)
                    : unixTimeStamp;
                const times = yield this.ctx
                    .$queryRaw `SELECT FROM_UNIXTIME(${unixSec});`;
                if (times.length > 0) {
                    ts = times[0]['FROM_UNIXTIME(?)'];
                }
            }
            else {
                const times = yield this.ctx.$queryRaw `select now();`;
                if (times.length > 0) {
                    ts = times[0]['now()'];
                }
            }
            return new Date(Date.parse(ts));
        });
    }
}
exports.PrismaMySQLUtil = PrismaMySQLUtil;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArbitrumScanServices = void 0;
const AbsNetworkScan_1 = require("./AbsNetworkScan");
class ArbitrumScanServices extends AbsNetworkScan_1.AbsNetworkScan {
    constructor(chainId, arbKey) {
        super(chainId, arbKey);
        switch (chainId) {
            //arbitrum mainnet
            case 42161:
                this.endpoint = `https://api.arbiscan.io`;
                break;
            default:
                throw new Error(`Invalid chain id for ArbitrumScan, chainid: ${chainId}`);
        }
    }
}
exports.ArbitrumScanServices = ArbitrumScanServices;

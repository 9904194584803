import './assets.css'
import { useEffect, useState } from 'react'
import { Button, Popup, Table } from 'semantic-ui-react'
import { RedemptionMain } from '../../components/RedeemDialog'
import { Earning, ApyLog, PlatformMap } from 'depro.common'
import { getProductApys } from '../../api/apy.api'
import { useTranslation } from 'react-i18next'
import { ToFixNum } from '../../common/utils'
import { NoResultFound } from '../../components/NoResultFound'
import { useLocation } from 'react-router-dom'

interface IMyAssets {
  earnings: Earning[]
  tokenRates: Record<string, number>
  redeemingProductIds: number[]
  reload: () => void
}
export const MyAssets = (props: IMyAssets) => {
  const { earnings, tokenRates, redeemingProductIds, reload } = props
  const location = useLocation()
  const { t } = useTranslation(['dashboard', 'common'])
  const [apyLogs, setApyLogs] = useState<ApyLog[]>([])

  const fetchApys = async () => {
    const resApy = await getProductApys()
    if (resApy) {
      setApyLogs(resApy)
    }
  }

  useEffect(() => {
    fetchApys()
    reload()
  }, [location.search])

  return (
    <div className="earn-wrapper">
      <Table basic="very" className="depro-table">
        <Table.Header>
          <Table.Row className="depro-table-heading">
            <Table.HeaderCell>{t('Token')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              {t('Platform')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {t('common:APY')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {t('Total Earnings')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {t('common:Balance')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {t('common:Action')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {earnings?.length > 0 ? (
            earnings.map((m: Earning) => {
              const apyLog = apyLogs.find((f) => f.ProductId === m.ProductId)
              const rate =
                tokenRates && m.products
                  ? tokenRates[m.products.TokenName]
                  : undefined
              return (
                <Table.Row
                  key={m.ProductId}
                  data-user-id={m.UserId}
                  data-product-id={m.ProductId}
                  verticalAlign="middle"
                >
                  <Table.Cell className="token">
                    <img src={m.products?.IconURL} className="ico" />
                    <div className="name">{m.products?.TokenName}</div>
                    {/* temp disabled action <div className="ico-action"></div> */}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <img
                      src={
                        PlatformMap[m.products!.PlatformId]?.IconURL ||
                        undefined
                      }
                      className="ico-platform"
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {apyLog ? `${ToFixNum(apyLog.Apy * 100, 2)}%` : 'N/A'}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <div className="money">
                      <div className="coin">
                        {ToFixNum(m.InterestEarned || 0)}{' '}
                        {PlatformMap[m.products?.PlatformId!]?.ATokenPrefix}
                        {m.products?.TokenName}
                      </div>
                      <small>
                        $
                        {rate
                          ? ToFixNum(Number(m.InterestEarned) / rate, 2)
                          : '0.00'}
                      </small>
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <div className="money">
                      <div className="coin">
                        {ToFixNum(m.ATokenBalance)}{' '}
                        {PlatformMap[m.products?.PlatformId!]?.ATokenPrefix}
                        {m.products?.TokenName}
                      </div>
                      <small>
                        $
                        {rate
                          ? ToFixNum(Number(m.ATokenBalance) / rate, 2)
                          : '0.00'}
                      </small>
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <div className="earn-action">
                      {redeemingProductIds.includes(m.ProductId) ? (
                        <Popup
                          content="Redemption is not available, please complete current redemption and try again later."
                          on="hover"
                          pinned
                          trigger={
                            <Button
                              content="Redeem"
                              className="primary redeem-disabled"
                              fluid
                            />
                          }
                          inverted
                          position="top center"
                          className="popup-text"
                        />
                      ) : (
                        <RedemptionMain
                          earning={m}
                          apy={apyLog?.Apy}
                          // platform={PlatformMap[m.products?.PlatformId!]}
                          reload={reload}
                        />
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="6" className="empty-data-wrapper">
                <NoResultFound />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLEarning = void 0;
const types_1 = require("../../common/types");
const utils_1 = require("../../common/utils");
const __1 = require("../..");
class PrismaMySQLEarning {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLEarning created');
    }
    Add(r) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.earnings.create({
                data: {
                    UserId: r.UserId,
                    ProductId: r.ProductId,
                    InterestEarned: r.InterestEarned,
                    ATokenBalance: r.ATokenBalance,
                },
            });
            return res;
        });
    }
    Delete(userId, productId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.earnings.delete({
                where: { UserId_ProductId: { UserId: userId, ProductId: productId } },
            });
            return res;
        });
    }
    UpdateBalance(r, change) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.$transaction((prisma) => __awaiter(this, void 0, void 0, function* () {
                const recordToUpdate = yield prisma.earnings.findUnique({
                    where: {
                        UserId_ProductId: {
                            UserId: r.UserId,
                            ProductId: r.ProductId,
                        },
                    },
                });
                if (!recordToUpdate) {
                    throw new Error('Record not found');
                }
                const oldValue = Number(recordToUpdate.ATokenBalance || 0);
                const newValue = oldValue + Number(change); // The increased value.
                const updatedRecord = yield prisma.earnings.update({
                    where: {
                        UserId_ProductId: {
                            UserId: r.UserId,
                            ProductId: r.ProductId,
                        },
                    },
                    data: {
                        ATokenBalance: newValue.toString(),
                    },
                });
                return updatedRecord;
            }));
            return res;
        });
    }
    GetByUserIdProductId(userId, productId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.earnings.findFirst({
                where: { UserId: userId, ProductId: productId },
                include: { products: true, user: true },
            });
            return res;
        });
    }
    GetByProductId(productId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.earnings.findMany({
                where: { ProductId: productId },
                include: { products: true, user: true },
            });
            return res;
        });
    }
    GetByUserIdProductIds(userId, productIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.earnings.findMany({
                where: {
                    UserId: userId,
                    ProductId: {
                        in: productIds,
                    },
                },
                include: { products: true, user: true },
            });
            return res;
        });
    }
    GetByUserId(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.earnings.findMany({
                where: { UserId: userId },
                include: {
                    products: true,
                    user: true,
                },
            });
            return res;
        });
    }
    BulkUpdateAmount(updates, ts, updateInterest = false) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.ctx.$transaction((prisma) => __awaiter(this, void 0, void 0, function* () {
                    return Promise.all(updates.map((m) => __awaiter(this, void 0, void 0, function* () {
                        const recordToUpdate = yield prisma.earnings.findUnique({
                            where: {
                                UserId_ProductId: {
                                    UserId: m.UserId,
                                    ProductId: m.ProductId,
                                },
                            },
                        });
                        if (!recordToUpdate) {
                            throw new Error(`Record not foundL ${JSON.stringify({
                                UserId: m.UserId,
                                ProductId: m.ProductId,
                            })}`);
                        }
                        const newBalance = Number(recordToUpdate.ATokenBalance || 0) + m.AmountChange;
                        const newInterest = updateInterest
                            ? Number(recordToUpdate.InterestEarned || 0) + m.AmountChange
                            : undefined;
                        const updatedRecord = yield prisma.earnings.update({
                            where: {
                                UserId_ProductId: {
                                    UserId: m.UserId,
                                    ProductId: m.ProductId,
                                },
                            },
                            data: {
                                ATokenBalance: newBalance.toString(),
                                InterestEarned: newInterest === null || newInterest === void 0 ? void 0 : newInterest.toString(),
                            },
                        });
                        return updatedRecord;
                    })));
                }));
                return res.length;
            }
            catch (e) {
                console.error('Exception batch updating earning amount');
            }
        });
    }
    GetInterestEntitledList(userID, earnGroup, minAmountUsd) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const minUsd = Number(minAmountUsd || 0);
                // earn group
                const groupPlatform = (0, utils_1.GetEarnGroupPlatforms)(earnGroup);
                const earnGroupCondition = groupPlatform === null || groupPlatform === void 0 ? void 0 : groupPlatform.map((platform) => {
                    return { products: { PlatformId: { contains: `-${platform}-` } } };
                });
                const validRecords = [];
                const [tr, earnings] = yield Promise.all([
                    this.ctx.token_rate.findMany(),
                    this.ctx.earnings.findMany({
                        where: {
                            AND: [
                                { UserId: userID },
                                {
                                    OR: earnGroupCondition,
                                },
                            ],
                        },
                        include: {
                            products: true,
                            user: true,
                        },
                    }),
                ]);
                if (tr && earnings) {
                    earnings.forEach((earn) => {
                        var _a;
                        const productRate = (_a = tr.find((f) => { var _a; return f.Token === ((_a = earn.products) === null || _a === void 0 ? void 0 : _a.TokenName); })) === null || _a === void 0 ? void 0 : _a.Rate;
                        if (productRate) {
                            if (Number(earn.ATokenBalance || 0) / Number(productRate) >
                                minUsd) {
                                validRecords.push(earn);
                            }
                        }
                        else {
                            console.warn('No product rate for token:', earn.products.TokenName);
                            validRecords.push(earn);
                        }
                    });
                    return validRecords;
                }
                else {
                    throw new Error(`No rates or eanrings. Rate len: ${tr.length}. Earn len: ${earnings.length}`);
                }
            }
            catch (e) {
                console.error('Expection on GetInterestEntitledList:', e);
                return [];
            }
        });
    }
    GetBizEarning(businessId, clientId) {
        return __awaiter(this, void 0, void 0, function* () {
            const businessClients = yield this.ctx.user.findMany({
                where: {
                    ParentId: businessId,
                    AccountType: types_1.UserAccountType.Client,
                    ClientId: clientId,
                },
            });
            const clientIds = businessClients.map((b) => b.Id);
            const [businessEarning, clientEarnings] = yield Promise.all([
                this.ctx.earnings.findMany({
                    where: { UserId: Number(businessId) },
                    include: { products: true },
                    orderBy: { ProductId: 'asc' },
                }),
                this.ctx.earnings.findMany({
                    where: { UserId: { in: clientIds } },
                    include: { user: true },
                    orderBy: { ProductId: 'asc' },
                }),
            ]);
            const result = [];
            businessEarning.forEach((b) => {
                var _a, _b, _c;
                result.push({
                    ChainId: b.products.ChainId,
                    PlatformId: b.products.PlatformId,
                    ProductId: b.ProductId,
                    Balance: b.ATokenBalance,
                    InterestEarned: b.InterestEarned || '0',
                    Timestamp: b.Timestamp,
                    ChainName: (_a = __1.ChainDataArray.find((f) => f.chain_id === b.products.ChainId)) === null || _a === void 0 ? void 0 : _a.name,
                    Platform: (_b = __1.PlatformMap[b.products.PlatformId]) === null || _b === void 0 ? void 0 : _b.Platform,
                    Token: b.products.TokenName,
                    AToken: ((_c = __1.PlatformMap[b.products.PlatformId]) === null || _c === void 0 ? void 0 : _c.ATokenPrefix) +
                        b.products.TokenName,
                    ClientEarnings: clientEarnings
                        .filter((c) => c.ProductId === b.ProductId)
                        .map((m) => {
                        return {
                            ClientId: m.user.ClientId,
                            InterestEarned: m.InterestEarned,
                            ATokenBalance: m.ATokenBalance,
                            Timestamp: m.Timestamp,
                        };
                    }),
                });
            });
            return result;
        });
    }
}
exports.PrismaMySQLEarning = PrismaMySQLEarning;

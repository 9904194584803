"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetScanServicebyId = void 0;
const ArbiScan_1 = require("./ArbiScan");
const Avascan_1 = require("./Avascan");
const Polygonscan_1 = require("./Polygonscan");
const Etherscan_1 = require("./Etherscan");
const { EtherscanKey, PolygonscanKey, AvalanchescanKey, ArbitrumscanKey } = process.env;
const GetScanServicebyId = (chain) => {
    const chainId = Number(chain);
    switch (chainId) {
        case 1:
        case 5:
            if (EtherscanKey)
                return new Etherscan_1.EtherScanServices(chainId, EtherscanKey);
            else
                throw new Error('No EtherscanKey');
        case 137:
        case 80001:
            if (PolygonscanKey)
                return new Polygonscan_1.PolygonScanServices(chainId, PolygonscanKey);
            else
                throw new Error('No PolygonscanKey');
        case 43114:
        case 43113:
            if (AvalanchescanKey)
                return new Avascan_1.AvaScanServices(chainId, AvalanchescanKey);
            else
                throw new Error('No AvalanchescanKey');
        case 42161:
        case 421613:
            if (ArbitrumscanKey)
                return new ArbiScan_1.ArbitrumScanServices(chainId, ArbitrumscanKey);
            else
                throw new Error('No ArbitrumscanKey');
        default:
            throw Error('Invalid chain scan services');
    }
};
exports.GetScanServicebyId = GetScanServicebyId;

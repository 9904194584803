import { useContext, useEffect } from 'react'
import { authTelegram } from '../../../api/auth.api'
import { ProfileType } from '../../../common/enums'
import AppContext from '../../../context/AppContext'

declare global {
  interface Window {
    TelegramLoginWidget: any
  }
}

export const TelegramLoginButton = () => {
  const { REACT_APP_TELEGRAM_BOT } = process.env
  const { setProfile } = useContext(AppContext)

  const onTelegramAuth = async (authRes: any) => {
    // console.debug('onTelegramAuth', authRes)

    const h = authRes.hash
    delete authRes.hash
    const res = await authTelegram(h, authRes)
    // console.debug('tg login res', res)
    setProfile({
      Type: ProfileType.Telegram,
      Name: res.Name,
      DeproId: res.Id,
      KYCStatus: res.KYCStatus,
      Token: res.Token,
      Email: res.Email,
    })
  }

  useEffect(() => {
    window.TelegramLoginWidget = {
      onTelegramAuth: (user: any) => onTelegramAuth(user),
    }
    const script: any = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-widget.js?19'
    script.async = true
    script.setAttribute('data-telegram-login', REACT_APP_TELEGRAM_BOT)
    script.setAttribute('data-size', 'large')
    script.setAttribute(
      'data-onauth',
      'TelegramLoginWidget.onTelegramAuth(user)'
    )
    script.setAttribute('data-request-access', 'write')
    document.getElementById('tg-login-container')?.appendChild(script)
    return () => {
      document.getElementById('tg-login-container')?.replaceChildren()
    }
  }, [])

  return (
    <>
      <div id="tg-login-container"></div>
    </>
  )
}

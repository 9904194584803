"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLTokenRate = void 0;
class PrismaMySQLTokenRate {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLTokenRate Created');
    }
    Add(rate) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ctx.token_rate.create({
                data: {
                    Token: rate.Token,
                    Rate: rate.Rate,
                },
            });
        });
    }
    Update(rates) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.$transaction(rates.map((m) => {
                return this.ctx.token_rate.upsert({
                    where: {
                        Token: m.Token,
                    },
                    update: {
                        Rate: m.Rate,
                    },
                    create: {
                        Token: m.Token,
                        Rate: m.Rate,
                    },
                });
            }));
            return res;
        });
    }
    Get(tokens) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ctx.token_rate.findMany({
                where: { Token: { in: tokens } },
            });
        });
    }
    GetAll() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.ctx.token_rate.findMany();
        });
    }
    GetByName(name) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.ctx.token_rate.findFirst({
                where: {
                    Token: name,
                },
            });
        });
    }
    GetMap(tokens) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.token_rate.findMany({
                where: { Token: { in: tokens } },
            });
            const map = {};
            res.forEach((f) => {
                map[f.Token] = Number(f.Rate) || undefined;
            });
            return map;
        });
    }
}
exports.PrismaMySQLTokenRate = PrismaMySQLTokenRate;

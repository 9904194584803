import "./details.token.css"
import { GetChainName, PlatformMap, Product } from "depro.common";

export const TokenInfo = ({ product }: { product: Product }) => {
  if (!product) {
    return <>Product not found</>
  }
  return (
    <div className="details-token">
      <img className="ico" src={product.IconURL} />
      <div>
        <div className="symbol" token-address={product.TokenAddress}>
          {product.TokenName}
        </div>
        <div className="platform-network">
          {PlatformMap[product.PlatformId].Platform} | {GetChainName(product.ChainId)}
        </div>
      </div>
    </div>
  )
}
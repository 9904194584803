"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteErrorType = exports.EarnGroup = exports.ApprovalType = exports.NoteType = exports.TransactionStatus = exports.PaymentChannel = exports.UserAccountType = exports.SubscriptionStatus = exports.RedemptionStatus = exports.ProductStatus = exports.EmailCodeType = exports.KYCStatus = exports.EarnType = void 0;
var EarnType;
(function (EarnType) {
    EarnType["Both"] = "BOTH";
    EarnType["Subscription"] = "SUBSCRIPTION";
    EarnType["Redemption"] = "REDEMPTION";
})(EarnType || (exports.EarnType = EarnType = {}));
var KYCStatus;
(function (KYCStatus) {
    KYCStatus["Pending"] = "PENDING";
    KYCStatus["Success"] = "SUCCESS";
    KYCStatus["Fail"] = "FAIL";
    KYCStatus["NotInit"] = "NOTINIT";
})(KYCStatus || (exports.KYCStatus = KYCStatus = {}));
var EmailCodeType;
(function (EmailCodeType) {
    EmailCodeType["Account"] = "ACCOUNT";
    EmailCodeType["Redemption"] = "REDEMPTION";
})(EmailCodeType || (exports.EmailCodeType = EmailCodeType = {}));
var ProductStatus;
(function (ProductStatus) {
    ProductStatus["Hot"] = "HOT";
    ProductStatus["Active"] = "ACTIVE";
    ProductStatus["Deleted"] = "DELETED";
    ProductStatus["PendingApproval"] = "PENDING_APPROVAL";
    ProductStatus["ApprovalFailed"] = "APPROVAL_FAIL";
    ProductStatus["Approving"] = "APPROVING";
})(ProductStatus || (exports.ProductStatus = ProductStatus = {}));
var RedemptionStatus;
(function (RedemptionStatus) {
    RedemptionStatus["Created"] = "CREATED";
    RedemptionStatus["Approved"] = "APPROVED";
    RedemptionStatus["Redeeming"] = "REDEEMING";
    RedemptionStatus["Transferring"] = "TRASNFERRING";
    RedemptionStatus["Success"] = "SUCCESS";
    RedemptionStatus["ApprovalFail"] = "APPROVAL_FAIL";
    RedemptionStatus["RedeemFail"] = "REDEEM_FAIL";
    RedemptionStatus["TransferFail"] = "TRANSFER_FAIL";
    RedemptionStatus["Cancelled"] = "CANCELLED";
    RedemptionStatus["PendingTransfer"] = "PENDING_TRANSFER";
    RedemptionStatus["SwapFail"] = "SWAP_FAIL";
    RedemptionStatus["Swapping"] = "SWAPPING";
    RedemptionStatus["ReserveRdmFail"] = "RESERVE_REDEEM_FAIL";
})(RedemptionStatus || (exports.RedemptionStatus = RedemptionStatus = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Created"] = "CREATED";
    SubscriptionStatus["Swapping"] = "SWAPPING";
    SubscriptionStatus["PendingDeposit"] = "PENDING_DEPOSIT";
    SubscriptionStatus["Depositing"] = "DEPOSITING";
    SubscriptionStatus["Success"] = "SUCCESS";
    SubscriptionStatus["FundFail"] = "FUND_FAIL";
    SubscriptionStatus["SwapFail"] = "SWAP_FAIL";
    SubscriptionStatus["DepositFail"] = "DEPOSIT_FAIL";
    SubscriptionStatus["Cancelled"] = "CANCELLED";
})(SubscriptionStatus || (exports.SubscriptionStatus = SubscriptionStatus = {}));
var UserAccountType;
(function (UserAccountType) {
    UserAccountType["Normal"] = "Normal";
    UserAccountType["Business"] = "Business";
    UserAccountType["Client"] = "Client";
})(UserAccountType || (exports.UserAccountType = UserAccountType = {}));
var PaymentChannel;
(function (PaymentChannel) {
    PaymentChannel["Crypto"] = "CRYPTO";
    PaymentChannel["Bank"] = "BANK";
    PaymentChannel["Credit"] = "CREDIT";
    PaymentChannel["BusinessReserve"] = "BUSINESS_RESERVE";
})(PaymentChannel || (exports.PaymentChannel = PaymentChannel = {}));
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["Created"] = "CREATED";
    TransactionStatus["Pending"] = "PENDING";
    TransactionStatus["Success"] = "SUCCESS";
    TransactionStatus["Fail"] = "FAIL";
})(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
var NoteType;
(function (NoteType) {
    NoteType["Redemption"] = "REDEMPTION";
    NoteType["Subscription"] = "SUBSCRIPTION";
})(NoteType || (exports.NoteType = NoteType = {}));
var ApprovalType;
(function (ApprovalType) {
    ApprovalType["Uniswap"] = "UNISWAP";
    ApprovalType["Stake"] = "STAKE";
})(ApprovalType || (exports.ApprovalType = ApprovalType = {}));
var EarnGroup;
(function (EarnGroup) {
    EarnGroup["Stake"] = "STAKE";
    EarnGroup["Vault"] = "VAULT";
})(EarnGroup || (exports.EarnGroup = EarnGroup = {}));
var QuoteErrorType;
(function (QuoteErrorType) {
    QuoteErrorType["None"] = "None";
    QuoteErrorType["InsufficientBalance"] = "ERR_IB";
    QuoteErrorType["SoldOut"] = "ERR_SO";
    QuoteErrorType["InvalidSwap"] = "ERR_IS";
})(QuoteErrorType || (exports.QuoteErrorType = QuoteErrorType = {}));

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLFiatRedeemNoticeLog = void 0;
class PrismaMySQLFiatRedeemNoticeLog {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLFiatRedeemNoticeLog created');
    }
    Add(fiatNoticeLog) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fiat_redeem_notice_log.create({
                data: {
                    OrderId: fiatNoticeLog.OrderId,
                    Amount: fiatNoticeLog.Amount,
                    Currency: fiatNoticeLog.Currency,
                    Type: fiatNoticeLog.Type,
                    Status: fiatNoticeLog.Status,
                    Sign: fiatNoticeLog.Sign,
                    Uuid: fiatNoticeLog.Uuid,
                    Signature: fiatNoticeLog.Signature,
                },
            });
            return res;
        });
    }
}
exports.PrismaMySQLFiatRedeemNoticeLog = PrismaMySQLFiatRedeemNoticeLog;

import './product.css'
import { DeproHeader } from '../../components'
import { AllProducts } from '../../components/Products'
import { EarnGroup } from 'depro.common'
import { useTranslation } from 'react-i18next'

export const Product = ({ group }: { group: EarnGroup }) => {
  const { t } = useTranslation(["common", "product"])
  return (
    <div className="product app-bg">
      <div className="app-padding">
        <DeproHeader />
        <div className='earn-group'>
          <div className='title'>{t(group)}</div>
          <div className='desc'>{t(`product:${group}-desc`)}</div>
        </div>
        <AllProducts earnGroup={group} />
      </div>
    </div>
  )
}

import { request } from './request.api'
import { EarnType } from '../common/types'

export const getTokenRates = async (
  tokenSymbol?: string[],
  map = false
): Promise<Record<string, number>> => {
  const mapParam = `?map=${map}`
  let queryParams = ''
  if (tokenSymbol && tokenSymbol.length > 0) {
    queryParams =
      '&tokens=' +
      tokenSymbol.filter((e, i) => tokenSymbol.indexOf(e) === i).join(',')
  }

  const resApy = await request(`/exrate/token${mapParam}${queryParams}`)
  return resApy
}

/**
 * *FiatRate also served as list of fiat currency for user to choose now
 * */
export const getFiatRates = async (earnType?: EarnType, map = false) => {
  let url = `/exrate/fiat?earnType=${earnType}&map=${map}`
  const resApy = await request(url)
  return resApy
}

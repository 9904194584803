"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBalancesAbi = void 0;
exports.getBalancesAbi = [
    {
        inputs: [
            { internalType: 'address', name: '_token', type: 'address' },
            { internalType: 'address', name: '_holder', type: 'address' },
        ],
        name: 'balanceOf',
        outputs: [
            {
                components: [
                    { internalType: 'address', name: 'tokenAddress', type: 'address' },
                    { internalType: 'uint256', name: 'balance', type: 'uint256' },
                ],
                internalType: 'struct TokenBalance[]',
                name: '',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            { internalType: 'address', name: '_holder', type: 'address' },
            { internalType: 'address[]', name: '_erc20Addresses', type: 'address[]' },
            {
                internalType: 'address[]',
                name: '_cTokenAddresses',
                type: 'address[]',
            },
            {
                internalType: 'address[]',
                name: '_yTokenAddresses',
                type: 'address[]',
            },
        ],
        name: 'getBalances',
        outputs: [
            {
                components: [
                    { internalType: 'address', name: 'tokenAddress', type: 'address' },
                    { internalType: 'uint256', name: 'balance', type: 'uint256' },
                ],
                internalType: 'struct TokenBalance[]',
                name: '',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];

import { useEffect, useState, useContext, useRef } from 'react'
import { Button, Modal, Dropdown } from 'semantic-ui-react'
import {
  EarnType,
  FiatRate,
  Platform,
  Product,
  KYCStatus,
  PaymentChannel,
  IToken,
  PlatformMap,
} from 'depro.common'
import './inputs.css'
import { useTranslation } from 'react-i18next'
import { ToFixNum } from '../../common/utils'
import { RedeemOptions, RedemptionData, QuoteResponse } from '.'
import AppContext from '../../context/AppContext'
import { getFiatRates, getTokenRates } from '../../api/exrate.api'
import { getAllProducts } from '../../api/product.api'
import { ConnectWalletMessage } from '../ConnectWallet'
import { redeemQuote } from '../../api/earn.api'
import usePrevious from '../../hooks/usePrevious'
import { useDebounce } from '@usedapp/core/dist/esm/src/hooks'

interface IRedemptionInputs {
  product: Product
  balance: string
  onClose: () => void
  onRedeem: (data: RedemptionData) => void
  onVerify: () => void
}

interface CurrencyOption {
  key: number
  text: string
  value: string
  image: { avatar: boolean; src: string }
  decimals: number
}

export const RedemptionInputs = (props: IRedemptionInputs) => {
  const { product, balance, onClose, onRedeem, onVerify } = props
  const { profile, address } = useContext(AppContext)
  const inputRedeem = useRef<HTMLInputElement>(null)
  const { t } = useTranslation(['redemption', 'common'])
  const [redeemChannel, setRedeemChannel] = useState<PaymentChannel>(
    PaymentChannel.Crypto
  )
  const [actionButton, setActionButton] = useState<{
    disabled: boolean
    text: string
  }>({ disabled: true, text: '' })
  const [redeemAccount, setRedeemAccount] = useState<string>()

  const [bounceAmount, setBounceAmount] = useState<string>()
  const redeemAmount = useDebounce(bounceAmount, 1000)
  const [receiveAmount, setReceiveAmount] = useState<string>()
  const [receiveAmountUsd, setReceiveAmountUsd] = useState<string>()
  const [fiatRateMap, setFiatRateMap] = useState<Record<string, number>>()
  const [tokenRateMap, setTokenRateMap] = useState<Record<string, number>>()
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([])
  const [tokenCurrency, setTokenCurrency] = useState<IToken>()
  const [fiatCurrency, setFiatCurrency] = useState<string>()
  const [iconCurrency, setIconCurrency] = useState<string>()
  const [selectedOption, setSelectedOption] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [nextFetchId, setNextFetchId] = useState(0)
  const [quote, setQuote] = useState<QuoteResponse>()
  const previousQuote = usePrevious<QuoteResponse | undefined>(quote)
  const [bankcardCurrency, setBankcardCurrency] = useState<string>()

  const handleRedeem = async () => {
    //* verify path
    if (
      redeemChannel === PaymentChannel.Bank &&
      profile?.KYCStatus !== KYCStatus.Success
    ) {
      onVerify()
      return
    }
    if (product && fiatRateMap && tokenRateMap) {
      // * redeem
      const redemptionData: RedemptionData = {
        productId: product.Id,
        amount: redeemAmount || '0',
        channel: redeemChannel,
        account: redeemAccount || '',
        currency:
          (redeemChannel === PaymentChannel.Bank
            ? fiatCurrency
            : tokenCurrency) || '',

        receiveAmount: receiveAmount || '0',
        receiveAmountUsd: ToFixNum(
          Number(receiveAmount || 0) / fiatRateMap![fiatCurrency!]
        ).toString(),
        amountUsd: ToFixNum(
          Number(redeemAmount || 0) / tokenRateMap![product.TokenName]
        ).toString(),
        rateProductCurrency:
          redeemChannel === PaymentChannel.Bank
            ? fiatRateMap[fiatCurrency!] / tokenRateMap[product.TokenName]
            : tokenRateMap[tokenCurrency!.name] /
            tokenRateMap[product.TokenName],
        currencyIconUrl: iconCurrency || '',
      }
      onRedeem(redemptionData)
    }
  }

  const fetchRates = async () => {
    const [fiatRates, tokenRates] = await Promise.all([
      getFiatRates(EarnType.Redemption, true),
      getTokenRates([], true),
    ])
    if (fiatRates) {
      setFiatRateMap(fiatRates)
    }
    if (tokenRates) {
      setTokenRateMap(tokenRates)
    }
  }

  const calReceivedAmount = async () => {
    try {
      if (
        !redeemAmount ||
        Number(redeemAmount) <= 0 ||
        Number(redeemAmount) > Number(balance)
      ) {
        setReceiveAmount(undefined)
        setReceiveAmountUsd(undefined)
        return
      }
      setLoading(true)

      const newNextId = nextFetchId + 1
      setNextFetchId(newNextId)

      const q = await redeemQuote({
        productId: product.Id,
        amount: redeemAmount || '0',
        channel: redeemChannel,
        currency:
          (redeemChannel === PaymentChannel.Bank
            ? fiatCurrency
            : tokenCurrency) || '',
        fetchId: newNextId,
      })

      if (q && !q.error) {
        setQuote(q)
      } else {
        setQuote(undefined)
      }
    } catch (e) {
      console.error('exception calReceivedAmount', e)
    } finally {
      setLoading(false)
    }
  }

  const fetchCurrencyOptions = async () => {
    if (redeemChannel === PaymentChannel.Crypto) {
      const products: Product[] = await getAllProducts()
      let options = products
        .filter((f) => f.ChainId === product?.ChainId)
        .map((m) => {
          return {
            key: m.Id,
            text: m.TokenName,
            value: m.TokenAddress || m.TokenName,
            image: { avatar: true, src: m.IconURL },
            decimals: m.TokenDecimal,
          }
        })

      //TODO wait support of swapping token of avalanche
      if (product?.ChainId === 43113 || product?.ChainId === 43114) {
        options = options.filter((f) => f.value === product.TokenAddress)
      }

      if (options) setCurrencyOptions(options)
    } else if (redeemChannel === PaymentChannel.Bank) {
      const fiats = await getFiatRates(EarnType.Redemption)
      let options = fiats.map((m: FiatRate) => {
        return {
          key: m.Id,
          text: m.Currency,
          value: m.Currency,
          image: { avatar: false, src: m.IconURL },
        }
      })
      if (options) setCurrencyOptions(options)
    }
  }

  //! quotes
  useEffect(() => {
    if (quote) {
      const fetchId = quote.clientFetchId
      if (fetchId < (previousQuote?.clientFetchId || 0)) {
        console.warn('Abort late response')
      } else {
        if (isNaN(quote.amountUnit) || isNaN(quote.amountUsd)) {
          console.error(`Error res ${JSON.stringify(quote)}`)
        } else {
          setReceiveAmount(
            quote.amountUnit > 0 ? quote.amountUnit.toString() : '0'
          )
          setReceiveAmountUsd(
            quote.amountUsd > 0 ? quote.amountUsd.toString() : '0'
          )
        }
      }
    }
  }, [quote])

  //! currency options
  useEffect(() => {
    fetchCurrencyOptions()
  }, [redeemChannel])

  //! reserved controlling the receive amount
  useEffect(() => {
    calReceivedAmount()
  }, [redeemAmount, redeemChannel, fiatCurrency, tokenCurrency, redeemAccount])

  //! reserved control the action button
  useEffect(() => {
    //* 1 pass KYC
    if (
      redeemChannel === PaymentChannel.Bank &&
      profile?.KYCStatus !== KYCStatus.Success
    ) {
      setActionButton({
        disabled: false,
        text: 'Verify',
      })
      return
    }

    //* 2 pass amount: non empty
    if (!redeemAmount) {
      setActionButton({
        disabled: true,
        text: 'Enter an amount',
      })
      return
    }

    if (
      Number(redeemAmount) === Number(balance) &&
      (Number(receiveAmount) <= 0 || !quote)
    ) {
      setActionButton({
        disabled: true,
        text: 'Insufficient balance',
      })
      return
    }

    //* 3 pass amount: allowed ranges
    if (
      Number(redeemAmount) > Number(balance) ||
      Number(receiveAmount) <= 0 ||
      !quote
    ) {
      setActionButton({
        disabled: true,
        text: 'Invalid amount',
      })
      return
    }

    //* 4 pass amount non empty
    if (!redeemAccount) {
      setActionButton({
        disabled: true,
        text: 'Choose account',
      })
      return
    }

    //* choose currency
    if (redeemChannel === PaymentChannel.Bank && !fiatCurrency) {
      setActionButton({
        disabled: true,
        text: 'Choose currency',
      })
      return
    }

    if (redeemChannel === PaymentChannel.Crypto && !tokenCurrency) {
      setActionButton({
        disabled: true,
        text: 'Choose token',
      })
      return
    }

    if (
      redeemChannel === PaymentChannel.Bank &&
      fiatCurrency !== bankcardCurrency
    ) {
      setActionButton({
        disabled: true,
        text: 'Wrong currency',
      })
      return
    }

    //* set ok
    setActionButton({
      disabled: false,
      text: 'Redeem',
    })
  }, [
    redeemAmount,
    redeemAccount,
    redeemChannel,
    receiveAmount,
    tokenCurrency,
    fiatCurrency,
    quote,
  ])

  useEffect(() => {
    if (selectedOption) {
      const opt = currencyOptions.find((f) => f.value === selectedOption)
      if (opt) {
        setIconCurrency(opt.image.src)
        switch (redeemChannel) {
          case PaymentChannel.Bank:
            setFiatCurrency(opt.value)
            break
          case PaymentChannel.Crypto:
            setTokenCurrency({
              address: opt.value || opt.text,
              symbol: opt.text,
              name: opt.text,
              decimals: opt.decimals,
            })
            break
          default:
            console.error('InValid option', opt)
            return
        }
      }
    }
  }, [selectedOption, currencyOptions])
  // update the default value
  useEffect(() => {
    if (currencyOptions?.length > 0) {
      switch (redeemChannel) {
        case PaymentChannel.Bank:
          if (!selectedOption) {
            setSelectedOption('USD')
          }
          break
        case PaymentChannel.Crypto:
          let opt = currencyOptions.find(
            (f) => f.value === product.TokenAddress
          )
          const defaultVal = opt ? opt.value : currencyOptions[0].value
          setSelectedOption(defaultVal)
          break
        default:
          return
      }
    }
  }, [currencyOptions])

  /**
   * init fetch
   */
  useEffect(() => {
    fetchRates()
  }, [])

  return (
    <>
      <Modal.Header>
        <div className="channels">
          <div
            className={`channel ${redeemChannel === PaymentChannel.Crypto ? 'selected' : ''
              }`}
            onClick={() => setRedeemChannel(PaymentChannel.Crypto)}
          >
            {t('Crypto currencies')}
          </div>
          <div
            className={`channel ${redeemChannel === PaymentChannel.Bank ? 'selected' : ''
              }`}
            onClick={() => setRedeemChannel(PaymentChannel.Bank)}
          >
            {t('Fiat currencies')}
          </div>

        </div>
        <div className="btn-modal-close" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        {redeemChannel === PaymentChannel.Crypto && !address ? (
          <div className="redeem-content">
            <div className="crypto-connect">
              <ConnectWalletMessage />
            </div>
          </div>
        ) : (
          <div className="redeem-content">
            <div className="from token-amount-wrapper">
              <div className="main">
                <div className="left">
                  <label>From</label>
                  <input
                    ref={inputRedeem}
                    type="number"
                    placeholder="0.00"
                    onChange={(e) => {
                      setBounceAmount(e.target.value)
                      setLoading(true)
                    }}
                  />
                  <small>
                    $
                    {redeemAmount && tokenRateMap
                      ? ToFixNum(
                        Number(redeemAmount || 0) /
                        tokenRateMap[product.TokenName],
                        2
                      )
                      : '0'}
                  </small>
                </div>
                <div className="right">
                  <img className="ico" src={product.IconURL} />
                  <div className="text">
                    {PlatformMap[product.PlatformId!].ATokenPrefix}
                    {product.TokenName}
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="balance">Balance: {ToFixNum(balance)}</div>
                <a
                  href="#"
                  className="max"
                  onClick={() => {
                    if (inputRedeem.current) {
                      inputRedeem.current.value = ToFixNum(balance).toString()
                    }
                    setBounceAmount(balance)
                  }}
                >
                  Max
                </a>
              </div>
            </div>
            <div className="to token-amount-wrapper">
              <div className="main">
                <div className="left">
                  <label>To (estimate)</label>
                  <input
                    type="text"
                    readOnly={true}
                    placeholder="0.00"
                    value={
                      receiveAmount
                        ? redeemChannel === PaymentChannel.Bank
                          ? ToFixNum(receiveAmount, 2)
                          : ToFixNum(receiveAmount)
                        : ''
                    }
                  />
                  <small className="usd">
                    ${receiveAmountUsd ? ToFixNum(receiveAmountUsd, 2) : 0}
                  </small>
                </div>
                <div className="right">
                  <Dropdown
                    className="coin-selection"
                    placeholder="Select token"
                    inline
                    pointing="top right"
                    scrolling
                    value={selectedOption}
                    onChange={(_, o) => setSelectedOption(o.value?.toString())}
                    options={currencyOptions}
                  />
                </div>
              </div>
              <div className="info">
                {tokenCurrency &&
                  tokenRateMap &&
                  redeemChannel === PaymentChannel.Crypto && (
                    <small className="rate">
                      1 {PlatformMap[product.PlatformId!].ATokenPrefix}
                      {product.TokenName} ≈{' '}
                      {ToFixNum(
                        tokenRateMap[tokenCurrency.name] /
                        tokenRateMap[product.TokenName]
                      )}{' '}
                      {tokenCurrency.name}
                    </small>
                  )}

                {fiatCurrency &&
                  tokenRateMap &&
                  fiatRateMap &&
                  redeemChannel === PaymentChannel.Bank && (
                    <small className="rate">
                      1 {PlatformMap[product.PlatformId!].ATokenPrefix}
                      {product.TokenName} ≈{' '}
                      {ToFixNum(
                        fiatRateMap[fiatCurrency] /
                        tokenRateMap[product.TokenName],
                        2
                      )}{' '}
                      {fiatCurrency}
                    </small>
                  )}
                <small className="fee">
                  Fee:{' '}
                  {product.RedemptionCharge === null ||
                    product.RedemptionCharge === undefined
                    ? '-'
                    : '$' + product.RedemptionCharge}
                </small>
              </div>
            </div>
            <RedeemOptions
              channel={redeemChannel}
              onSelectAccount={(account: string) => {
                setRedeemAccount(account)
              }}
              onCurrencyChanged={(currency: string) => {
                setSelectedOption(currency)
                if (redeemChannel === PaymentChannel.Bank) {
                  setBankcardCurrency(currency)
                }
              }}
              onVerify={onVerify}
            />
            <div className="action-wrapper">
              <Button
                className="primary modal-btn"
                disabled={actionButton.disabled || loading}
                onClick={handleRedeem}
                loading={loading}
              >
                {actionButton.text}
              </Button>
            </div>
          </div>
        )}
      </Modal.Content>
    </>
  )
}

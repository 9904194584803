"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsFireBlocksId = exports.FireblocksWallet = void 0;
const fireblocks_sdk_1 = require("fireblocks-sdk");
const ethers_1 = require("ethers");
const api_1 = require("avalanche/dist/apis/evm/api");
const erc20Abi_1 = require("../assets/erc20Abi");
const avalanche_1 = require("avalanche");
const utils_1 = require("../common/utils");
const fs = __importStar(require("fs"));
const path = __importStar(require("path"));
class FireblocksWallet {
    constructor(chainId, assetId) {
        this.getBlockNumber = () => __awaiter(this, void 0, void 0, function* () {
            if (this.provider instanceof api_1.EVMAPI) {
                const blockParam = yield this.provider.callMethod('eth_blockNumber');
                return Number(blockParam.data.result);
            }
            if (this.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                return this.provider.getBlockNumber();
            }
            return 0;
        });
        this.getProductsBalance = (data, blockTime) => __awaiter(this, void 0, void 0, function* () {
            try {
                let tokenData, ethBalance;
                if (this.provider instanceof api_1.EVMAPI) {
                    tokenData = (yield this.provider.callMethod('eth_call', [
                        { to: this.balanceContractAddress, data },
                        (blockTime === null || blockTime === void 0 ? void 0 : blockTime.toString()) || 'latest',
                    ])).data.result;
                    let ethBalanceData = yield this.provider.callMethod('eth_getBalance', [
                        this.address,
                        'latest',
                    ]);
                    if (ethBalanceData) {
                        ethBalance = ethers_1.BigNumber.from(ethBalanceData.data.result);
                    }
                }
                if (this.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                    tokenData = yield this.provider.call({
                        to: this.balanceContractAddress,
                        data: data,
                    });
                    ethBalance = yield this.provider.getBalance(this.address);
                }
                if (tokenData && ethBalance) {
                    const tokensBalance = tokenData.substring(130).match(/.{1,64}/g);
                    let formmatedTokensBalance = {};
                    if (tokensBalance) {
                        for (let i = 0; i < tokensBalance.length; i += 2) {
                            formmatedTokensBalance['0x' + tokensBalance[i].substring(24)] =
                                ethers_1.BigNumber.from('0x' + tokensBalance[i + 1]);
                        }
                        formmatedTokensBalance['eth'] = ethBalance;
                        return formmatedTokensBalance;
                    }
                }
            }
            catch (e) {
                console.error(`Unable to get products balance on chain ${this.chainId}, data: ${data}`);
                console.error(e);
            }
            return null;
        });
        this.assetId = assetId;
        this.chainId = chainId;
        const { FIREBLOCKS_API_SECRET_PATH, FIREBLOCKS_API_KEY, FIREBLOCKS_SOURCE_VAULT_ACCOUNT, InfuraKey, AlchemyKey, ETHEREUM_BALANCE_CONTRACT_ADDRESS, POLYGON_BALANCE_CONTRACT_ADDRESS, AVALANCHE_BALANCE_CONTRACT_ADDRESS, ARBITRUM_BALANCE_CONTRACT_ADDRESS, } = process.env;
        if (FIREBLOCKS_API_SECRET_PATH &&
            FIREBLOCKS_API_KEY &&
            FIREBLOCKS_SOURCE_VAULT_ACCOUNT &&
            InfuraKey &&
            AlchemyKey &&
            ETHEREUM_BALANCE_CONTRACT_ADDRESS &&
            POLYGON_BALANCE_CONTRACT_ADDRESS &&
            AVALANCHE_BALANCE_CONTRACT_ADDRESS &&
            ARBITRUM_BALANCE_CONTRACT_ADDRESS) {
            const apiSecret = fs.readFileSync(path.resolve(__dirname, FIREBLOCKS_API_SECRET_PATH), 'utf8');
            this.fireblocks = new fireblocks_sdk_1.FireblocksSDK(apiSecret, FIREBLOCKS_API_KEY);
            this.infuraKey = InfuraKey;
            this.vaultAccountId = FIREBLOCKS_SOURCE_VAULT_ACCOUNT;
            this.alchemyKey = AlchemyKey;
            switch (chainId) {
                // * ETH mainnet or testnet
                case 1:
                case 5:
                    this.provider = new ethers_1.ethers.providers.InfuraProvider(chainId, this.infuraKey);
                    this.balanceContractAddress = ETHEREUM_BALANCE_CONTRACT_ADDRESS;
                    break;
                // * Polygon mainnet or testnet
                case 137:
                case 80001:
                    this.provider = new ethers_1.ethers.providers.AlchemyProvider(chainId, this.alchemyKey);
                    this.balanceContractAddress = POLYGON_BALANCE_CONTRACT_ADDRESS;
                    break;
                // * AVALANCHE mainnet or testnet
                case 43114:
                case 43113:
                    //TODO this.provider = new ethers.providers.AnkrProvider(chainId)
                    const INFURA_ENDPOINT = chainId == 43114
                        ? 'avalanche-mainnet.infura.io'
                        : 'avalanche-fuji.infura.io';
                    const BASE_URL = `/v3/${InfuraKey}`;
                    const avalanche = new avalanche_1.Avalanche(INFURA_ENDPOINT, 443, 'https');
                    this.provider = new api_1.EVMAPI(avalanche, BASE_URL);
                    this.balanceContractAddress = AVALANCHE_BALANCE_CONTRACT_ADDRESS;
                    break;
                case 42161:
                case 421613:
                    this.provider = new ethers_1.ethers.providers.AlchemyProvider(chainId, this.alchemyKey);
                    this.balanceContractAddress = ARBITRUM_BALANCE_CONTRACT_ADDRESS;
                    break;
                default:
                    throw new Error(`Invalid chain Id ${chainId}`);
            }
        }
        else {
            throw new Error(`Miss env: ${JSON.stringify({
                FIREBLOCKS_API_SECRET_PATH,
                FIREBLOCKS_API_KEY,
                FIREBLOCKS_SOURCE_VAULT_ACCOUNT,
                InfuraKey,
                AlchemyKey,
                ETHEREUM_BALANCE_CONTRACT_ADDRESS,
                POLYGON_BALANCE_CONTRACT_ADDRESS,
                AVALANCHE_BALANCE_CONTRACT_ADDRESS,
                ARBITRUM_BALANCE_CONTRACT_ADDRESS,
            })}`);
        }
    }
    initWalletAddress() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('param', {
                valut: this.vaultAccountId,
                aasset: this.assetId,
            });
            try {
                const addrs = yield this.fireblocks.getDepositAddresses(this.vaultAccountId, this.assetId);
                this.address = addrs[0].address;
            }
            catch (e) {
                console.error('Exception initWalletAddress', this.assetId);
                console.error(e);
            }
        });
    }
    getNonce() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.address) {
                yield this.initWalletAddress();
            }
            if (this.provider instanceof api_1.EVMAPI) {
                const blockParam = yield this.provider.callMethod('eth_blockNumber');
                return yield (yield this.provider.callMethod('eth_getTransactionCount', [
                    this.address,
                    blockParam.data.result,
                ])).data.result;
            }
            return yield this.provider.getTransactionCount(this.address);
        });
    }
    getGasPriceWei() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.provider instanceof api_1.EVMAPI) {
                return yield (yield this.provider.callMethod('eth_gasPrice')).data.result;
            }
            return yield this.provider.getGasPrice();
        });
    }
    // null is pending
    getTransactionReceipt(hash) {
        return __awaiter(this, void 0, void 0, function* () {
            // first get the fireblocks id
            let txnHash;
            if ((0, exports.IsFireBlocksId)(hash)) {
                const chainHash = yield this.getHashByFireblocksId(hash);
                if (chainHash === null) {
                    //* not yet ready
                    return null;
                }
                else {
                    if (chainHash === '') {
                        //* fail or rejected in firebocks
                        return {
                            status: 0,
                            transactionHash: '',
                        };
                    }
                    else {
                        txnHash = chainHash;
                    }
                }
            }
            else {
                txnHash = hash;
            }
            if (this.provider instanceof api_1.EVMAPI) {
                return yield (yield this.provider.callMethod('eth_getTransactionReceipt', [txnHash])).data.result;
            }
            return yield this.provider.getTransactionReceipt(txnHash);
        });
    }
    getHashByFireblocksId(fireblocksId) {
        return __awaiter(this, void 0, void 0, function* () {
            const txn = yield this.fireblocks.getTransactionById(fireblocksId);
            if (txn &&
                (txn.status === fireblocks_sdk_1.TransactionStatus.COMPLETED ||
                    txn.status === fireblocks_sdk_1.TransactionStatus.FAILED)) {
                return txn.txHash || '';
            }
            else {
                return null;
            }
        });
    }
    getTxHashById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            var status = '';
            var txHash = '';
            while (status !== fireblocks_sdk_1.TransactionStatus.COMPLETED &&
                status !== fireblocks_sdk_1.TransactionStatus.FAILED &&
                txHash == '') {
                yield new Promise((resolve) => setTimeout(resolve, 1000));
                const txn = yield this.fireblocks.getTransactionById(id);
                status = txn.status;
                txHash = txn.txHash;
            }
            // console.log((await this.fireblocks.getTransactionById(id)))
            return txHash;
        });
    }
    // supportedChainIds: number[]
    // providers: {[key: string]: ethers.providers.BaseProvider | EVMAPI}
    getETHBalance() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.fireblocks.getMaxSpendableAmount(this.vaultAccountId, this.assetId);
            return ethers_1.BigNumber.from(response.maxSpendableAmount);
        });
    }
    getTokenAddress(assetId) {
        return __awaiter(this, void 0, void 0, function* () {
            const addrs = yield this.fireblocks.getDepositAddresses(this.vaultAccountId, assetId);
            if (addrs == null || addrs.length == 0) {
                console.warn(`Address not found, token:${assetId}`);
                return null;
            }
            return addrs[0].address; // TODO: how to identify the correct address from different chains?
        });
    }
    signContract(contract) {
        return __awaiter(this, void 0, void 0, function* () {
            throw Error('Not Implemented.');
        });
    }
    // Return the hash of the new transaction.
    createContractTransaction(trans) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.log({ AssetId: this.assetId });
                const payload = {
                    operation: fireblocks_sdk_1.TransactionOperation.CONTRACT_CALL,
                    assetId: this.assetId,
                    source: {
                        type: fireblocks_sdk_1.PeerType.VAULT_ACCOUNT,
                        id: this.vaultAccountId,
                    },
                    destination: {
                        type: fireblocks_sdk_1.PeerType.ONE_TIME_ADDRESS,
                        oneTimeAddress: { address: trans.to },
                    },
                    // decimals of eth, matic, avax are 18
                    amount: trans.value ? ethers_1.ethers.utils.formatUnits(trans.value, 18) : 0,
                    note: 'Contract Call Transaction',
                    extraParameters: {
                        contractCallData: trans.data,
                    },
                };
                const fireblocksTxn = yield this.fireblocks.createTransaction(payload);
                return fireblocksTxn.id;
            }
            catch (e) {
                console.log(e.message);
                return e;
            }
        });
    }
    createETHTransaction(to, amount) {
        return __awaiter(this, void 0, void 0, function* () {
            // Return the hash of the new transaction.
            const payload = {
                assetId: this.assetId,
                source: {
                    type: fireblocks_sdk_1.PeerType.VAULT_ACCOUNT,
                    id: this.vaultAccountId,
                },
                destination: {
                    type: fireblocks_sdk_1.PeerType.ONE_TIME_ADDRESS,
                    oneTimeAddress: { address: to },
                },
                amount: amount,
                note: 'Created by fireblocks SDK',
            };
            const id = yield (yield this.fireblocks.createTransaction(payload)).id;
            return yield this.getTxHashById(id);
        });
    }
    // Supports ethereum, avalanche, polygon.
    // if no token address, it will send the native currency
    createTransferTransaction(to, amount, tokenAddr) {
        return __awaiter(this, void 0, void 0, function* () {
            const assets = yield this.fireblocks.getSupportedAssets();
            const asset = tokenAddr
                ? assets.find((f) => f.contractAddress.toLowerCase() == tokenAddr.toLowerCase() &&
                    f.nativeAsset == this.assetId)
                : assets.find((f) => f.id === this.assetId);
            console.log('debug transfer txn', {
                tokenAddr,
                assetId: this.assetId,
                found: assets.find((f) => f.contractAddress.toLowerCase() == (tokenAddr === null || tokenAddr === void 0 ? void 0 : tokenAddr.toLowerCase())),
            });
            if (!asset) {
                console.error(`Asset not found: ${tokenAddr}`);
                return '';
            }
            // console.log(JSON.stringify(asset)); // {"id":"USDC_ETH_TEST3_S5QX","name":"USDC","type":"ERC20","contractAddress":"0x9FD21bE27A2B059a288229361E2fA632D8D2d074","nativeAsset":"ETH_TEST3","decimals":6}
            const payload = {
                assetId: asset.id,
                source: {
                    type: fireblocks_sdk_1.PeerType.VAULT_ACCOUNT,
                    id: this.vaultAccountId,
                },
                destination: {
                    type: fireblocks_sdk_1.PeerType.ONE_TIME_ADDRESS,
                    oneTimeAddress: { address: to },
                },
                amount: amount,
                note: 'Created by fireblocks SDK',
            };
            const fireTxn = yield this.fireblocks.createTransaction(payload);
            return fireTxn.id;
        });
    }
    createAVAXTransaction(to, amount, account, chainId, gasLimit = '0xD710') {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.createTransferTransaction(account, String(Number(amount) / 1000000), to);
        });
    }
    getTokenDecimal(address) {
        return __awaiter(this, void 0, void 0, function* () {
            if (address !== 'ETH') {
                if (this.provider instanceof api_1.EVMAPI) {
                    const iface = new ethers_1.ethers.utils.Interface(erc20Abi_1.erc20Abi);
                    const data = iface.encodeFunctionData('decimals');
                    const res = yield this.provider.callMethod('eth_call', [
                        { to: address, data },
                        'latest',
                    ]);
                    return res.data.result;
                }
                if (this.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                    const tokenContract = new ethers_1.ethers.Contract(address, erc20Abi_1.erc20Abi, this.provider);
                    const balance = yield tokenContract.decimals();
                    return balance;
                }
            }
            return 18;
        });
    }
    readBalance(to, data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (to !== 'ETH') {
                if (this.provider instanceof api_1.EVMAPI) {
                    const res = yield this.provider.callMethod('eth_call', [
                        { to: to, data: data },
                        'latest',
                    ]);
                    return ethers_1.BigNumber.from(res.data.result);
                }
                if (this.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                    const tokenContract = new ethers_1.ethers.Contract(to, ['function balanceOf(address) view returns (uint)'], this.provider);
                    const balance = yield tokenContract.balanceOf(this.address);
                    return balance;
                }
            }
            return ethers_1.BigNumber.from(0);
        });
    }
    checkTransaction(txnHash) {
        return __awaiter(this, void 0, void 0, function* () {
            let txnReceipt = {};
            if (this.provider instanceof api_1.EVMAPI) {
                const res = yield this.provider.callMethod('eth_getTransactionReceipt', [
                    txnHash,
                ]);
                txnReceipt = res.data.result;
            }
            if (this.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                txnReceipt = yield this.provider.getTransactionReceipt(txnHash);
            }
            if (txnReceipt) {
                return {
                    status: txnReceipt.status,
                    sender: txnReceipt.from,
                    receiver: txnReceipt.logs[0].topics[2].replace('0x000000000000000000000000', '0x'),
                    tokenAddress: txnReceipt.to,
                    amountWei: ethers_1.BigNumber.from(txnReceipt.logs[0].data),
                    hash: txnHash,
                };
            }
            return null;
        });
    }
    checkSwapReceiveTransaction(hash, receiveToken) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.address) {
                yield this.initWalletAddress();
            }
            let txnHash = hash;
            if ((0, exports.IsFireBlocksId)(hash)) {
                const chainHash = yield this.getHashByFireblocksId(hash);
                if (chainHash === null) {
                    return null;
                }
                else {
                    if (chainHash === '') {
                        //* fail or rejected in firebocks
                        return {
                            status: 0,
                            hash: '',
                        };
                    }
                    else {
                        txnHash = chainHash;
                    }
                }
            }
            else {
                txnHash = hash;
            }
            let txnReceipt = {};
            if (this.provider instanceof api_1.EVMAPI) {
                const res = yield this.provider.callMethod('eth_getTransactionReceipt', [
                    txnHash,
                ]);
                txnReceipt = res.data.result;
            }
            const receiveTokenAddress = receiveToken.toUpperCase().includes('0X')
                ? receiveToken
                : (_a = (0, utils_1.GetChainDataById)(this.chainId)) === null || _a === void 0 ? void 0 : _a.wrap_native_currency.contractAddress;
            console.log({ receiveTokenAddress });
            if (!receiveTokenAddress) {
                return null;
            }
            if (this.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                txnReceipt = yield this.provider.getTransactionReceipt(txnHash);
            }
            let tokenReceive = ethers_1.BigNumber.from(0);
            if (txnReceipt && Number(txnReceipt.status) !== 0) {
                if (receiveToken.toUpperCase().includes('0X')) {
                    txnReceipt.logs.forEach((log) => {
                        if (log.address.toUpperCase() == receiveTokenAddress.toUpperCase()) {
                            if (log.topics[2] &&
                                log.topics[2].includes(this.address.toLowerCase().substring(2))) {
                                console.log(log.topics[2]);
                                tokenReceive = tokenReceive.add(ethers_1.BigNumber.from(log.data));
                            }
                        }
                    });
                }
                else {
                    txnReceipt.logs.forEach((log) => {
                        if (log.address.toUpperCase() == receiveTokenAddress.toUpperCase()) {
                            if (log.topics[2] &&
                                log.topics[2].includes(txnReceipt.to.toLowerCase().substring(2))) {
                                console.log(log.topics[2]);
                                tokenReceive = tokenReceive.add(ethers_1.BigNumber.from(log.data));
                            }
                        }
                    });
                }
                return {
                    status: txnReceipt.status,
                    sender: txnReceipt.from,
                    receiver: txnReceipt.logs[0].topics[2].replace('0x000000000000000000000000', '0x'),
                    tokenAddress: txnReceipt.to,
                    amountWei: tokenReceive,
                    hash: txnHash,
                };
            }
            return null;
        });
    }
    getTransaction(txnHash) {
        return __awaiter(this, void 0, void 0, function* () {
            let txnReceipt = {};
            if (this.provider instanceof api_1.EVMAPI) {
                const res = yield this.provider.callMethod('eth_getTransactionByHash', [
                    txnHash,
                ]);
                txnReceipt = res.data.result;
            }
            if (this.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                txnReceipt = yield this.provider.getTransaction(txnHash);
            }
            return txnReceipt;
        });
    }
}
exports.FireblocksWallet = FireblocksWallet;
const IsFireBlocksId = (hash) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(hash); // true
};
exports.IsFireBlocksId = IsFireBlocksId;

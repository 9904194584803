"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPrismaMySQLDatabase = void 0;
const User_1 = require("./User");
const TokenRate_1 = require("./TokenRate");
const Kycs_1 = require("./Kycs");
const Products_1 = require("./Products");
const Bankcards_1 = require("./Bankcards");
const Fund_1 = require("./Fund");
const ApyLog_1 = require("./ApyLog");
const FiatRate_1 = require("./FiatRate");
const FiatNoticeLog_1 = require("./FiatNoticeLog");
const VerificationCode_1 = require("./VerificationCode");
const ProductInterestLog_1 = require("./ProductInterestLog");
const ProductBalance_1 = require("./ProductBalance");
const TransactionStake_1 = require("./TransactionStake");
const TransactionSwap_1 = require("./TransactionSwap");
const TransactionApproval_1 = require("./TransactionApproval");
const FiatRedeemNoticeLog_1 = require("./FiatRedeemNoticeLog");
const Redemption_1 = require("./Redemption");
const Subscription_1 = require("./Subscription");
const Earning_1 = require("./Earning");
const ManagementUser_1 = require("./ManagementUser");
const Util_1 = require("./Util");
const Transaction_1 = require("./Transaction");
const Contracts_1 = require("./Contracts");
const ProductMarket_1 = require("./ProductMarket");
const GetPrismaMySQLDatabase = (dbContext) => {
    return {
        Users: new User_1.PrismaMySQLUser(dbContext),
        TokenRate: new TokenRate_1.PrismaMySQLTokenRate(dbContext),
        Kycs: new Kycs_1.PrismaMySQLKycs(dbContext),
        Products: new Products_1.PrismaMySQLProducts(dbContext),
        Bankcards: new Bankcards_1.PrismaMySQLBankcards(dbContext),
        Funds: new Fund_1.PrismaMySQLFunds(dbContext),
        ApyLogs: new ApyLog_1.PrismaMySQLApyLogs(dbContext),
        FiatRate: new FiatRate_1.PrismaMySQLFiatRate(dbContext),
        FiatNoticeLog: new FiatNoticeLog_1.PrismaMySQLFiatNoticeLog(dbContext),
        VerificationCode: new VerificationCode_1.PrismaMySQLVerificationCode(dbContext),
        ProductInterestLogs: new ProductInterestLog_1.PrismaMySQLProductInterestLog(dbContext),
        ProductBalance: new ProductBalance_1.PrismaMySQLProductBalance(dbContext),
        TransactionSwap: new TransactionSwap_1.PrismaMySQLTransactionSwap(dbContext),
        TransactionStake: new TransactionStake_1.PrismaMySQLTransactionStake(dbContext),
        TransactionApproval: new TransactionApproval_1.PrismaMySQLTransactionApproval(dbContext),
        FiatRedeemNoticeLog: new FiatRedeemNoticeLog_1.PrismaMySQLFiatRedeemNoticeLog(dbContext),
        Redemption: new Redemption_1.PrismaMySQLRedemption(dbContext),
        Subscriptions: new Subscription_1.PrismaMySQLSubscription(dbContext),
        Earning: new Earning_1.PrismaMySQLEarning(dbContext),
        ManagementUser: new ManagementUser_1.PrismaMySQLManagementUser(dbContext),
        Util: new Util_1.PrismaMySQLUtil(dbContext),
        Transaction: new Transaction_1.PrismaMySQLTransaction(dbContext),
        Contracts: new Contracts_1.PrismaMySQLContracts(dbContext),
        ProductMarket: new ProductMarket_1.PrismaMySQLProductMarket(dbContext),
    };
};
exports.GetPrismaMySQLDatabase = GetPrismaMySQLDatabase;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLTransactionSwap = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLTransactionSwap {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLTransactionSwap Created');
    }
    Add(swap) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_swap.create({
                data: {
                    OrderId: swap.OrderId,
                    ChainId: swap.ChainId,
                    FromAddress: swap.FromAddress,
                    FromAmount: swap.FromAmount,
                    ToAddress: swap.ToAddress,
                    ToAmount: swap.ToAmount,
                    Hash: swap.Hash,
                    Status: swap.Status,
                    Note: swap.Note,
                },
            });
            return res;
        });
    }
    BulkUpdateStatus(swaps) {
        return __awaiter(this, void 0, void 0, function* () {
            const now = new Date();
            const res = yield this.ctx.$transaction(swaps.map((m) => {
                var _a;
                return this.ctx.transaction_swap.update({
                    where: {
                        Id: m.swapId,
                    },
                    data: {
                        ToAmount: (_a = m.toAmount) === null || _a === void 0 ? void 0 : _a.toString(),
                        Status: m.status,
                        Hash: m.hash,
                    },
                });
            }));
            return res.length;
        });
    }
    GetByNoteAndId(note, orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_swap.findFirst({
                where: {
                    OrderId: orderId,
                    Note: note,
                },
                orderBy: {
                    CreatedAt: 'desc',
                },
            });
            return res;
        });
    }
    GetListByNoteAndId(note, orderIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_swap.findMany({
                where: {
                    Note: note,
                    OrderId: { in: orderIds },
                },
            });
            return res;
        });
    }
    GetLongPendingTransactions(timestamp) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_swap.findMany({
                where: {
                    Status: types_1.TransactionStatus.Pending,
                    UpdatedAt: {
                        lte: timestamp,
                    },
                },
            });
            return res;
        });
    }
    AddWithRedemptionUpdate(swap, redemptionId, newStatus) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.ctx.$transaction([
                this.ctx.transaction_swap.create({
                    data: {
                        OrderId: swap.OrderId,
                        ChainId: swap.ChainId,
                        FromAddress: swap.FromAddress,
                        FromAmount: swap.FromAmount,
                        ToAddress: swap.ToAddress,
                        ToAmount: swap.ToAmount,
                        Hash: swap.Hash,
                        Status: swap.Status,
                        Note: swap.Note,
                    },
                }),
                this.ctx.redemptions.update({
                    where: {
                        Id: redemptionId,
                    },
                    data: {
                        Status: newStatus,
                    },
                }),
            ]);
        });
    }
}
exports.PrismaMySQLTransactionSwap = PrismaMySQLTransactionSwap;

import {
  Product,
  IToken,
  User,
  KYCStatus,
  PaymentChannel,
  FiatRate,
} from 'depro.common'
import { ISwapProvider } from '../swaps/ISwap'
import { ProfileType } from './enums'

export interface IApp {
  user?: User
  profile: Profile | null
  provider: any
  web3Provider: any
  address?: string
  chainId?: number
  swapProvider?: ISwapProvider
  tokens: IToken[]
  nativeCurrency?: IToken
  wrapNativeCurrency?: IToken
  trade?: Trade
  setProvider: (p: any) => void
  setWeb3Provider: (p: any) => void
  setAddress: (p?: string) => void
  setChainId: (p?: number) => void
  setSwapProvider: (p?: ISwapProvider) => void
  setTokens: (p: IToken[]) => void
  setNativeCurrency: (p?: IToken) => void
  setWrapNativeCurrency: (p?: IToken) => void
  setUser: (User?: User) => void
  setProfile: (p: Profile | null) => void
  connectWallet: (isAutoConnect?: boolean) => void
  disconnectWallet: () => void
  logout: () => void
  setTrade: (t?: Trade) => void
}

export type Profile = {
  Type: ProfileType
  Name: string // email or tg id or wallet addrestt
  DeproId: number //depro only
  KYCStatus?: KYCStatus //depro only
  Token: string //depro only
  Email: string | null
}

export type Trade = {
  currency?: FiatRate // for fiat
  tokenCurrency?: TokenCurrency // for token
  amount: number
  amountUSD: number
  receiveAmount: number
  receiveAmountUsd: number
  proudctData: Product
  currencyPerProductRate?: number
  receiveAddress?: string //only in defi
}

export type FiatPaymentParams = {
  productId: number
  fiatChannel: PaymentChannel
  fiatCardId: number
  payAmount: number
  stakeAmount: number
  currency: string
  backHost: string
  noticeUrl: string
}
export type DefiPaymentParams = {
  productId: number
  txn: string
  address: string
  payAmount: number
  stakeAmount: number
  currency: string
}

export enum EarnType {
  Both = 'BOTH',
  Subscription = 'SUBSCRIPTION',
  Redemption = 'REDEMPTION',
}

export type Quote = {
  productId: number
  amount: string
  channel: PaymentChannel
  currency: string | IToken
  fetchId?: number
}

export type TokenCurrency = IToken & {
  icon: string
}

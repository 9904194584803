import './hotProducts.css'
import { useEffect, useRef, useState } from 'react'
import { getHotProducts } from '../../../api/product.api'
import { Product, ProductStatus } from 'depro.common'
import { ProductCard } from '../ProductCard/productCard'
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper.min.css"
import { ReactComponent as LeftSvg } from '../../../assets/images/swiper-left.svg'
import { ReactComponent as RightSvg } from '../../../assets/images/swiper-right.svg'
import useMediaQuery from "../../../hooks/useMediaQuery"

SwiperCore.use([Navigation, Pagination]);

export const HotProduct = () => {
  const [productData, setProductData] = useState<Product[]>([])
  const one = useMediaQuery('(max-width: 992px)')
  const two = useMediaQuery('(max-width: 1500px)')
  const three = useMediaQuery('(max-width: 1800px)')

  const numView = one ? 1 : two ? 2 : three ? 3 : 4

  const hotProduct = productData.filter((f) => f.Status === ProductStatus.Hot)

  const fetchInfo = async () => {
    const res = await getHotProducts()
    if (res && !res.error) {
      setProductData(res)
    }
  }

  useEffect(() => {
    fetchInfo()
  }, [])

  return (
    <div className="hot-product-wrapper">
      <div className='swiper-button-prev control'><LeftSvg /></div>
      <Swiper
        spaceBetween={10}
        slidesPerView={Math.min(hotProduct.length, numView)}
        navigation={{ prevEl: ".swiper-button-prev", nextEl: ".swiper-button-next" }}
        pagination={{ clickable: true, }}
        onSlideChange={() => { }}
        onSwiper={(_) => { }}
        observer={true}
      >
        {hotProduct.map((data: Product) => {
          return (
            <SwiperSlide key={data.Id}>
              <ProductCard data={data} />
            </SwiperSlide>
          )
        })
        }
      </Swiper>
      <div className='swiper-button-next control' ><RightSvg /></div>
    </div >
  )
}

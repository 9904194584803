import { ToFixNum, formatDollar } from "../../common/utils";
import "./details.information.css"
import { GetChainDataById, PlatformMap, Product } from "depro.common";
import { useTranslation } from 'react-i18next'

export const ProductDetailsInformation = ({ product }: { product: Product }) => {

  const { i18n } = useTranslation()
  const platform = PlatformMap[product.PlatformId]

  if (!product) {
    return <></>
  }
  return (
    <div className="details-info">
      <h2>Information</h2>
      <ul>
        <li>
          <label>Earn</label>
          <div className="value">
            <img src={product.IconURL} />
            <strong>{PlatformMap[product.PlatformId].ATokenPrefix}{product.TokenName}</strong>
          </div>
        </li>
        <li>
          <label>Total supplied</label>
          <div className="value">
            <strong>${formatDollar(product.product_market?.SupplyUSD || 0, i18n.language)}</strong>
          </div>
        </li>
        <li>
          <label>Platform</label>
          <div className="value">
            <img src={platform.IconURL} />
            <strong>{platform.Platform}</strong>
          </div>
        </li>
        <li>
          <label>Network</label>
          <div className="value">
            <img src={GetChainDataById(product.ChainId)?.chain_icon} />
            <strong>{GetChainDataById(product.ChainId)?.name}</strong>
          </div>
        </li>
        {(platform.Platform === "AAVE" || platform.Platform === "COMPOUND") && (
          <>
            <li>
              <label>Available liquidity</label>
              <div className="value">
                <strong>${formatDollar(product.product_market?.AvailableLiquidity)}</strong>
              </div>
            </li>
            <li>
              <label>Price</label>
              <div className="value">
                <strong>${ToFixNum(product.product_market?.UnitPriceUSD, 2, true)}</strong>
              </div>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}
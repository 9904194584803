"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLFunds = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLFunds {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLOrder created');
    }
    Add(fund) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fund.create({
                data: {
                    Note: fund.Note,
                    Channel: fund.Channel,
                    Account: fund.Account,
                    Txn: fund.Txn,
                    Currency: fund.Currency,
                    Amount: fund.Amount,
                    OrderId: fund.OrderId,
                    Status: fund.Status,
                    UserId: fund.UserId,
                    IsBatchProcessing: fund.IsBatchProcessing,
                    CurrencyAddress: fund.CurrencyAddress,
                },
            });
            return res;
        });
    }
    GetUserLatestRecords(uid, status, offset, size) {
        return __awaiter(this, void 0, void 0, function* () {
            const funds = yield this.ctx.fund.findMany({
                skip: offset,
                take: size,
                orderBy: [{ UpdatedAt: 'desc' }],
                where: { UserId: Number(uid), Status: status },
            });
            return funds;
        });
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const fund = yield this.ctx.fund.findUnique({
                where: { Id: Number(id) },
            });
            return fund;
        });
    }
    Update(fund) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fund.update({
                where: { Id: fund.Id },
                data: {
                    Note: fund.Note,
                    Channel: fund.Channel,
                    Account: fund.Account,
                    Currency: fund.Currency,
                    Amount: fund.Amount,
                    Status: fund.Status,
                    Txn: fund.Txn,
                    IsBatchProcessing: fund.IsBatchProcessing || false,
                    CurrencyAddress: fund.CurrencyAddress,
                },
            });
            return res;
        });
    }
    Delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ctx.fund.delete({
                    where: {
                        Id: Number(id),
                    },
                });
                return true;
            }
            catch (e) {
                console.log('Delete fund error from db', id, e);
                return false;
            }
        });
    }
    GetLongPendingSubsciption(dt) {
        return __awaiter(this, void 0, void 0, function* () {
            const funds = yield this.ctx.fund.findMany({
                where: {
                    Note: types_1.NoteType.Subscription,
                    Status: types_1.TransactionStatus.Pending,
                    IsBatchProcessing: false,
                    UpdatedAt: {
                        lte: dt,
                    },
                },
            });
            return funds;
        });
    }
    BulkUpdateStatus(funds) {
        return __awaiter(this, void 0, void 0, function* () {
            // const now = new Date()
            const res = yield this.ctx.$transaction(funds.map((m) => {
                var _a;
                return this.ctx.fund.update({
                    where: {
                        Id: m.fundId,
                    },
                    data: {
                        Amount: (_a = m.amount) === null || _a === void 0 ? void 0 : _a.toString(),
                        Status: m.newStatus,
                        Txn: m.updatedHash,
                    },
                });
            }));
            return res.length;
        });
    }
    GetLatestFundByOrderId(orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fund.findFirst({
                where: { OrderId: orderId, Status: 'FAIL' },
                orderBy: { Id: 'desc' },
            });
            return res;
        });
    }
    GetListByNoteAndIdAndStatus(note, orderIds, status) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fund.findMany({
                where: { Note: note, OrderId: { in: orderIds }, Status: status },
                orderBy: { Id: 'desc' },
            });
            return res;
        });
    }
    GetLatestByNoteAndId(note, orderId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fund.findFirst({
                where: { Note: note, OrderId: orderId },
                orderBy: { Id: 'desc' },
            });
            return res;
        });
    }
    GetLongPendingTransactions(dt) {
        return __awaiter(this, void 0, void 0, function* () {
            const funds = yield this.ctx.fund.findMany({
                where: {
                    Status: types_1.TransactionStatus.Pending,
                    IsBatchProcessing: false,
                    UpdatedAt: {
                        lte: dt,
                    },
                },
            });
            return funds;
        });
    }
    UpdateStatus(id, newStatus) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.fund.update({
                where: {
                    Id: id,
                },
                data: {
                    Status: newStatus,
                },
            });
            return result;
        });
    }
    GetByOrderIdsAndNoteType(orderIds, note) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fund.findMany({
                where: {
                    Note: types_1.NoteType.Redemption,
                    OrderId: { in: orderIds },
                },
                orderBy: { OrderId: 'asc' },
            });
            return res;
        });
    }
    GetByTxn(hash) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fund.findFirst({
                where: {
                    Txn: hash,
                },
            });
            return res;
        });
    }
}
exports.PrismaMySQLFunds = PrismaMySQLFunds;

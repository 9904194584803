import { request } from './request.api'
import { Bankcard } from 'depro.common'

export const getBankcard = async () => {
  return request(`/user/bankcard`)
}

export const addBankcard = async (card: Bankcard) => {
  return request(`/user/bankcard`, {
    method: 'POST',
    body: JSON.stringify({ card }),
  })
}

export const removeBankcard = async (card: Bankcard) => {
  return request(`/user/bankcard/${card?.Id}`, {
    method: 'DELETE',
  })
}

/**
 * @deprecated
 * @param chainId
 * @param address
 * @returns
 */
export const getUserDefiTxn = async (
  chainId: number | string,
  address: string
) => {
  return request(`/defi/txns/${chainId}/${address}`)
}

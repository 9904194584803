import { useEffect, useState, useContext } from 'react'
import './verification.css'
import { VerificationFailed } from './failed'
import { VerificationInput } from './input'
import { VerificationReview } from './review'
import { VerificationVerified } from './verified'
import { Kyc, KYCStatus } from 'depro.common'
import { getUserRecord } from '../../api/kyc.api'
import AppContext from '../../context/AppContext'
import { DeproHeader } from '../../components'
import { BackButton } from '../../components/Btn/BackButton'
import { useTranslation } from 'react-i18next'

export const Verification = () => {
  const { t } = useTranslation('verification')
  const [verifiedUser, setVerifiedUser] = useState({
    Name: '',
    Id: '',
  })
  const [kycStatus, setKycStatus] = useState<KYCStatus>()
  const { profile, setProfile } = useContext(AppContext)

  const fetchVerificationStatus = async () => {
    const result: Kyc = await getUserRecord()
    if (result) {
      const resultStatus = Object.values(KYCStatus).includes(
        result.Status as KYCStatus
      )
        ? (result.Status as KYCStatus)
        : undefined
      setKycStatus(resultStatus)
      if (profile) {
        setProfile({ ...profile, KYCStatus: resultStatus })
      }
      setVerifiedUser({
        Id: result.IdNumber,
        Name: `${result.LastName} ${result.FirstMiddleName}`,
      })
    }
  }

  useEffect(() => {
    fetchVerificationStatus()
  }, [])

  return (
    <div className="verification">
      <div className="app-bg-plain">
        <div className="banner app-padding">
          <div>
            <DeproHeader />
            <div className="title">{t('Verification')}</div>
          </div>
        </div>
      </div>

      {profile ? (
        <div className="verification-wrapper app-padding">
          <div className="float-back-wrapper">
            <div className="back">
              <BackButton />
            </div>
          </div>

          <div className="content">
            {(!kycStatus || kycStatus === KYCStatus.NotInit) && (
              <VerificationInput onSubmit={fetchVerificationStatus} />
            )}

            {kycStatus === KYCStatus.Fail && (
              <VerificationFailed verifyAgain={() => setKycStatus(undefined)} />
            )}

            {kycStatus === KYCStatus.Pending && (
              <VerificationReview
                Name={verifiedUser.Name}
                Id={verifiedUser.Id}
              />
            )}
            {kycStatus === KYCStatus.Success && (
              <VerificationVerified
                Name={verifiedUser.Name}
                Id={verifiedUser.Id}
              />
            )}
          </div>
        </div>
      ) : (
        <p>{t('Please login')}</p>
      )}
    </div>
  )
}

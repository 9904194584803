import { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import { Fund as F, PaymentChannel, TransactionStatus } from 'depro.common'
import { getUserLatestFunds } from '../../api/fund.api'
import { maskCondential, ToFixNum, trimAddress } from '../../common/utils'
import { Trans, useTranslation } from 'react-i18next'
import { NoResultFound } from '../../components/NoResultFound'
import { useLocation } from 'react-router-dom'

interface IDashboardFund {
  fiatRates: Record<string, number>
  tokenRates: Record<string, number>
}
export const Fund = (props: IDashboardFund) => {
  const { tokenRates, fiatRates } = props
  const location = useLocation()
  const { t } = useTranslation(['dashboard', 'common'])
  const [record, setRecord] = useState<F[]>()
  const fetchFunds = async () => {
    const res = await getUserLatestFunds(TransactionStatus.Success)
    if (res) {
      setRecord(res)
    }
  }

  useEffect(() => {
    fetchFunds()
  }, [location.search])

  return (
    <div className="fund-wrapper">
      <Table basic="very" className="depro-table">
        <Table.Header>
          <Table.Row className="depro-table-heading">
            <Table.HeaderCell>{t('Date')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Note')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Channel')}</Table.HeaderCell>
            <Table.HeaderCell>{t('CardNo')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Address')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Amount')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {record?.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan="7" className="empty-data-wrapper">
                <NoResultFound />
              </Table.Cell>
            </Table.Row>
          )}
          {record &&
            record.map((m: F) => {
              if (m.Account.startsWith('0x')) {
              }
              return (
                <Table.Row key={m.Id} verticalAlign="middle">
                  <Table.Cell>
                    {new Date(m.UpdatedAt).toLocaleString()}
                  </Table.Cell>
                  <Table.Cell>
                    <Trans>{t(m.Note)}</Trans>
                  </Table.Cell>
                  <Table.Cell>{t(m.Channel)}</Table.Cell>
                  <Table.Cell>
                    {m.Account.startsWith('0x')
                      ? '-'
                      : maskCondential(m.Account)}
                  </Table.Cell>
                  <Table.Cell>
                    {m.Account.startsWith('0x') ? trimAddress(m.Account) : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      className={`amount ${m.Note === 'SUBSCRIPTION' ? 'plus' : 'minus'
                        }`}
                    >
                      {m.Channel === PaymentChannel.Credit
                        ? ToFixNum(m.Amount, 2)
                        : ToFixNum(m.Amount)}{' '}
                      {m.Currency}
                    </div>
                    {tokenRates[m.Currency] && (
                      <small>
                        $
                        {m.Channel === PaymentChannel.Credit &&
                          ToFixNum(
                            Number(m.Amount) / fiatRates[m.Currency],
                            2
                          )}{' '}
                        {m.Channel === PaymentChannel.Crypto &&
                          ToFixNum(
                            Number(m.Amount) / tokenRates[m.Currency],
                            2
                          )}{' '}
                      </small>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </div>
  )
}

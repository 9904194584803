"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAwsService = void 0;
const AwsService_1 = require("./AwsService");
const GetAwsService = () => {
    const { S3Endpoint, S3Port, AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, S3SslEnabled, S3Region, S3Bucket, } = process.env;
    if (AWS_ACCESS_KEY_ID && AWS_SECRET_ACCESS_KEY) {
        const aws = new AwsService_1.AwsService(AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY);
        if (S3Endpoint && S3Port && S3SslEnabled && S3Bucket) {
            aws.configS3(S3Endpoint, Number(S3Port), S3SslEnabled.toLowerCase() === 'true', S3Region || '', S3Bucket);
        }
        else {
            throw new Error('Env not found for aws S3');
        }
        return aws;
    }
    else {
        throw new Error('Env not found for aws');
    }
};
exports.GetAwsService = GetAwsService;

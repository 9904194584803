import { Modal } from 'semantic-ui-react'
import { VerificationMessage } from '../VerificationMessage/VerificationMessage'

interface IRedemptionVerify {
  onClose: () => void
  onBack: () => void
}

export const RedemptionVerify = (props: IRedemptionVerify) => {
  const { onBack, onClose } = props

  return (
    <>
      <Modal.Header>
        <div className="title"></div>
        <div className="btn-modal-close" onClick={onClose} />
      </Modal.Header>
      <Modal.Content>
        <VerificationMessage onBack={onBack} />
      </Modal.Content>
    </>
  )
}

import { useTranslation } from 'react-i18next'
import './depro.lang.css'
import { GetAvailableLangauge } from '../../common/utils'

export const Lang = ({ icon }: { icon: string | null }) => {
  const { i18n } = useTranslation()
  const langs = GetAvailableLangauge()

  return (
    <div className="lang">
      <div
        className="icon"
        onClick={() => {
          const lang = i18n.language === 'en-US' ? 'zh-CN' : 'en-US'
          i18n.changeLanguage(lang)
        }}
      >
        {langs.find((f) => f.value === i18n.language)?.text || 'EN'}
      </div>
      {/* <Dropdown
        icon={icon}
        floating
        labeled={icon ? true : false}
        button
        className="icon"
        text={
          AvailableLanguages.find((f) => f.value === i18n.language)?.text ||
          'EN'
        }
        pointing="top right"
        onClick={() => {
          const lang = i18n.language === 'en-US' ? 'zh-CN' : 'en-US'
          i18n.changeLanguage(lang, () => {
            window.location.reload()
          })
        }}
      >
        <Dropdown.Menu>
          {AvailableLanguages.map((m) => (
            <Dropdown.Item
              key={m.value}
              text={m.text}
              onClick={() =>
                i18n.changeLanguage(m.value, () => {
                  window.location.reload()
                })
              }
            />
          ))}
        </Dropdown.Menu>
      </Dropdown> */}
    </div>
  )
}

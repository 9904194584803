"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLProductMarket = void 0;
class PrismaMySQLProductMarket {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLProductMarket created');
    }
    BulkUpsert(pms) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.$transaction(pms.map((m) => {
                return this.ctx.product_market.upsert({
                    where: {
                        ProductId: m.ProductId,
                    },
                    create: {
                        ProductId: m.ProductId,
                        Apy: m.Apy,
                        Supply: m.Supply,
                        SupplyUSD: m.SupplyUSD,
                        UnitPriceUSD: m.UnitPriceUSD,
                        AvailableLiquidity: m.AvailableLiquidity,
                        WeeklyApy: m.WeeklyApy,
                        MonthlyApy: m.MonthlyApy,
                        InceptionApy: m.InceptionApy,
                        GrossApr: m.GrossApr,
                        NetApy: m.NetApy,
                    },
                    update: {
                        Apy: m.Apy,
                        Supply: m.Supply,
                        SupplyUSD: m.SupplyUSD,
                        UnitPriceUSD: m.UnitPriceUSD,
                        AvailableLiquidity: m.AvailableLiquidity,
                        WeeklyApy: m.WeeklyApy,
                        MonthlyApy: m.MonthlyApy,
                        InceptionApy: m.InceptionApy,
                        GrossApr: m.GrossApr,
                        NetApy: m.NetApy,
                    },
                });
            }));
            return res.length;
        });
    }
}
exports.PrismaMySQLProductMarket = PrismaMySQLProductMarket;

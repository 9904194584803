import './bankcard.remove.css'
import { Bankcard } from 'depro.common'
import { ReactComponent as IcoRemoveCard } from '../../assets/images/remove-card.svg'
import { Button } from 'semantic-ui-react'
import { maskCondential } from '../../common/utils'
import { removeBankcard } from '../../api/account.api'

interface IRemoveBankCard {
  card: Bankcard
  onRemove: () => void
  onBack: () => void
}

export const RemoveBankCard = (props: IRemoveBankCard) => {
  const { card, onRemove, onBack } = props

  const handleConfirmRemove = async () => {
    if (card) {
      const res = await removeBankcard(card)
      if (res?.message === true) {
        // 1 cards updated
        // 2 selected card changed to undefine if same
        onRemove()
      }
    }
  }

  return (
    <div className="bankcard remove">
      <div className="main">
        <IcoRemoveCard />
        <div className="texts">
          <div className="title">Remove card</div>
          <div className="desc">Are you sure you want to remove bank card?</div>
        </div>
        <div className="infos">
          <div>Card number</div>
          <strong>{maskCondential(card.Number)}</strong>
        </div>
      </div>
      <div className="action">
        <Button className="inverted-primary" onClick={onBack}>
          {'Cancel'}
        </Button>
        <Button className="primary" onClick={handleConfirmRemove}>
          {'Confirm'}
        </Button>
      </div>
    </div>
  )
}

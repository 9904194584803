"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLTransactionApproval = void 0;
class PrismaMySQLTransactionApproval {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLTransactionApproval Created');
    }
    Add(txn) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_approval.create({
                data: {
                    ProductId: txn.ProductId,
                    ApprovalType: txn.ApprovalType,
                    ApprovalContract: txn.ApprovalContract,
                    Hash: txn.Hash,
                },
            });
            return res;
        });
    }
    UpdateHash(id, txnHash) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_approval.update({
                where: {
                    Id: id,
                },
                data: {
                    Hash: txnHash,
                },
            });
            return res;
        });
    }
    GetByProductIdAndApprovalType(productId, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.transaction_approval.findFirst({
                where: {
                    ProductId: productId,
                    ApprovalType: type,
                },
                orderBy: {
                    CreatedAt: 'desc',
                },
            });
            return res;
        });
    }
}
exports.PrismaMySQLTransactionApproval = PrismaMySQLTransactionApproval;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLProductBalance = void 0;
class PrismaMySQLProductBalance {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLProductBalance created');
    }
    Add(ProductBalance) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.product_balance.create({
                data: {
                    ProductId: ProductBalance.ProductId,
                    TokenBalance: ProductBalance.TokenBalance,
                    ATokenBalance: ProductBalance.ATokenBalance,
                    ClientSubscribed: ProductBalance.ClientSubscribed,
                    NetBalance: ProductBalance.NetBalance,
                },
            });
            return res;
        });
    }
    GetByProductId(productId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.product_balance.findFirst({
                where: { ProductId: productId },
            });
            return res;
        });
    }
    Update(ProductBalance) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.product_balance.update({
                where: { ProductId: ProductBalance.ProductId },
                data: {
                    TokenBalance: ProductBalance.TokenBalance,
                    ATokenBalance: ProductBalance.ATokenBalance,
                    ClientSubscribed: ProductBalance.ClientSubscribed,
                    NetBalance: ProductBalance.NetBalance,
                },
            });
            return res;
        });
    }
    Delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.product_balance.delete({
                where: {
                    ProductId: id,
                },
            });
            return res;
        });
    }
}
exports.PrismaMySQLProductBalance = PrismaMySQLProductBalance;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AaveStakeProduct = void 0;
const evm_1 = require("avalanche/dist/apis/evm");
const ethers_1 = require("ethers");
const aaveAbi_1 = require("../assets/aaveAbi");
const ethers_2 = require("ethers/lib/ethers");
const erc20Abi_1 = require("../assets/erc20Abi");
const assets_1 = require("../assets");
const __1 = require("..");
class AaveStakeProduct {
    constructor(product, wallet) {
        this.product = product;
        this.tokenAddress = product.TokenAddress;
        this.aTokenAdrress = product.ATokenAddress;
        this.contractAddress = product.ContractAddress;
        this.wallet = wallet;
        if (this.wallet.provider instanceof ethers_1.ethers.providers.BaseProvider) {
            this.stakeContract = new ethers_2.Contract(this.contractAddress, aaveAbi_1.aaveAbi, this.wallet.provider);
        }
        else {
            this.stakeContract = new ethers_2.Contract(this.contractAddress, aaveAbi_1.aaveAbi);
        }
    }
    GetAPY() {
        return __awaiter(this, void 0, void 0, function* () {
            // get apy for eth and polygon
            if (this.wallet.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                const liquidityRate = yield this.stakeContract.getReserveData(this.tokenAddress);
                // const RAY = 10 ** 27 // 10 to the power 27
                const SECONDS_PER_YEAR = 31536000;
                const idx = assets_1.PlatformMap[this.product.PlatformId].Version === 'V3' ? 2 : 3;
                const bn = ethers_1.BigNumber.from(liquidityRate[idx]);
                const depositAPR = Number(ethers_1.ethers.utils.formatUnits(bn, 27));
                const apy = (depositAPR / SECONDS_PER_YEAR + 1) ** SECONDS_PER_YEAR - 1;
                return apy;
            }
            // get apy for avalanche
            if (this.wallet.provider instanceof evm_1.EVMAPI) {
                const provider = this.wallet.provider;
                const iface = new ethers_1.ethers.utils.Interface(aaveAbi_1.aaveAbi);
                const calldata = iface.encodeFunctionData('getReserveData', [
                    this.tokenAddress,
                ]);
                const response = (yield provider.callMethod('eth_call', [
                    {
                        to: this.contractAddress,
                        data: calldata,
                    },
                    'latest',
                ])).data.result;
                const resList = response.substring(2).match(/.{1,64}/g);
                const liquidityRate = ethers_1.BigNumber.from('0x' + resList[3]);
                const RAY = 10 ** 27; // 10 to the power 27
                const SECONDS_PER_YEAR = 31536000;
                const depositAPR = liquidityRate / RAY;
                const apy = (depositAPR / SECONDS_PER_YEAR + 1) ** SECONDS_PER_YEAR - 1;
                return apy;
            }
            return 0;
        });
    }
    GetATokenUnderlyingBalance(wei, blockNo) {
        return __awaiter(this, void 0, void 0, function* () {
            //for product on eth or polygon
            if (this.wallet.provider instanceof ethers_1.ethers.providers.BaseProvider) {
                const aTokenContract = new ethers_1.ethers.Contract(this.aTokenAdrress, ['function balanceOf(address) view returns (uint)'], this.wallet.provider);
                const balance = yield aTokenContract.balanceOf(this.wallet.address, {
                    blockTag: blockNo,
                });
                if (wei)
                    return balance;
                //* aave product decimal should be the same between atoken & underlying token
                else
                    return ethers_1.ethers.utils.formatUnits(balance, this.product.TokenDecimal);
            }
            //for product on avalanche
            if (this.wallet.provider instanceof evm_1.EVMAPI) {
                const iface = new ethers_1.ethers.utils.Interface(erc20Abi_1.erc20Abi);
                const calldata = iface.encodeFunctionData('balanceOf', [
                    this.wallet.address,
                ]);
                const response = (yield this.wallet.provider.callMethod('eth_call', [
                    {
                        to: this.aTokenAdrress,
                        data: calldata,
                    },
                    blockNo ? `0x${blockNo.toString(16)}` : 'latest',
                ])).data.result;
                if (wei) {
                    return ethers_1.BigNumber.from(response);
                }
                else {
                    return ethers_1.ethers.utils.formatUnits(response, this.product.TokenDecimal);
                }
            }
            return ethers_1.BigNumber.from(0);
        });
    }
    GetExchangeRate() {
        return __awaiter(this, void 0, void 0, function* () {
            return 1;
        });
    }
    Deposit(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const amountWei = (0, __1.SafeParseUnits)(amount, this.product.TokenDecimal);
            const nonce = yield this.wallet.getNonce();
            const gasPrice = yield this.wallet.getGasPriceWei();
            const iface = new ethers_1.ethers.utils.Interface(aaveAbi_1.aaveAbi);
            const data = iface.encodeFunctionData('deposit', [
                this.tokenAddress,
                amountWei,
                this.wallet.address,
                '0',
            ]);
            const stakeTxn = {
                nonce: nonce,
                to: this.contractAddress,
                from: this.wallet.address,
                data: data,
                gasPrice: gasPrice,
                gasLimit: 500000,
            };
            // console.log({stakeTxn})
            const txnHash = yield this.wallet.createContractTransaction(stakeTxn);
            return txnHash;
        });
    }
    Withdraw(amount) {
        return __awaiter(this, void 0, void 0, function* () {
            const nonce = yield this.wallet.getNonce();
            //* atoken decimal = underlying decimals in AAVE
            const amountWei = (0, __1.SafeParseUnits)(amount, this.product.TokenDecimal);
            const gasPrice = yield this.wallet.getGasPriceWei();
            const iface = new ethers_1.ethers.utils.Interface(aaveAbi_1.aaveAbi);
            const data = iface.encodeFunctionData('withdraw', [
                this.tokenAddress,
                amountWei,
                this.wallet.address,
            ]);
            const stakeTxn = {
                nonce: nonce,
                to: this.contractAddress,
                from: this.wallet.address,
                data: data,
                gasPrice: gasPrice,
                gasLimit: 500000,
            };
            const txnHash = yield this.wallet.createContractTransaction(stakeTxn);
            return txnHash;
        });
    }
}
exports.AaveStakeProduct = AaveStakeProduct;

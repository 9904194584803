import './verify.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface VerifyButtonProp {
  border?: boolean
  text?: string
}
export const VerifyButton = (props: VerifyButtonProp) => {
  const { t } = useTranslation('common')
  const { border, text } = props
  const navigate = useNavigate()
  const location = useLocation()

  const finalTxt = text || t('Verification')

  return (
    <div
      className={'verification-btn' + (border ? ' border' : '')}
      onClick={() => navigate('/verification?from=' + location.pathname)}
    >
      <div className="icon"></div>
      <div className="text">{finalTxt}</div>
    </div>
  )
}

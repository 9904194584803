import { Dimmer, Loader, Segment, Icon } from 'semantic-ui-react'
import { useRef, useEffect } from 'react'
import './s3uploader.css'
import useS3Upload from '../../hooks/useS3Upload'
import { useTranslation } from 'react-i18next'

interface IIdUploaderProps {
  banner: any
  text: string
  onUpload: (url: string) => void
}

export const S3Uploader = (props: IIdUploaderProps) => {
  const { t } = useTranslation('common')
  const { banner, text, onUpload } = props
  const fileRef = useRef<HTMLInputElement>(null)
  const { isLoading, image, setImage, result } = useS3Upload()

  const uploadClicked = () => {
    fileRef?.current?.click()
  }

  useEffect(() => {
    if (result) onUpload(result)
  }, [result])

  return (
    <Segment className="uploader-segment">
      <Dimmer active={isLoading}>
        <Loader indeterminate>{t('Uploading')}...</Loader>
      </Dimmer>

      <div className="uploader-wrapper" onClick={uploadClicked}>
        <div className="image-wrapper">
          {image ? (
            <img src={URL.createObjectURL(image)} />
          ) : (
            <>
              <img src={banner} />
              <div className="text">{text}</div>
            </>
          )}
        </div>
        <div className="action">
          <div className="change">
            {image ? t('Change file') : t('Upload file')}
          </div>
        </div>
      </div>

      {fileRef?.current?.value && (
        <div
          className="clear"
          onClick={() => {
            if (fileRef.current) {
              fileRef.current.value = ''
            }
            setImage(null)
          }}
        >
          <Icon name="times circle outline" />
        </div>
      )}

      <input
        hidden
        ref={fileRef}
        type="file"
        name="myImage"
        accept="image/*"
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            setImage(event.target.files[0])
          }
        }}
      />
    </Segment>
  )
}

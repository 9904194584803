"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManagementStake = void 0;
const StakeProductFactory_1 = require("./StakeProductFactory");
const ManagementStake = (db, wallets) => {
    const stakeReserve = (productId, amount) => __awaiter(void 0, void 0, void 0, function* () {
        const checkIsReserve = yield db.Products.GetById(Number(productId));
        if (checkIsReserve && checkIsReserve.ReservedToken) {
            const platform = (0, StakeProductFactory_1.GetStakeProduct)(checkIsReserve, wallets[checkIsReserve.ChainId]);
            const stakeHash = yield platform.Deposit(amount);
            console.log({ stakeHash });
            return stakeHash;
        }
        else {
            throw Error(`Product with id ${productId} is not reserved token!`);
        }
    });
    const unstakeReserve = (productId, amount) => __awaiter(void 0, void 0, void 0, function* () {
        const checkIsReserve = yield db.Products.GetById(Number(productId));
        if (checkIsReserve && checkIsReserve.ReservedToken) {
            const platform = (0, StakeProductFactory_1.GetStakeProduct)(checkIsReserve, wallets[checkIsReserve.ChainId]);
            const stakeHash = yield platform.Withdraw(amount);
            console.log({ stakeHash });
            return stakeHash;
        }
        else {
            throw Error(`Product with id ${productId} is not reserved token!`);
        }
    });
    // stake with swap
    const stake = (productId, amount) => __awaiter(void 0, void 0, void 0, function* () {
        const checkProductSupported = yield db.Products.GetById(Number(productId));
        if (!checkProductSupported) {
            throw Error('Unsupported product id!');
        }
        const reserveTokenData = yield db.Products.GetReservedProduct(checkProductSupported.ChainId);
        if (!reserveTokenData) {
            throw Error(`Reserve token for chain ${checkProductSupported.ChainId} data not found on chain ${checkProductSupported.Id}`);
        }
        const platform = (0, StakeProductFactory_1.GetStakeProduct)(checkProductSupported, wallets[checkProductSupported.ChainId]);
        const stakeHash = yield platform.Deposit(amount);
        console.log({ stakeHash });
        return stakeHash;
    });
    // const checkApproveAmount = async (product: Product) => {
    //   const provider = wallets[product.ChainId].provider
    //   const walletAddress = wallets[product.ChainId].address
    //   if (provider instanceof EVMAPI) {
    //     const iface = new ethers.utils.Interface(erc20Abi)
    //     const data = iface.encodeFunctionData('allowance', [
    //       walletAddress,
    //       product.ContractAddress,
    //     ])
    //     const approvedAmount = await provider.callMethod('eth_call', [
    //       { to: product.TokenAddress, data: data },
    //       'latest',
    //     ])
    //     return approvedAmount
    //   }
    //   if (provider instanceof ethers.providers.BaseProvider) {
    //     const tokenContract = new ethers.Contract(
    //       product.TokenAddress,
    //       erc20Abi,
    //       provider
    //     )
    //     const approvedAmount = await tokenContract.allowance(
    //       walletAddress,
    //       product.ContractAddress
    //     )
    //     return approvedAmount
    //   }
    // }
    return {
        stake,
        stakeReserve,
        unstakeReserve,
    };
};
exports.ManagementStake = ManagementStake;

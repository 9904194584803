import { ProductStatus } from 'depro.common'
import { request } from './request.api'


export const getProductById = async (id: number | string) => {
  const res = await request(`/product/${id}`)
  return res
}


export const getHotProducts = async () => {
  const res = await request(`/product/data?status=${ProductStatus.Hot}`)
  return res
}

export const getAllProducts = async () => {
  const statusQuery = [ProductStatus.Active, ProductStatus.Hot].join(",")
  const res = await request(`/product/data?status=${statusQuery}`)
  return res
}

export const getPagedProductInfo = async (
  offset: number,
  size = 10,
  sortByPrice?: string,
  sortByApy?: string,
  sortBySupply?: string,
  chainId?: number,
  platform?: string,
  version?: string,
  earnGroup?: string
) => {
  const statusQuery = [ProductStatus.Active, ProductStatus.Hot].join(",")
  const queries: string[] = []
  queries.push(`status=${statusQuery}`)
  queries.push(`offset=${offset}`)
  queries.push(`size=${size}`)

  if (sortByPrice) queries.push('sortByPrice=' + sortByPrice)
  if (sortByApy) queries.push('sortByApy=' + sortByApy)
  if (sortBySupply) queries.push('sortBySupply=' + sortBySupply)
  if (chainId) queries.push('chainId=' + chainId)
  if (platform) queries.push('platform=' + platform)
  if (version) queries.push('version=' + version)
  if (earnGroup) queries.push('earnGroup=' + earnGroup)

  const res = await request('/product/data' + '?' + queries.join('&'))
  return res
}

export const getAllProductCount = async (
  chainId?: number,
  platform?: string,
  version?: string,
  earnGroup?: string
) => {
  const queries: string[] = []
  if (chainId) queries.push('chainId=' + chainId)
  if (platform) queries.push('platform=' + platform)
  if (version) queries.push('version=' + version)
  if (earnGroup) queries.push('earnGroup=' + earnGroup)

  const finalQuery = queries.length === 0 ? '' : '?' + queries.join('&')
  const res = await request(`/product/count/${finalQuery}`)
  return res
}

export const getChainData = async () => {
  const res = await request(`/product/chain-data`)
  return res
}

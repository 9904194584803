import './main.css'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'semantic-ui-react'
import {
  RedemptionCodeVerification,
  RedemptionConfirm,
  RedemptionData,
  RedemptionPending,
  RedemptionVerify,
} from '.'
import { redeemByCrypto, redeemByFiat } from '../../api/earn.api'
import { Earning, PaymentChannel, Platform } from 'depro.common'
import { RedemptionInputs } from './inputs'

type DialogView = 'INPUTS' | 'PENDING' | 'CONFIRM' | 'VERIFY' | 'CODE'

interface IRedemptionMain {
  earning?: Earning
  apy?: number
  // platform?: Platform
  reload: () => void
}

export const RedemptionMain = (props: IRedemptionMain) => {
  const { earning, apy, reload } = props
  const [open, setOpen] = useState(false)
  const [view, setView] = useState<DialogView>('INPUTS')
  const [data, setData] = useState<RedemptionData>()
  const { t } = useTranslation(['redemption', 'common'])

  const product = earning?.products
  const redeem = async (code: string) => {
    if (data) {
      const result =
        data.channel === PaymentChannel.Bank
          ? await redeemByFiat({ ...data, verificationCode: code })
          : await redeemByCrypto({ ...data, verificationCode: code })

      if (result && !result.error) {
        setView('PENDING')
      } else {
        console.error(result)
      }
    } else {
      console.warn('No redemption data')
    }
  }
  const handleClose = () => {
    setView('INPUTS')
    setOpen(false)
    reload()
  }

  if (!earning || !product) return <>Page not found...</>

  return (
    <Modal
      className="redemption-modal"
      onClose={handleClose}
      onOpen={() => setOpen(true)}
      open={open}
      size="tiny"
      trigger={
        <Button className="primary" fluid>
          {t('Redeem')}
        </Button>
      }
      closeIcon
    >
      {view === 'INPUTS' && (
        <RedemptionInputs
          product={product}
          balance={earning.ATokenBalance}
          onClose={handleClose}
          onRedeem={(rdmData: RedemptionData) => {
            setData(rdmData)
            console.log(rdmData)
            console.log(apy)
            setView('CONFIRM')
          }}
          onVerify={() => {
            setView('VERIFY')
          }}
        />
      )}

      {view === 'CONFIRM' && data && apy !== undefined && (
        <RedemptionConfirm
          product={product}
          inputData={data}
          apy={apy}
          onClose={handleClose}
          onConfirm={() => {
            setView('CODE')
          }}
          onBack={() => {
            setView('INPUTS')
          }}
        />
      )}

      {view === 'PENDING' && data && (
        <RedemptionPending inputData={data} onClose={handleClose} />
      )}

      {view === 'VERIFY' && (
        <RedemptionVerify
          onClose={handleClose}
          onBack={() => setView('INPUTS')}
        />
      )}

      {view === 'CODE' && (
        <RedemptionCodeVerification
          onVerifySuccess={redeem}
          onClose={handleClose}
          onBack={() => setView('CONFIRM')}
        />
      )}
    </Modal>
  )
}

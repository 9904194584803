import "./details.apy.css"
import { useState, useEffect } from 'react'
import { Product } from 'depro.common'
import { ApyChart, ApyDataPoint } from '../../components/ApyChart'
import { ToFixPercent } from '../../common/utils'

export const ProductDetailsApy = ({ product }: { product: Product }) => {
  const [apyDp, setApyDp] = useState<ApyDataPoint[]>([])

  useEffect(() => {
    if (product && product.apy_log) {
      setApyDp(
        product.apy_log.map((m) => {
          return { x: m.Timestamp, y: m.Apy * 100 }
        })
      )
    }
  }, [product])
  return (
    <div className='details-apy'>
      <div className='heading'>APY</div>
      <div className='value'>{ToFixPercent(product.product_market?.Apy)}</div>
      <ApyChart data={apyDp} width="100%" color="#66CCCC" legend />
    </div>
  )
}

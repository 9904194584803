import './confirm.css'
import { Button, Modal } from 'semantic-ui-react'
import {
  Platform,
  Product,
  PaymentChannel,
  IToken,
  PlatformMap,
} from 'depro.common'
import { maskCondential, ToFixNum } from '../../common/utils'
import { RedemptionData } from '.'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FiatTransfer } from '../../assets/images/fiat-transfer.svg'
import { ReactComponent as IcoBack } from '../../assets/images/back.svg'
import { useEffect } from 'react'

interface IRedemptionConfirm {
  product: Product
  inputData: RedemptionData
  apy: number
  onClose: () => void
  onConfirm: () => void
  onBack: () => void
}

export const RedemptionConfirm = (props: IRedemptionConfirm) => {
  const { product, inputData, apy, onClose, onConfirm, onBack } = props
  const { t } = useTranslation(['redemption', 'common'])

  useEffect(() => {
    console.log('ALHSJADHJSKAJK')
  }, [product])
  // fetch rates here?
  return (
    <>
      <Modal.Header>
        <div className="title">
          <a href="#" onClick={onBack}>
            <IcoBack />
          </a>
          <div>Confirm redemption</div>
        </div>
        <div className="btn-modal-close" onClick={onClose} />
      </Modal.Header>

      <Modal.Content>
        <div className="confirm">
          <div className="item">
            <div className="redeem-token">
              <img className="ico" src={product.IconURL} />
              <div className="name">
                {PlatformMap[product.PlatformId!].ATokenPrefix}
                {product.TokenName}
              </div>
            </div>
            <FiatTransfer />
            <div className="fiat-currency">
              <img src={inputData.currencyIconUrl} className="icon" />
              <div className="name">
                {inputData.channel === PaymentChannel.Bank
                  ? inputData.currency.toString()
                  : (inputData.currency as IToken).name}
              </div>
            </div>
          </div>
          <div className="item">
            <div className="label">{t('Platform')}</div>
            <div className="value">{product.PlatformId}</div>
          </div>
          <div className="item">
            <div className="label">{t('APY')}</div>
            <div className="value">{ToFixNum(apy * 100, 2)}%</div>
          </div>
          <div className="item">
            <div className="label">{t('Redemption Amount')}</div>
            <div className="value">
              {ToFixNum(inputData.amount)}{' '}
              {PlatformMap[product.PlatformId!].ATokenPrefix}
              {product.TokenName}
            </div>
          </div>
          <div className="item">
            <div className="label">{t('common:Rate')}</div>
            <div className="value">
              1 {PlatformMap[product.PlatformId!].ATokenPrefix}
              {product.TokenName} &asymp;{' '}
              {inputData.channel === PaymentChannel.Bank
                ? `${ToFixNum(Number(inputData.rateProductCurrency), 2)} ${inputData.currency
                }`
                : `${ToFixNum(inputData.rateProductCurrency)} ${(inputData.currency as IToken).name
                }`}
            </div>
          </div>
          <div className="item">
            <div className="label">{t('Fee')}</div>
            <div className="value">${product.RedemptionCharge}</div>
          </div>
          <div className="item">
            <div className="label">{t('Redeem to')}</div>
            <div className="value">
              {inputData.channel === PaymentChannel.Bank
                ? t('Bank card')
                : t('Crypto wallet')}{' '}
              | <strong>{maskCondential(inputData.account)}</strong>
            </div>
          </div>
          <div className="item">
            <div className="label">{t('Funds returned time')}</div>
            <div className="value">
              {inputData.channel === PaymentChannel.Bank
                ? t('T+1')
                : t('In 2 hours')}
            </div>
          </div>
          <div className="item total">
            <div className="label">{t('You will receive (estimate)')}</div>
            <div className="value">
              {inputData.channel === PaymentChannel.Bank
                ? `${ToFixNum(inputData.receiveAmount, 2)} ${inputData.currency
                }`
                : `${ToFixNum(inputData.receiveAmount)} ${(inputData.currency as IToken).name
                }`}
            </div>
          </div>
        </div>
        <div className="confirm-action">
          <Button fluid className="inverted-primary" onClick={onClose}>
            Cancel
          </Button>
          <Button fluid className="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}

import './home.css'
import { HomeHeader } from './home.header'
import { DeproHeader } from '../../components'
import { AllProducts, HotProduct } from '../../components/Products'
import { useState } from 'react'
import { EarnGroup } from 'depro.common'

const Home = () => {
  const [group, setGroup] = useState<EarnGroup>(EarnGroup.Stake)

  return (
    <div className="home app-bg">
      <div className="banner app-padding">
        <DeproHeader />
        <HomeHeader />
      </div>
      <div className="content app-padding">
        <HotProduct />
        <div className='group-selection'>
          <div className='controls'>
            <div role="button" className={group === EarnGroup.Stake ? `selected stake` : `stake`}
              onClick={() => setGroup(EarnGroup.Stake)}>
              Stake
            </div>
            <div role="button" className={group === EarnGroup.Vault ? `selected vault` : `vault`}
              onClick={() => setGroup(EarnGroup.Vault)}>
              Vaults
            </div>
          </div>
          <div className='desc'>
            {group === EarnGroup.Stake && `Deposit in fiat or crypto currency and get returns by providing liquidity.`}
            {group === EarnGroup.Vault && `Route your deposit through strategies which seek out the highest yield.`}
          </div>
        </div>
        <AllProducts earnGroup={group} />
      </div>
    </div>
  )
}

export default Home

import './code.css'
import { useRef, useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { ReactComponent as IcoBack } from '../../assets/images/back.svg'
import { ReactComponent as IcoRedeemVerificationCode } from '../../assets/images/verification-redeem.svg'
import { ReactComponent as IconAlert } from '../../assets/images/alert.svg'
import { ReactComponent as IconVerified } from '../../assets/images/check-blue.svg'
import AppContext from '../../context/AppContext'
import { maskCondential } from '../../common/utils'
import { getVerificationCodeByEmail, verifyEmailCode } from '../../api/kyc.api'
import { EmailCodeType } from 'depro.common'
import useCountDown from '../../hooks/useCountDown'

interface ICodeVerification {
  onVerifySuccess: (code: string) => void
  onClose: () => void
  onBack: () => void
}
export const RedemptionCodeVerification = (props: ICodeVerification) => {
  const { onVerifySuccess, onBack, onClose } = props

  const { REACT_APP_EMAIL_TIME } = process.env
  const { profile } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState<
    'SUCCESS' | 'FAIL'
  >()

  const { timeLeft, start } = useCountDown(Number(REACT_APP_EMAIL_TIME) | 60)

  const digit1 = useRef<HTMLInputElement>(null)
  const digit2 = useRef<HTMLInputElement>(null)
  const digit3 = useRef<HTMLInputElement>(null)
  const digit4 = useRef<HTMLInputElement>(null)
  const digit5 = useRef<HTMLInputElement>(null)
  const digit6 = useRef<HTMLInputElement>(null)

  const digits = [digit1, digit2, digit3, digit4, digit5, digit6]

  const fetchCode = async () => {
    if (profile?.Email) {
      getVerificationCodeByEmail(EmailCodeType.Redemption)
    }
  }

  const verifyCode = async (code: string) => {
    try {
      setLoading(true)
      const result = await verifyEmailCode(EmailCodeType.Redemption, code)
      if (result && result.success) {
        setVerificationStatus('SUCCESS')
      } else {
        setVerificationStatus('FAIL')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  const setDigit = (val: string, idx: number) => {
    const next = (val === '') ? idx - 1 : idx + 1

    if (digits[next]) {
      digits[next].current?.focus()
      digits[next].current?.select()
    }

    const code = digits.map((d) => d.current?.value).join('')
    if (code?.length === 6) {
      verifyCode(code)
    }
  }

  const onPaste = (val: string) => {
    if (val) {
      for (let i = 0; i < digits.length; i++) {
        digits[i].current!.value = val[i] || ''
      }
    }

    const code = digits.map((d) => d.current?.value).join('')
    if (code?.length === 6) {
      verifyCode(code)
    }
  }

  const handleContinue = async () => {
    const code = digits.map((d) => d.current?.value).join('')
    onVerifySuccess(code)
  }

  useEffect(() => {
    fetchCode()
  }, [])

  return (
    <>
      <Modal.Header>
        <div className="title">
          <a href="#" onClick={onBack}>
            <IcoBack />
          </a>
          <div>Verification Code</div>
        </div>
        <div className="btn-modal-close" onClick={onClose} />
      </Modal.Header>

      <Modal.Content>
        <div className="code-wrapper">
          <div className="texts">
            <div className="icon">
              <IcoRedeemVerificationCode />
            </div>
            <div className="text-wrapper">
              <div className="title">Verification code</div>
              <div className="desc">
                Please enter the verification code we sent to your email address{' '}
                <strong>{maskCondential(profile?.Email || '', 3, 10)}</strong>
              </div>
            </div>
          </div>
          <div className="inputs-wrapper">
            <div className="inputs">
              <div className="code-group">
                {digits.map((digit, idx: number) => {
                  return (
                    <input
                      key={idx}
                      className="code-digit"
                      maxLength={1}
                      ref={digit}
                      onChange={(e) =>
                        setDigit(
                          e.target.value,
                          idx
                        )
                      }
                      onPaste={(e) => {
                        if (e.clipboardData) {
                          const pastedText = e.clipboardData.getData('text')
                          onPaste(pastedText)
                        }
                      }}
                    />
                  )
                })}
              </div>
              <div className="resend">
                {!timeLeft || timeLeft === 0 ? (
                  <a
                    href="#"
                    onClick={() => {
                      start()
                      fetchCode()
                    }}
                  >
                    Not received? Resend
                  </a>
                ) : (
                  <div className="timeLeft">{timeLeft}s</div>
                )}
              </div>
            </div>
          </div>
          {verificationStatus === 'SUCCESS' && (
            <div className="alert-message success">
              <IconVerified />
              <div className="text">{'Verified.'}</div>
            </div>
          )}
          {verificationStatus === 'FAIL' && (
            <div className="alert-message fail">
              <IconAlert />
              <div className="text">
                {'Sorry, the verification code is invalid, please reenter.'}
              </div>
            </div>
          )}
        </div>
        <div className="confirm-action">
          <Button fluid className="inverted-primary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            fluid
            className="primary"
            onClick={handleContinue}
            disabled={loading || verificationStatus !== 'SUCCESS'}
            loading={loading}
          >
            Continue
          </Button>
        </div>
      </Modal.Content>
    </>
  )
}

import { useTranslation } from 'react-i18next'
import NoResult from '../../assets/images/no-result-found.svg'

export const NoResultFound = () => {
  const { t } = useTranslation('common')
  return (
    <div>
      <img src={NoResult} />
      <div style={{ fontSize: '.875rem', color: '#999999' }}>
        {t('No result found')}
      </div>
    </div>
  )
}

import { useState, useEffect } from 'react'
import { uploadImage } from '../api/kyc.api'

const useS3Upload = () => {
  const [isLoading, setLoading] = useState(false)
  const [image, setImage] = useState<File | null>(null)
  const [result, setResult] = useState<any>()

  const uploadFile = async () => {
    if (image) {
      setLoading(true)
      const res = await uploadImage(image)
      // filename: '....png'
      // storagePath: '2ff****378.png'
      if (res && res.filename && res.storagePath) {
        setResult(res.storagePath)
      }
      setLoading(false)
    }
  }

  const reset = () => {
    setLoading(false)
    setImage(null)
    setResult(undefined)
  }

  useEffect(() => {
    uploadFile()
  }, [image])

  return {
    isLoading,
    result,
    image,
    setImage,
    reset,
  }
}

export default useS3Upload

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLUser = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLUser {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.info('PrismaMySQLUser Created');
    }
    Add(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.user.create({
                data: {
                    Name: user.Name,
                    Email: user.Email,
                    TelegramId: user.TelegramId,
                    AccountType: user.AccountType,
                    Password: user.Password,
                    ParentId: user.ParentId,
                    PubKey: user.PubKey,
                    ClientId: user.ClientId,
                },
            });
            return res;
        });
    }
    Delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ctx.user.delete({
                    where: {
                        Id: Number(id),
                    },
                });
                return true;
            }
            catch (e) {
                return false;
            }
        });
    }
    Update(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.user.update({
                where: { Id: user.Id },
                data: {
                    Name: user.Name,
                    Email: user.Email,
                    PubKey: user.PubKey,
                    Password: user.Password,
                },
            });
            return res;
        });
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.ctx.user.findUnique({
                where: { Id: Number(id) },
            });
            if (user) {
                return user;
            }
            return null;
        });
    }
    GetByEmail(email) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.ctx.user.findFirst({
                where: { Email: email },
            });
            if (user) {
                return user;
            }
            return null;
        });
    }
    GetByTelegramId(tgId) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.ctx.user.findFirst({
                where: { TelegramId: tgId.toString() },
            });
            if (user) {
                return user;
            }
            return null;
        });
    }
    GetTotalUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const userCount = yield this.ctx.user.count();
            return userCount;
        });
    }
    ListUser(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.ctx.user.findMany({
                where: { AccountType: options.accountType, Id: options.userId },
                include: {
                    earnings: options.includeEarning
                        ? { include: { products: true } }
                        : false,
                },
                skip: options.offset,
                take: options.size,
            });
        });
    }
    GetByBizIdAndClientId(bizId, clientId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.ctx.user.findFirst({
                where: {
                    AccountType: types_1.UserAccountType.Client,
                    ParentId: bizId,
                    ClientId: clientId,
                },
            });
        });
    }
}
exports.PrismaMySQLUser = PrismaMySQLUser;

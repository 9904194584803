import { request } from './request.api'
import { Quote } from '../common/types'
import { RedemptionData } from '../components/RedeemDialog'
import { EarnGroup } from 'depro.common'

export const getUserEarnings = async (earnGroup?: EarnGroup) => {
  const query = earnGroup ? `?earnGroup=${earnGroup}` : ''
  const res = await request(`/earn/userEarnings` + query)
  return res
}

export const getSubscriptionHistory = async () => {
  const res = await request(`/earn/subscriptions`)
  return res
}

export const getRedemptionHistory = async () => {
  const res = await request(`/earn/redemptions`)
  return res
}

export const redeemByFiat = async (data: RedemptionData) => {
  const res = await request(`/earn/unstake/fiat`, {
    method: 'POST',
    body: JSON.stringify({
      bankcardId: data.account,
      amount: data.amount,
      currency: data.currency,
      productId: data.productId,
      verificationCode: data.verificationCode,
    }),
  })
  return res
}

export const redeemByCrypto = async (data: RedemptionData) => {
  const res = await request(`/earn/unstake/crypto`, {
    method: 'POST',
    body: JSON.stringify({
      redeemAddress: data.account,
      amount: data.amount,
      currency: data.currency,
      productId: data.productId,
      verificationCode: data.verificationCode,
    }),
  })
  return res
}

export const redeemQuote = async (data: Quote) => {
  const res = await request(`/earn/unstake-quote`, {
    method: 'POST',
    body: JSON.stringify({
      amount: data.amount,
      channel: data.channel,
      currency: data.currency,
      productId: data.productId,
      fetchId: data.fetchId,
    }),
  })
  return res
}

export const subscribeQuote = async (data: Quote) => {
  const res = await request(`/earn/stake-quote`, {
    method: 'POST',
    body: JSON.stringify({
      amount: data.amount,
      channel: data.channel,
      currency: data.currency,
      productId: data.productId,
      fetchId: data.fetchId,
    }),
  })
  return res
}

export const getUserRedeeming = async () => {
  const res = await request(`/earn/redeeming`)
  return res
}

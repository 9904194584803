import { useTranslation } from 'react-i18next'
import { IVerificationDetails } from '.'
import { maskCondential } from '../../common/utils'
import './review.verified.css'

export const VerificationVerified = (props: IVerificationDetails) => {
  const { Name, Id } = props
  const { t } = useTranslation('verification')

  return (
    <div className="verification verified">
      <div className="heading">{t('Verified identity')}</div>
      <div className="body">
        <div className="img-container">
          <div className="img"></div>
          <div className="img-desc">{t('Verified identity')}</div>
        </div>

        <div className="details">
          <div className="name">
            <div className="label">{t('Full name')}</div>
            <div className="value">{maskCondential(Name)}</div>
          </div>
          <div className="id">
            <div className="label">{t('ID number')}</div>
            <div className="value">{maskCondential(Id)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLBankcards = void 0;
class PrismaMySQLBankcards {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLBankcards created');
    }
    Add(card) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.ctx.bankcards.create({
                    data: {
                        Holder: card.Holder,
                        CountryRegion: card.CountryRegion,
                        Number: card.Number,
                        Bank: card.Bank,
                        Address: card.Address,
                        UserId: card.UserId,
                        CardPhoto: card.CardPhoto,
                        Currency: card.Currency,
                        BranchName: card.BranchName,
                        SwiftCode: card.SwiftCode,
                        LastFundTime: card.LastFundTime,
                    },
                });
                return res;
            }
            catch (e) {
                console.error('Error adding bank card', e);
                return null;
            }
        });
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const card = yield this.ctx.bankcards.findUnique({
                where: { Id: Number(id) },
                include: {
                    user: true,
                },
            });
            if (card) {
                return card;
            }
            return null;
        });
    }
    GetByUserId(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const cards = yield this.ctx.bankcards.findMany({
                where: { UserId: Number(uid) },
                orderBy: [{ LastFundTime: 'desc' }, { CreatedAt: 'desc' }],
            });
            return cards;
        });
    }
    Delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ctx.bankcards.delete({
                    where: {
                        Id: Number(id),
                    },
                });
                return true;
            }
            catch (e) {
                return false;
            }
        });
    }
    GetByCardNumber(num) {
        return __awaiter(this, void 0, void 0, function* () {
            const cards = yield this.ctx.bankcards.findFirst({
                where: { Number: num },
            });
            return cards;
        });
    }
    //TODO test
    UpdateFundTime(id, fundTime) {
        return __awaiter(this, void 0, void 0, function* () {
            const card = yield this.ctx.bankcards.update({
                where: { Id: id },
                data: {
                    LastFundTime: fundTime,
                },
            });
            return card;
        });
    }
}
exports.PrismaMySQLBankcards = PrismaMySQLBankcards;

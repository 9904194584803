import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import './dashboard.header.css'
import { useTranslation } from 'react-i18next'
import { ToFixNum } from '../../common/utils'

interface ITotalEarning {
  balance?: number
  earning?: number
}

export const DashboardHeader = (props: ITotalEarning) => {
  const { balance, earning } = props
  const { t } = useTranslation('dashboard')
  const { profile } = useContext(AppContext)

  return (
    <div className="header">
      <h1>{t('Dashboard')}</h1>
      <ul>
        <li>
          <label>{t('Balance')}</label>
          <p>
            {!profile && '***'}
            {profile && (balance ? '$' + ToFixNum(balance, 2) : '-')}
          </p>
        </li>
        <li>
          <label>{t('Total Earnings')}</label>
          <p>
            {!profile && '***'}
            {profile && (earning ? '$' + ToFixNum(earning, 2) : '-')}
          </p>
        </li>
      </ul>
    </div>
  )
}

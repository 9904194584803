import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Segment,
  Icon,
} from 'semantic-ui-react'
import countriesJSON from '../../assets/country.json'
import { useState, useMemo } from 'react'
import { kycFormSend } from '../../api'
import { Kyc } from 'depro.common'
import './input.css'
import IDFront from '../../assets/images/idcard_front.svg'
import IDBack from '../../assets/images/idcard_back.svg'
import { S3Uploader } from '../../components/S3Uploader'
import { useTranslation } from 'react-i18next'

export const VerificationInput = ({ onSubmit }: { onSubmit: () => void }) => {
  const { t, i18n } = useTranslation(['verification', 'country'])
  const [isLoading, setLoading] = useState(false)
  const [nationality, setNationality] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [idType, setIdType] = useState<string>('')
  const [idNumber, setIdNumber] = useState<string>('')
  const [idImgFront, setIdImgFront] = useState<string>('')
  const [idImgBack, setIdImgBack] = useState<string>('')
  const [errInput, setErrInput] = useState<Record<
    string,
    string | null
  > | null>(null)

  const countries = useMemo(
    () =>
      countriesJSON.map((m) => {
        return {
          key: m.key,
          flag: m.flag,
          value: m.value,
          text: t(`country:${m.text}`),
        }
      }),
    [i18n.language]
  )

  const validateForm = (r: Kyc) => {
    const err = {} as Record<string, string>

    if (!r.Nationality || r.Nationality.length === 0) {
      err.Nationality = t('Nationality is blank')
    }
    if (!r.FirstMiddleName || r.FirstMiddleName.length === 0) {
      err.FirstMiddleName = t('First and middle names are blank')
    }
    if (!r.LastName || r.LastName.length === 0) {
      err.LastName = t('Last name is blank')
    }
    if (!r.IdType || r.IdType.length === 0) {
      err.IdType = t('ID type is blank')
    }
    if (!r.IdNumber || r.IdNumber.length === 0) {
      err.IdNumber = t('ID number is blank')
    }
    if (!r.IdProofFront || r.IdProofFront.length === 0) {
      err.IdProofFront = t('No upload file')
    }
    if (!r.IdProofBack || r.IdProofBack.length === 0) {
      err.IdProofBack = t('No upload file')
    }

    if (Object.keys(err).length > 0) {
      setErrInput(err)
      return false
    }
    return true
  }

  const verify = async () => {
    const form = {
      Nationality: nationality,
      FirstMiddleName: firstName,
      LastName: lastName,
      IdType: idType,
      IdNumber: idNumber,
      IdProofFront: idImgFront,
      IdProofBack: idImgBack,
      Status: 'PENDING', // this will be overwitten in backend
      CreatedAt: new Date(),
      UpdatedAt: new Date(),
    } as Kyc
    const isPass = validateForm(form)
    if (isPass) {
      setLoading(true)
      const result: Kyc = await kycFormSend(form)
      if (result && result.Id) {
        onSubmit()
      }
      setLoading(false)
    }
  }

  return (
    <Segment className="verification input">
      <Dimmer active={isLoading}>
        <Loader indeterminate>{t('Submitting form')}...</Loader>
      </Dimmer>
      <div className="heading">{t('Please verify your identity')}</div>
      <div className="form-row">
        <div className="input-item">
          <div className="label">{t('Nationality')}</div>
          <div className="control">
            <Dropdown
              fluid
              selection
              search
              options={countries}
              onChange={(e, data: any) => setNationality(data.value)}
            />
            {errInput && errInput.Nationality && (
              <small>{errInput.Nationality}</small>
            )}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="input-item">
          <div className="label">{t('First and middle names')}</div>
          <div className="control">
            <Input
              fluid
              value={firstName}
              onChange={(_, data: any) => {
                setFirstName(data.value)
                if (data.value) {
                  setErrInput({ ...errInput, FirstMiddleName: null })
                }
              }}
              icon={
                firstName ? (
                  <Icon
                    name="times circle"
                    link
                    onClick={() => setFirstName('')}
                  />
                ) : null
              }
            />
            {errInput && errInput.FirstMiddleName && (
              <small>{errInput.FirstMiddleName}</small>
            )}
          </div>
        </div>
        <div className="input-item">
          <div className="label">{t('Last name')}</div>
          <div className="control">
            <Input
              fluid
              value={lastName}
              onChange={(_, data: any) => {
                setLastName(data.value)
                if (data.value) {
                  setErrInput({ ...errInput, LastName: null })
                }
              }}
              icon={
                lastName ? (
                  <Icon
                    name="times circle"
                    link
                    onClick={() => setLastName('')}
                  />
                ) : null
              }
            />
            {errInput && errInput.LastName && (
              <small>{errInput.LastName}</small>
            )}
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="input-item">
          <div className="label">{t('ID Type')}</div>
          <div className="control">
            <Dropdown
              fluid
              selection
              placeholder={t('Select ID type')}
              options={[
                { key: 'IDCARD', value: 'IDCARD', text: t('ID Card') },
                { key: 'PASSPORT', value: 'PASSPORT', text: t('Passport') },
              ]}
              onChange={(e, data: any) => setIdType(data.value)}
            ></Dropdown>
            {errInput && errInput.IdType && <small>{errInput.IdType}</small>}
          </div>
        </div>
        <div className="input-item">
          <div className="label">{t('ID number')}</div>
          <div className="control">
            <Input
              fluid
              value={idNumber}
              onChange={(_, data: any) => {
                setIdNumber(data.value)
                if (data.value) {
                  setErrInput({ ...errInput, IdNumber: null })
                }
              }}
              icon={
                idNumber ? (
                  <Icon
                    name="times circle"
                    link
                    onClick={() => setIdNumber('')}
                  />
                ) : null
              }
            />
            {errInput && errInput.IdNumber && (
              <small>{errInput.IdNumber}</small>
            )}
          </div>
        </div>
      </div>

      <div className="form-row">
        <div className="input-item">
          <div className="label">{t('Upload your ID photo')}</div>
          <div className="control">
            <div className="upload">
              <S3Uploader
                banner={IDFront}
                text={t('Front of ID card')}
                onUpload={(url: string) => {
                  if (url) {
                    setIdImgFront(url)
                    setErrInput({ ...errInput, IdProofFront: null })
                  }
                }}
              />
            </div>
            {errInput && errInput.IdProofFront && (
              <small>{errInput.IdProofFront}</small>
            )}
          </div>
        </div>
        <div className="input-item">
          <div className="label">&nbsp;</div>
          <div className="control">
            <div className="upload">
              <S3Uploader
                banner={IDBack}
                text={t('Back of ID card')}
                onUpload={(url: string) => {
                  if (url) {
                    setIdImgBack(url)
                    setErrInput({ ...errInput, IdProofBack: null })
                  }
                }}
              />
            </div>
            {errInput && errInput.IdProofBack && (
              <small>{errInput.IdProofBack}</small>
            )}
          </div>
        </div>
      </div>

      <div className="action">
        <Button className="submit" onClick={verify}>
          {t('Submit')}
        </Button>
      </div>
    </Segment>
  )
}

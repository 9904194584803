"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLApyLogs = void 0;
class PrismaMySQLApyLogs {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLApyLogs created');
    }
    Add(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.apy_log.create({
                data: {
                    ProductId: record.ProductId,
                    Apy: Number(record.Apy.toFixed(10)),
                },
            });
            return res;
        });
    }
    BulkAdd(logs) {
        return __awaiter(this, void 0, void 0, function* () {
            const batch = logs.map((m) => {
                return {
                    ProductId: m.ProductId,
                    Apy: Number(m.Apy.toFixed(10)),
                };
            });
            const res = yield this.ctx.apy_log.createMany({
                data: batch,
            });
            return res.count;
        });
    }
    GetProductsLatestApy(productIds) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.apy_log.findMany({
                where: {
                    ProductId: {
                        in: productIds,
                    },
                },
                distinct: ['ProductId'],
                orderBy: {
                    Timestamp: 'desc',
                },
            });
            return res;
        });
    }
}
exports.PrismaMySQLApyLogs = PrismaMySQLApyLogs;

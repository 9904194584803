import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import './failed.css'

export const VerificationFailed = ({
  verifyAgain,
}: {
  verifyAgain: () => void
}) => {
  const { t } = useTranslation('verification')
  return (
    <div className="verification failed">
      <div className="heading">
        <div className="text">{t('Failed')}</div>
        <div className="desc">{t('Invalid personal info')}</div>
      </div>
      <div className="body">
        <div className="img-container">
          <div className="img"></div>
          <div className="img-desc">{t('Failed')}</div>
        </div>
        <div className="details">
          <Button className="action" onClick={() => verifyAgain()}>
            {t('Verify again')}
          </Button>
        </div>
      </div>
    </div>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLVerificationCode = void 0;
const utils_1 = require("../../common/utils");
class PrismaMySQLVerificationCode {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLVerificationCode Created');
    }
    Upsert(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.verification_code.upsert({
                where: {
                    UserId: Number(userId),
                },
                create: {
                    Code: (0, utils_1.Makeid)(6, true),
                    UserId: Number(userId),
                },
                update: {
                    Code: (0, utils_1.Makeid)(6, true),
                },
            });
            return res;
        });
    }
    DeleteByUserId(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.ctx.verification_code.delete({
                    where: {
                        UserId: Number(uid),
                    },
                });
                return true;
            }
            catch (e) {
                return false;
            }
        });
    }
    GetByUserId(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.verification_code.findUnique({
                where: {
                    UserId: Number(uid),
                },
            });
            return res;
        });
    }
}
exports.PrismaMySQLVerificationCode = PrismaMySQLVerificationCode;

import { useState, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Table } from 'semantic-ui-react'
import { ToFixNum, trimAddress } from '../../common/utils'
import Copy from '../../assets/images/copy.svg'
import { getRedemptionHistory } from '../../api/earn.api'
import { Fund, PlatformMap, PaymentChannel, Redemption } from 'depro.common'
import { NoResultFound } from '../../components/NoResultFound'
import { useLocation } from 'react-router-dom'

interface IRedemptionHistory {
  fiatRates: Record<string, number>
  tokenRates: Record<string, number>
}
export const RedemptionHistory = (props: IRedemptionHistory) => {
  const { tokenRates, fiatRates } = props
  const { t } = useTranslation('dashboard')
  const location = useLocation()
  const [records, setRecords] = useState<Redemption[]>([])
  const [fundMap, setFundMap] = useState<Record<number, Fund>>() //*key is order ID

  const fetchRedemptions = async () => {
    const subs = await getRedemptionHistory()
    if (subs) {
      setRecords(subs.redemptions)
      setFundMap(subs.funds)
    }
  }
  useEffect(() => {
    fetchRedemptions()
  }, [location.search])

  return (
    <div className="order-wrapper">
      <Table basic="very" className="depro-table">
        <Table.Header className="depro-table-heading">
          <Table.Row>
            <Table.HeaderCell>{t('Order ID')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Token')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Amount')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Redeem to')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Fee')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Receive amount')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Time')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Status')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {records.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan="7" className="empty-data-wrapper">
                <NoResultFound />
              </Table.Cell>
            </Table.Row>
          )}
          {fundMap &&
            records.map((m: Redemption) => {
              return (
                <Table.Row key={m.Id}>
                  <Table.Cell className="order-col">
                    <div className="text">{m.Id}</div>
                    <img src={Copy} className="action" />
                  </Table.Cell>
                  <Table.Cell>
                    <div className="money">
                      <div className="coin">
                        {PlatformMap[m.products?.PlatformId!]?.ATokenPrefix}
                        {m.products?.TokenName}
                      </div>
                      <small>
                        {PlatformMap[m.products?.PlatformId!]?.Platform}
                      </small>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="money">
                      <div className="coin">
                        {ToFixNum(m.Amount)}{' '}
                        {PlatformMap[m.products?.PlatformId!]?.ATokenPrefix}
                        {m.products?.TokenName}
                      </div>
                      <small>
                        $
                        {ToFixNum(
                          Number(m.Amount) / tokenRates[m.products?.TokenName!],
                          2
                        )}
                      </small>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="money">
                      <div className="coin">{fundMap[m.Id]?.Currency}</div>
                      <small>{trimAddress(fundMap[m.Id]?.Account || '')}</small>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    ${ToFixNum(m.products?.RedemptionCharge || 0, 2)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="money">
                      <div className="coin">
                        {ToFixNum(fundMap[m.Id]?.Amount)}{' '}
                        {fundMap[m.Id]?.Currency}
                      </div>
                      <small>
                        $
                        {fundMap[m.Id].Channel === PaymentChannel.Credit &&
                          ToFixNum(
                            Number(fundMap[m.Id].Amount) /
                            fiatRates[fundMap[m.Id].Currency],
                            2
                          )}
                        {fundMap[m.Id].Channel === PaymentChannel.Crypto &&
                          ToFixNum(
                            Number(fundMap[m.Id]?.Amount) /
                            tokenRates[fundMap[m.Id].Currency],
                            2
                          )}
                      </small>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {new Date(m.UpdatedAt).toLocaleString()}
                  </Table.Cell>
                  <Table.Cell>
                    <Trans>{t(m.Status || '')}</Trans>
                  </Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table>
    </div>
  )
}

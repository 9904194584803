import './home.header.css'
import { useTranslation, Trans } from 'react-i18next'
import TitleSvg from "../../assets/images/title.svg"
import TitleCNSvg from "../../assets/images/title-zhCN.svg"

export const HomeHeader = () => {
  const { t, i18n } = useTranslation('home')

  return (
    <div className="header">
      <div className="intro">
        {
          i18n.language === "zh-CN" ? <img src={TitleCNSvg} /> : <img src={TitleSvg} />
        }
        {/* <br />
        <Trans i18nKey={'home:intro'}>
          One-click Access to <span className='gradient'>DeFi</span>
        </Trans> */}
      </div>
      <div className="desc">
        {t("In DePro, everyone can easily build crypto portfolio and generate crypto earnings here with fiat/crypto currencies in one click.")}
      </div>
    </div>
  )
}

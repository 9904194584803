"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLContracts = void 0;
class PrismaMySQLContracts {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLBankcards created');
    }
    GetStakeContracts(platform, chainId) {
        return __awaiter(this, void 0, void 0, function* () {
            const contracts = yield this.ctx.products.findMany({
                distinct: ['ContractAddress'],
                select: {
                    ContractAddress: true,
                },
                where: {
                    ChainId: chainId,
                    PlatformId: {
                        contains: platform,
                    },
                },
            });
            return contracts.map((m) => m.ContractAddress);
        });
    }
    GetSupportERC20Contracts(chainId) {
        return __awaiter(this, void 0, void 0, function* () {
            const contracts = yield this.ctx.products.findMany({
                select: {
                    TokenAddress: true,
                },
                where: {
                    ChainId: chainId,
                },
            });
            return contracts.map((m) => m.TokenAddress);
        });
    }
}
exports.PrismaMySQLContracts = PrismaMySQLContracts;

import "./trade.payment.css"
import { FiatPaymentParams, Trade } from "../../common/types"
import { useContext, useEffect, useState } from "react"
import { GetChainDataById, PaymentChannel, PlatformMap, SafeParseUnits } from "depro.common"
import { stakeByFiat, stakeByCrypto } from "../../api/cefi.api"
import { Modal } from "semantic-ui-react"
import CloseClear from '../../assets/images/close-clear.svg'
import TxnAction from '../../assets/images/txn-action.svg'
import TxnPending from '../../assets/images/txn-pending.svg'
import TxnUncompleted from '../../assets/images/txn-uncomplete.svg'
import { TokenInfo } from "./details.token"
import { ToFixNum, trimAddress } from "../../common/utils"
import { useNavigate } from "react-router-dom"
import useLocalStorage from "../../hooks/useLocalStorage"
import AppContext from "../../context/AppContext"
import { ethers } from "ethers"

interface ITradePaymentModal {
  open: boolean
  onClose: () => void
  trade: Trade
}

export const TradePaymentModal = (props: ITradePaymentModal) => {
  const { open, onClose, trade } = props
  const { address, chainId, web3Provider } = useContext(AppContext)
  const [view, setView] = useState<"loading" | "crypto_pending" | "created" | "failed" | "crypto_uncompleted">("loading")
  const naviagte = useNavigate()

  const [isPendingTxnFound, setPendingTxnFound] = useState<boolean>(false) // there is probably txn in metamask
  const [lastNonce, setLastNonce] = useLocalStorage("lastnonce", "") // last nonce

  const makeFiatPayment = async () => {
    if (trade?.currency) {
      const params: FiatPaymentParams = {
        productId: trade.proudctData.Id,
        payAmount: trade.amount,
        stakeAmount: trade.receiveAmount,
        currency: trade.currency.Currency,
        backHost: window.location.protocol + '//' + window.location.host,
        noticeUrl: process.env.REACT_APP_API?.includes('://')
          ? process.env.REACT_APP_API || ''
          : window.location.protocol +
          '//' +
          window.location.host +
          process.env.REACT_APP_API || '',
        fiatChannel: PaymentChannel.Credit,
        fiatCardId: 0,
      }

      const response = await stakeByFiat(params)
      if (response && response.res === 1 && response.ec === 0) {
        window.open(response['dt']['lst'])
        setView("created")
      } else {
        console.error('scusciprtion error: ', response)
        setView("failed")
      }
    }
  }

  const makeCryptoPayment = async () => {
    try {
      setView("crypto_pending")
      if (trade && trade.amount && trade.receiveAddress && trade.tokenCurrency) {
        if (chainId !== trade.proudctData.ChainId) {
          throw Error('Your wallet is not on the same chain')
        }

        const nativeCurrencyName = GetChainDataById(trade.proudctData.ChainId)
          ?.native_currency.symbol
        let txnhash: string = ''
        const signer = web3Provider.getSigner()

        const nonce = await signer.getTransactionCount();
        const checkKey = `${address}:${nonce}`
        console.log("Prepare nonce", nonce)

        if (lastNonce) {
          if (checkKey === lastNonce) {
            console.error("A transaction with the same nonce is already pending.", { lastNonce, nonce });
            setPendingTxnFound(true)
            return
          } else {
            console.warn(`Previous nonce [${lastNonce}] found. Replace by ${checkKey}`)
            setLastNonce(checkKey)
          }
        } else {
          setLastNonce(checkKey)
        }

        if (trade.tokenCurrency.address === nativeCurrencyName) {
          const tx = await signer.sendTransaction({
            to: trade.receiveAddress,
            value: ethers.utils.parseEther(trade.amount.toString()),
          })
          txnhash = tx.hash
        } else {
          const tokenContract = new ethers.Contract(
            trade.tokenCurrency.address,
            ['function transfer(address to, uint amount) returns (bool)'],
            web3Provider
          )
          const parsedAmount = SafeParseUnits(
            trade.amount,
            trade.tokenCurrency.decimals
          )

          const transaction = await tokenContract
            .connect(signer)
            .transfer(trade.receiveAddress, parsedAmount)

          txnhash = transaction.hash
        }

        if (txnhash) {
          const detail = {
            productId: trade.proudctData.Id,
            txn: txnhash,
            address: await signer.getAddress(),
            payAmount: trade.amount,
            stakeAmount: trade.receiveAmount,
            currency: trade.tokenCurrency.symbol,
            currencyAddress: trade.tokenCurrency.address,
          }

          const res = await stakeByCrypto(detail)
          if (res && !res.error) {
            setView("created")
          } else {
            console.error(`Error return from server`, res)
            setView("failed")
          }
          setLastNonce("")
        } else {
          console.warn('Transaction failed. Probably rejected by user')
          setView("failed")
          setLastNonce("")
        }
      }
    } catch (e) {
      console.error('Exception paying by wallet', e)
      setView("failed")
      setLastNonce("")
    }
  }

  const clearPendingTxn = async () => {
    if (isPendingTxnFound) {
      // already prompt error, wait next txn
      setLastNonce("")
    }
  }

  useEffect(() => {
    if (isPendingTxnFound) {
      setView("crypto_uncompleted")
    }
  }, [isPendingTxnFound])

  const makePayment = async () => {
    if (trade) {
      if (trade.currency) {
        makeFiatPayment()
      } else if (trade.tokenCurrency) {
        makeCryptoPayment()
      } else {
        console.error("Invalid trade")
      }
    }
  }

  useEffect(() => {
    if (open) {
      makePayment()
    } else {
      clearPendingTxn()
    }
  }, [open])

  return <Modal
    className="details-trade-payment"
    onClose={() => onClose()}
    open={open}
    size="tiny"
  >
    <Modal.Header>
      <a><img src={CloseClear} role="button" onClick={onClose} /></a>
    </Modal.Header>
    <Modal.Content className="content">
      {view === "loading" && (
        <>loading...</>
      )}

      {view === "crypto_pending" && (
        <div className="action-message">
          <img src={TxnAction} />
          <h3>Please confirm the transaction in your wallet</h3>
        </div>
      )}

      {view === "crypto_uncompleted" && (
        <div className="action-message">
          <img src={TxnUncompleted} />
          <h3>You have an unfinished order</h3>
          <p>Please cancel the authorization in your wallet before making the next order.</p>
        </div>
      )}

      {(view === "created" || view === "failed") && (
        <div className="result">
          <div className="status">
            <img src={TxnPending} />
            <h2>Your Subscription is
              {view === "created" && <span>{" "}created</span>}
              {view === "failed" && <span>{" "}failed</span>}
            </h2>
          </div>
          <div className="token">
            <TokenInfo product={trade.proudctData} />
          </div>
          <ul>
            <li>
              <label>Subscription amount</label>
              <p>{ToFixNum(trade.receiveAmount)} {PlatformMap[trade.proudctData.PlatformId].ATokenPrefix + trade.proudctData.TokenName}</p>
            </li>
            <li>
              <label>Channel</label>
              {trade.currency && <p>Fiat currencies</p>}
              {trade.tokenCurrency && <p>Crypto currencies</p>}
            </li>
            <li>
              <label>Pay with</label>
              {trade.currency && <p>Visa / Master card</p>}
              {trade.tokenCurrency &&
                <p>Crypto wallet
                  {view === "created" &&
                    <>&#x7c;<mark>{trimAddress(trade.receiveAddress)}</mark></>
                  }
                </p>
              }
            </li>
            <li>
              <label>Payment amount</label>
              {trade.currency && <p>{trade.amount} {trade.currency.Currency}</p>}
              {trade.tokenCurrency && <p>{trade.amount} {trade.tokenCurrency.symbol}</p>}
            </li>
          </ul>
          <div className="actions">
            {view === "created" && (
              <>
                <div role="button" className="inverted-primary" onClick={onClose}>Close</div>
                <div role="button" className="primary" onClick={() => {
                  naviagte('/dashboard?view=subscription')
                }}>Check details</div>
              </>
            )}
            {view === "failed" && (
              <div role="button" className="primary" onClick={onClose}>Close</div>
            )}
          </div>
        </div>
      )}
    </Modal.Content>

  </Modal >
}
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n'
import reportWebVitals from './reportWebVitals'
import {
  Mainnet,
  DAppProvider,
  Config,
  Mumbai,
  Goerli,
  Avalanche,
  AvalancheTestnet,
} from '@usedapp/core'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AppProvider from './context/AppProvider'
import 'semantic-ui-css/semantic.min.css'
import Home from './pages/home'
import { Login } from './pages/login'
import { Dashboard } from './pages/dashboard'
import { Verification } from './pages/verification'
import { BindEmail } from './pages/bindemail'
import { Product } from './pages/product'
import { ProductDetails } from './pages/product-details'
import { Payment } from './pages/payment'
import { EarnGroup } from 'depro.common'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const { REACT_APP_INFURA_ID, REACT_APP_ALCHEMY_ID } = process.env
const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    // [Mainnet.chainId]: getDefaultProvider('mainnet'),
    [Mainnet.chainId]: `https://mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`,
    [Goerli.chainId]: `https://goerli.infura.io/v3/${REACT_APP_INFURA_ID}`,
    [Mumbai.chainId]: `https://polygon-mumbai.g.alchemy.com/v2/${REACT_APP_ALCHEMY_ID}`,
    [Avalanche.chainId]: `https://avalanche-mainnet.infura.io/v3/${REACT_APP_INFURA_ID}`,
    [AvalancheTestnet.chainId]: `https://avalanche-fuji.infura.io/v3/${REACT_APP_INFURA_ID}`,
  },
}

root.render(
  <AppProvider>
    <DAppProvider config={config}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/products" element={<Product group={EarnGroup.Stake} />} />
          <Route path="/products/stake" element={<Product group={EarnGroup.Stake} />} />
          <Route path="/products/vaults" element={<Product group={EarnGroup.Vault} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/bind-email" element={<BindEmail />} />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route path="/payment" element={<Payment />} />
        </Routes>
      </BrowserRouter>
    </DAppProvider>
  </AppProvider>
)

reportWebVitals()

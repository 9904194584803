import './disconnected.css'
import { SigninButton } from '../../components/Btn'
import { useTranslation } from 'react-i18next'
import LoginWarn from "../../assets/images/dashboard-login-warn.svg"
export const DashboardDisconnected = () => {
  const { t } = useTranslation('dashboard')
  return (
    <div className="dashboard-disconnect">
      <img src={LoginWarn} />
      <h2>{t('Please, Log in or Sign up')}</h2>
      <p>{t("Please log in to see your assets, orders, and fund record.")}</p>
      <div className='login'>
        <SigninButton />
      </div>
    </div>
  )
}

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AwsService = void 0;
var AWS = require('aws-sdk');
const Minio = __importStar(require("minio"));
class AwsService {
    constructor(awsKeyId, awsKeySecret) {
        this.S3Bucket = '';
        AWS.config.getCredentials(function (err) {
            if (err)
                console.log(err.stack);
        });
        AWS.config.update({ region: 'ap-southeast-1' });
        this.AwsKeyId = awsKeyId;
        this.AwsKeySecret = awsKeySecret;
        // simple email services
        this.SES = new AWS.SES({ apiVersion: '2010-12-01' });
        // this.S3 = null
    }
    configS3(s3Enpoint, port, useSSL, region, bucket) {
        this.S3 = new Minio.Client({
            endPoint: s3Enpoint,
            port: port,
            accessKey: this.AwsKeyId,
            secretKey: this.AwsKeySecret,
            useSSL,
            region,
        });
        this.S3Bucket = bucket;
    }
    sendEmail(address, subject, content) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                Destination: {
                    ToAddresses: Array.isArray(address) ? address : [address],
                },
                Message: {
                    Body: {
                        Text: {
                            Charset: 'UTF-8',
                            Data: content,
                        },
                    },
                    Subject: {
                        Charset: 'UTF-8',
                        Data: subject,
                    },
                },
                Source: 'no-reply@depro.app',
            };
            try {
                const data = yield this.SES.sendEmail(params).promise();
                if (data) {
                    return {
                        messageId: data.MessageId,
                    };
                }
            }
            catch (err) {
                console.error(err);
                return {
                    error: err,
                };
            }
        });
    }
    uploadFile(fileName, buffer, cb) {
        return __awaiter(this, void 0, void 0, function* () {
            this.S3.putObject(this.S3Bucket, fileName, buffer, (error, _) => {
                if (error) {
                    console.error(`Error upload to S3`, { bucket: this.S3Bucket, fileName }, error);
                    if (cb)
                        cb(undefined);
                }
                if (cb)
                    cb(fileName);
            });
        });
    }
    downloadFile(filename, cb) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.S3.getObject(this.S3Bucket, filename, (error, stream) => {
                if (error) {
                    console.error(`Error download from S3`, { bucket: this.S3Bucket, filename }, error);
                    cb(undefined);
                }
                cb(stream);
            });
        });
    }
}
exports.AwsService = AwsService;

import './token-amount.css'
import { useTranslation } from 'react-i18next'
import { ToFixNum } from '../../common/utils'
import { GetChainName } from 'depro.common'

interface ITokenAmount {
  icon: string
  tokenName: string
  chainId: number
  amount: number | string
  amountUsd: number | string
}

export const TokenAmount = (props: ITokenAmount) => {
  const { t } = useTranslation('common')
  const { icon, tokenName, chainId, amount, amountUsd } = props

  return (
    <div className="token-amount-wrapper">
      <div className="token-label">
        <img className="icon" src={icon} />
        <div>
          <div className="name">{tokenName}</div>
          <div className="network">{GetChainName(chainId)}</div>
        </div>
      </div>
      <div className="token-value">
        <div className="amount">
          {ToFixNum(amount, 2)}&nbsp;
          {tokenName}
        </div>
        <div className="token-in-usd">
          {ToFixNum(amountUsd, 2)} {t('USD')}
        </div>
      </div>
    </div>
  )
}

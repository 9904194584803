"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLSubscription = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLSubscription {
    constructor(dbContext) {
        this.Add = (sub) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.subscriptions.create({
                data: {
                    UserId: sub.UserId,
                    ProductId: sub.ProductId,
                    Amount: sub.Amount,
                    Status: sub.Status,
                },
            });
            return res;
        });
        this.GetById = (id) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.subscriptions.findUnique({
                where: {
                    Id: id,
                },
                include: {
                    user: true,
                    products: true,
                },
            });
            return res;
        });
        this.GetByStatus = (status) => __awaiter(this, void 0, void 0, function* () {
            const res = this.ctx.subscriptions.findMany({
                where: {
                    Status: status,
                },
                include: {
                    products: true,
                    user: true,
                },
            });
            return res;
        });
        this.BulkUpdateStatus = (updates) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.$transaction(updates.map((m) => {
                return this.ctx.subscriptions.update({
                    where: {
                        Id: m.id,
                    },
                    data: {
                        Status: m.status,
                    },
                });
            }));
            return res.length;
        });
        this.UpdateStatus = (id, status) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.subscriptions.update({
                where: { Id: id },
                data: {
                    Status: status,
                },
            });
            return res;
        });
        this.UpdateAmount = (id, amount) => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.subscriptions.update({
                where: { Id: id },
                data: {
                    Amount: amount,
                },
                include: {
                    products: true,
                },
            });
            return res;
        });
        this.GetByUserWithFund = (options) => __awaiter(this, void 0, void 0, function* () {
            const subscriptions = yield this.ctx.subscriptions.findMany({
                where: {
                    Status: { in: options.status },
                    UserId: options.userId,
                    products: {
                        ChainId: options.chainId,
                        TokenName: options.tokenName,
                    },
                },
                include: { products: true },
                skip: options.offset,
                take: options.size,
                orderBy: { CreatedAt: 'desc' },
            });
            const funds = yield this.ctx.fund.findMany({
                where: {
                    Note: types_1.NoteType.Subscription,
                    OrderId: {
                        in: subscriptions.map((r) => r.Id),
                    },
                },
                orderBy: [{ UpdatedAt: 'desc' }],
            });
            const fundMap = {};
            funds.forEach((f) => {
                if (!fundMap[f.OrderId] || fundMap[f.OrderId].CreatedAt < f.CreatedAt) {
                    fundMap[f.OrderId] = f;
                }
            });
            return {
                subscriptions,
                funds: fundMap,
            };
        });
        this.GetByUser = (uid) => __awaiter(this, void 0, void 0, function* () {
            return this.ctx.subscriptions.findMany({
                where: {
                    UserId: uid,
                },
                include: { products: true, user: true },
                orderBy: { CreatedAt: 'desc' },
            });
        });
        this.GetByQuery = (subscriptionQuery) => __awaiter(this, void 0, void 0, function* () {
            const { bizId, userId, clientId, status, offset, size } = subscriptionQuery;
            return this.ctx.subscriptions.findMany({
                where: {
                    UserId: userId,
                    user: {
                        ParentId: bizId,
                        ClientId: clientId,
                    },
                    Status: status,
                },
                include: { products: true, user: true },
                orderBy: { CreatedAt: 'desc' },
                skip: offset || 0,
                take: size,
            });
        });
        this.ctx = dbContext;
        console.log('PrismaMySQLSubscription created');
    }
}
exports.PrismaMySQLSubscription = PrismaMySQLSubscription;

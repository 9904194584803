"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLRedemption = void 0;
const types_1 = require("../../common/types");
class PrismaMySQLRedemption {
    constructor(dbContext) {
        this.GetByUserWithFund = (options) => __awaiter(this, void 0, void 0, function* () {
            const redemptions = yield this.ctx.redemptions.findMany({
                where: {
                    Status: { in: options.status },
                    UserId: options.userId,
                    products: {
                        ChainId: options.chainId,
                        TokenName: options.tokenName,
                    },
                },
                include: { products: true },
                skip: options.offset,
                take: options.size,
                orderBy: { CreatedAt: 'desc' },
            });
            const funds = yield this.ctx.fund.findMany({
                where: {
                    Note: types_1.NoteType.Redemption,
                    OrderId: {
                        in: redemptions.map((r) => r.Id),
                    },
                },
                orderBy: [{ UpdatedAt: 'desc' }],
            });
            const fundMap = {};
            funds.forEach((f) => {
                if (!fundMap[f.OrderId] || fundMap[f.OrderId].CreatedAt < f.CreatedAt) {
                    fundMap[f.OrderId] = f;
                }
            });
            return {
                redemptions,
                funds: fundMap,
            };
        });
        this.ctx = dbContext;
        console.log('PrismaMySQLRedemption created');
    }
    Add(record) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.create({
                data: {
                    UserId: record.UserId,
                    ProductId: record.ProductId,
                    Amount: record.Amount,
                    Status: record.Status,
                },
            });
            return res;
        });
    }
    Delete(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.delete({ where: { Id: id } });
            return res;
        });
    }
    Update(r) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.update({
                where: { Id: r.Id },
                data: {
                    UserId: r.UserId,
                    ProductId: r.ProductId,
                    Amount: r.Amount,
                    Status: r.Status,
                },
            });
            return res;
        });
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.findFirst({
                where: { Id: id },
                include: { products: true, user: true },
            });
            return res;
        });
    }
    GetByUserIdProductId(userId, productId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.findFirst({
                orderBy: { Id: 'desc' },
                where: { UserId: userId, ProductId: productId },
                include: { products: true, user: true },
            });
            return res;
        });
    }
    GetCurrentWithdrawByUserIdProductId(userId, productId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.findFirst({
                where: {
                    UserId: userId,
                    ProductId: productId,
                    Status: {
                        notIn: [
                            types_1.RedemptionStatus.Success,
                            types_1.RedemptionStatus.ApprovalFail,
                            types_1.RedemptionStatus.Cancelled,
                        ],
                    },
                },
                include: { products: true, user: true },
            });
            console.log('Current withdraw', res);
            return res;
        });
    }
    GetByStatus(stauts) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.findMany({
                where: {
                    Status: stauts,
                },
                include: { products: true, user: true },
            });
            return res;
        });
    }
    BulkUpdateStatus(updates) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.$transaction(updates.map((m) => {
                return this.ctx.redemptions.update({
                    where: {
                        Id: m.id,
                    },
                    data: {
                        Status: m.status,
                    },
                });
            }));
            return res.length;
        });
    }
    GetRedeemingProductIds(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.findMany({
                where: {
                    UserId: userId,
                    Status: {
                        notIn: [
                            types_1.RedemptionStatus.Success,
                            types_1.RedemptionStatus.ApprovalFail,
                            types_1.RedemptionStatus.Cancelled,
                        ],
                    },
                },
                include: { products: true, user: true },
            });
            return res;
        });
    }
    GetListByStatus(status) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.findMany({
                where: {
                    Status: { in: status },
                },
            });
            return res;
        });
    }
    UpdateStatus(id, status) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.update({
                where: {
                    Id: id,
                },
                data: {
                    Status: status,
                },
            });
            return res;
        });
    }
    Filter(skip, status, userId, tokenName, platform) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.redemptions.findMany({
                where: {
                    Status: {
                        in: status,
                    },
                    UserId: userId,
                    products: {
                        TokenName: tokenName,
                        PlatformId: {
                            contains: platform,
                        },
                    },
                },
                include: {
                    products: true,
                },
                skip: skip,
                take: 10,
                orderBy: { Id: 'asc' },
            });
            return res;
        });
    }
    GetByUser(uid) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.ctx.redemptions.findMany({
                where: {
                    UserId: uid,
                },
                include: { products: true },
                orderBy: { CreatedAt: 'desc' },
            });
        });
    }
    GetByQuery(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const { offset, size, bizId, userId, clientId, status, fundChannel } = options;
            const funds = yield this.ctx.fund.findMany({
                where: {
                    Channel: fundChannel,
                    Note: types_1.NoteType.Redemption,
                    UserId: userId,
                    user: {
                        ParentId: bizId,
                        ClientId: clientId,
                    },
                },
            });
            const filterOrderIds = fundChannel ? funds.map((m) => m.OrderId) : undefined;
            const redemptions = yield this.ctx.redemptions.findMany({
                where: {
                    UserId: userId,
                    Status: status,
                    user: {
                        ParentId: bizId,
                        ClientId: clientId,
                    },
                    Id: {
                        in: filterOrderIds,
                    },
                },
                include: { products: true, user: true },
                orderBy: { CreatedAt: 'desc' },
                skip: offset,
                take: size,
            });
            const results = [];
            redemptions.forEach((r) => {
                const recordFunds = funds.filter((f) => f.OrderId === r.Id);
                if (recordFunds && recordFunds.length > 0) {
                    results.push(Object.assign(Object.assign({}, r), { funds: recordFunds }));
                }
            });
            return results;
        });
    }
}
exports.PrismaMySQLRedemption = PrismaMySQLRedemption;

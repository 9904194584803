import { useState, useEffect } from 'react'
import './bindemail.css'
import { DeproHeader } from '../../components'
import { Button, Icon, Input } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { validateEmail } from '../../common/utils'
import useCountDown from '../../hooks/useCountDown'
import { getVerificationCodeByEmail, verifyEmailCode } from '../../api/kyc.api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import { useTranslation } from 'react-i18next'
import { EmailCodeType } from 'depro.common'

export const BindEmail = () => {
  const { t } = useTranslation(['bindmail', 'common'])
  const { REACT_APP_EMAIL_TIME } = process.env
  const { profile, setProfile } = useContext(AppContext)
  const [codeBtnTxt, setCodeBtnTxt] = useState('Send Code')
  const [codeBtnDisabled, setCodeBtnDisabled] = useState(true)
  const [email, setEmail] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [continueDisabled, setContinueDisabled] = useState(true)
  const { timeLeft, start, isfirstAttempt } = useCountDown(
    Number(REACT_APP_EMAIL_TIME) | 60
  )
  const [formErrEmail, setFormErrEmail] = useState<string>()
  const [formErrCode, setFormErrCode] = useState<string>()
  const navigate = useNavigate()
  const [searchPara, _] = useSearchParams()

  const getCode = async () => {
    if (validateEmail(email)) {
      const res = await getVerificationCodeByEmail(EmailCodeType.Account, email)
      if (res) {
        if (res.error) {
          console.error(res.error)
        } else {
          start()
        }
      }
    } else {
      setFormErrEmail(t('Invalid Email'))
    }
  }

  const verify = async () => {
    const res = await verifyEmailCode(EmailCodeType.Account, code, email)
    if (res.success) {
      if (profile) {
        setProfile({ ...profile, Email: email })
      }
    } else {
      setFormErrCode(t('Invalid code'))
    }
  }

  useEffect(() => {
    if (profile?.Email) {
      navigate({ pathname: searchPara.get('from') || '/' })
    }
  }, [[profile]])

  // code btn text
  useEffect(() => {
    if (timeLeft != null) {
      if (timeLeft > 0) {
        setCodeBtnTxt(`${timeLeft}${t('s')}`)
      } else {
        if (isfirstAttempt) {
          setCodeBtnTxt(t(`Send Code`))
        } else {
          setCodeBtnTxt(t(`Resend Code`))
        }
      }
    }
  }, [timeLeft])

  //Continue button
  useEffect(() => {
    if (code) {
      setContinueDisabled(false)
    } else {
      setContinueDisabled(true)
    }
  }, [code])

  //Send code button
  useEffect(() => {
    if (email && (!timeLeft || timeLeft === 0)) {
      setCodeBtnDisabled(false)
    } else {
      setCodeBtnDisabled(true)
    }
  }, [email, timeLeft])

  //form err
  useEffect(() => {
    if (email) {
      setFormErrEmail(undefined)
    }
  }, [email])

  return (
    <div className="bind-wallet-wrapper app-padding">
      <DeproHeader />
      <div className="main">
        <div className="head modal-header-title">
          <div>{t('Bind Email Address')}</div>
          <Link
            className="btn-modal-close"
            to={{ pathname: searchPara.get('from') || '/' }}
          />
        </div>
        <div className="sub-head">
          {t('For the security of your account, please bind the email address')}
        </div>
        <div className="form">
          <div className="item">
            <div className="text">
              <div className="label">{t('Email')}</div>
              <div className="error">{formErrEmail}</div>
            </div>
            <div className="control">
              <Input
                fluid
                className="input-ctrl"
                placeholder={t('Input Email')}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                icon={
                  email ? (
                    <Icon
                      name="times circle"
                      link
                      onClick={() => setEmail('')}
                    />
                  ) : null
                }
              />
            </div>
          </div>
          <div className="item">
            <div className="text">
              <div className="label">{t('Verification Code')}</div>
              <div className="error">{formErrCode}</div>
            </div>
            <div className="code-wrapper">
              <div className="txt-code">
                <Input
                  fluid
                  className="input-ctrl"
                  placeholder={t('Input Code')}
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  icon={
                    code ? (
                      <Icon
                        name="times circle"
                        link
                        onClick={() => setCode('')}
                      />
                    ) : null
                  }
                />
              </div>
              <Button
                className="btn send-code input-ctrl"
                disabled={codeBtnDisabled}
                onClick={getCode}
              >
                {codeBtnTxt}
              </Button>
            </div>
          </div>
          <div className="item">
            <div className="btn-wrapper">
              <Button
                className="btn continue input-ctrl"
                disabled={continueDisabled}
                onClick={verify}
              >
                {t('common:Continue')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

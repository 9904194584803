import './depro.header.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Profile, Lang } from '.'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LogoSvg } from '../../assets/images/logo.svg'
import VaultsMenuItem from '../../assets/images/vaults-menu.svg'
import StakeMenuItem from '../../assets/images/stake-menu.svg'
import DownArrow from '../../assets/images/down_arrow.svg'
import UpArrow from '../../assets/images/up_arrow.svg'
import { Dropdown } from 'semantic-ui-react'
import { useState } from 'react'


export const DeproHeader = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation(['header', "common"])
  const [arrow, setArrow] = useState<string>(DownArrow)

  return (
    <div className="depro-header">
      <div className="logo">
        <Link to={{ pathname: '/home' }}>
          <LogoSvg />
        </Link>
      </div>
      <menu>
        <div>
          <Link
            to={{ pathname: '/home' }}
            className={
              location.pathname === '/home' || location.pathname === '/'
                ? 'active'
                : ''
            }
          >
            {t('Home')}
          </Link>
          <Dropdown
            onOpen={() => { setArrow(UpArrow) }}
            onClose={() => { setArrow(DownArrow) }}
            icon={null}
            basic
            style={{ border: 'none' }}
            floating
            default
            trigger={
              <span className='action'>{t('Products')} <img src={arrow} /></span>
            }
            onMouseEnter={(event: any) => {
              const target = event.currentTarget;
              target?.click();
              target?.focus();
              setArrow(UpArrow)
            }}
            onMouseLeave={() => {
              setArrow(DownArrow)
            }}
          >
            <Dropdown.Menu className='product-menu'>
              <Dropdown.Item text={t("common:STAKE")} value={undefined}
                onClick={() => {
                  navigate({ pathname: '/products/stake' })
                }}
                image={{ avatar: true, src: StakeMenuItem }} />
              <Dropdown.Item text={t("common:VAULT")} value={undefined}
                onClick={() => {
                  navigate({ pathname: '/products/vaults' })
                }}
                image={{ avatar: true, src: VaultsMenuItem }} />
            </Dropdown.Menu>
          </Dropdown>

          <Link
            to={{ pathname: '/dashboard' }}
            className={location.pathname === '/dashboard' ? 'active' : ''}
          >
            {t('Dashboard')}
          </Link>
        </div>
        <a target="_blank" href="https://www.moonpay.com/zh/buy">
          {t('Buy crypto')}
        </a>
      </menu>
      <div className="controls">
        <Lang icon={null} />
        <Profile />
      </div>
    </div>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLManagementUser = void 0;
class PrismaMySQLManagementUser {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLManagementUser created');
    }
    Add(mgmtUser) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.management_user.create({
                data: {
                    Name: mgmtUser.Name,
                    Password: mgmtUser.Password,
                    Role: mgmtUser.Role,
                },
            });
            return result;
        });
    }
    GetById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const record = yield this.ctx.management_user.findUnique({
                where: { Id: id },
            });
            return record || null;
        });
    }
    GetByName(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const record = yield this.ctx.management_user.findFirst({
                where: { Name: name },
            });
            return record || null;
        });
    }
    DeleteByName(name) {
        return __awaiter(this, void 0, void 0, function* () {
            const record = yield this.ctx.management_user.delete({
                where: { Name: name },
            });
            return record;
        });
    }
    UpdateRoleById(userId, role) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.management_user.update({
                where: {
                    Id: userId,
                },
                data: {
                    Role: role,
                },
            });
            return result;
        });
    }
    UpdatePasswordById(id, newPassword) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ctx.management_user.update({
                where: {
                    Id: id,
                },
                data: {
                    Password: newPassword,
                },
            });
            return result;
        });
    }
    Filter(option) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.ctx.management_user.findMany({
                where: { Name: option.username },
                skip: option.offset,
                take: option.size,
            });
        });
    }
}
exports.PrismaMySQLManagementUser = PrismaMySQLManagementUser;

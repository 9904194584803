"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrismaMySQLFiatNoticeLog = void 0;
class PrismaMySQLFiatNoticeLog {
    constructor(dbContext) {
        this.ctx = dbContext;
        console.log('PrismaMySQLFiatNoticeLog created');
    }
    Add(fiatNoticeLog) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fiat_notice_log.create({
                data: {
                    SubscriptionId: fiatNoticeLog.SubscriptionId,
                    BackHost: fiatNoticeLog.BackHost,
                    Amount: fiatNoticeLog.Amount,
                    OrderAmount: fiatNoticeLog.OrderAmount,
                    Currency: fiatNoticeLog.Currency,
                    TradeTime: fiatNoticeLog.TradeTime,
                    Type: fiatNoticeLog.Type,
                    Status: fiatNoticeLog.Status,
                    Sign: fiatNoticeLog.Sign,
                    Uuid: fiatNoticeLog.Uuid,
                    Key: fiatNoticeLog.Key,
                    Signature: fiatNoticeLog.Signature,
                    Signature2: fiatNoticeLog.Signature2,
                    Exrate: fiatNoticeLog.Exrate,
                    ExAmount: fiatNoticeLog.ExAmount,
                    SourceCurrency: fiatNoticeLog.SourceCurrency,
                },
            });
            return res;
        });
    }
    GetLatestBySubscriptionId(sid) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.ctx.fiat_notice_log.findFirst({
                where: {
                    SubscriptionId: sid,
                },
                orderBy: {
                    TradeTime: 'desc',
                },
            });
            return res;
        });
    }
}
exports.PrismaMySQLFiatNoticeLog = PrismaMySQLFiatNoticeLog;

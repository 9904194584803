import "./VerificationMessage.css"
import { Button } from 'semantic-ui-react'
import { ReactComponent as IcoVerifyId } from '../../assets/images/verify-id.svg'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


interface IVerificationMessage {
  onBack: () => void
}

export const VerificationMessage = (props: IVerificationMessage) => {
  const { onBack } = props
  const { t } = useTranslation(['redemption', 'common'])
  const navigate = useNavigate()
  return (
    < div className="verification-message" >
      <IcoVerifyId />
      <div className="texts">
        <div className="title">Please verify your identity</div>
        <div className="desc">
          {t(
            'To comply with regulatory requirements, please take 3 minutes to complete your identify verification.'
          )}
        </div>
      </div>
      <div className="infos">
        <div className="item">
          <div className="pt"></div>
          <div>Basic information</div>
        </div>
        <div className="item">
          <div className="pt"></div>
          <div>Upload ID Photo</div>
        </div>
      </div>
      <div className="action">
        <Button className="inverted-primary" onClick={onBack}>
          {t('Cancel')}
        </Button>
        <Button
          className="primary"
          onClick={() => {
            navigate({
              pathname: `/verification`,
              search: `from=${window.location.pathname}`,
            })
          }}
        >
          {t('Verify Identity')}
        </Button>
      </div>
    </div >
  )
}
import './trade.confirm.css'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'semantic-ui-react'
import { ReactComponent as FiatTransfer } from '../../assets/images/fiat-transfer.svg'
import { ToFixNum, ToFixPercent, maskCondential } from '../../common/utils'
import { PlatformMap } from 'depro.common'
import { Trade } from '../../common/types'

interface ITradeConfirmModal {
  open: boolean
  onClose: (isConfirm?: boolean) => void
  trade?: Trade
}

export const TradeConfirmModal = (props: ITradeConfirmModal) => {
  const { open, onClose, trade } = props
  const { t } = useTranslation(['detail', 'common'])

  if (!trade) return <></>

  const aTokenName = PlatformMap[trade.proudctData.PlatformId].ATokenPrefix + trade.proudctData.TokenName

  const currencyName = trade.currency?.Currency || trade.tokenCurrency?.symbol || undefined
  const currencyIcon = trade.currency?.IconURL || trade.tokenCurrency?.icon || undefined

  return (
    <Modal
      className="trade-confirm"
      onClose={() => onClose()}
      open={open}
      size="tiny"
    >
      <Modal.Header>
        <div className="modal-header-title">{t('Confirm Subscription')}</div>
        <div className="btn-modal-close" onClick={() => onClose()} />
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className="content-wrapper">
            <div className="currencies">
              <div className='currency'>
                <img src={currencyIcon} />
                <p>{currencyName}</p>
              </div>
              <div className='transfer'>
                <FiatTransfer />
              </div>
              <div className='currency'>
                <img src={trade.proudctData.IconURL} />
                <p>{aTokenName}</p>
              </div>
            </div>

            <ul className="item-group">
              <li>
                <label>{t('common:Platform')}</label>
                <p>{PlatformMap[trade.proudctData.PlatformId].Platform}</p>
              </li>
              <li>
                <label>{t('APY')}</label>
                <p>
                  {ToFixPercent(trade.proudctData.product_market?.Apy)}
                </p>
              </li>
              <li><hr /></li>
              <li>
                <label>{t('Subscription amount (estimate)')}</label>
                <p>
                  {ToFixNum(trade.receiveAmount) + " " + aTokenName}
                </p>
              </li>
              <li>
                <label>{t('Rate')}</label>
                <p>{`1 ${trade.proudctData.TokenName} ≈ ${trade.currencyPerProductRate?.toPrecision(2)} ${currencyName}`}</p>
              </li>
              <li>
                <label>{t('Fee')}</label>
                <p>{trade.proudctData.SubscriptionCharge ? `$` + trade.proudctData.SubscriptionCharge : "-"}</p>
              </li>
              <li>
                <label>{t('Payment method')}</label>
                {trade.currency && <p>{t('Third-party Payment')}</p>}
                {trade.tokenCurrency &&
                  <p>
                    {t('Crypto wallet')}{' '} | <b>{maskCondential(trade.receiveAddress)}</b>
                  </p>}
              </li>
              <li>
                <label>{t('Payment amount')}</label>
                <strong>{trade.amount} {currencyName}</strong>
              </li>
            </ul>
          </div>
        </Modal.Description>
      </Modal.Content >
      <Modal.Actions>
        <div className="confirm-action">
          <Button className="inverted-primary" onClick={() => onClose()}>
            {t('common:Cancel')}
          </Button>
          <Button className="primary" onClick={() => onClose(true)}>
            {t('common:Continue')}
          </Button>
        </div>
      </Modal.Actions>
    </Modal >
  )
}

export enum WalletType {
  Hosted,
  Local,
}

export enum ProfileType {
  Google = 'GOOGLE',
  Telegram = 'TELEGRAM',
  LocalWallet = 'LOCAL_WALLET',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

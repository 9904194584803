import { DeproLogin } from '.'
import { useContext, useEffect } from 'react'
import AppContext from '../../context/AppContext'
import './login.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DeproHeader } from '../../components'

export const Login = () => {
  const { profile } = useContext(AppContext)
  const navigate = useNavigate()
  const [searchPara, _] = useSearchParams()

  useEffect(() => {
    if (profile) {
      if (profile.Email) navigate({ pathname: searchPara.get('from') || '/' })
      else
        navigate({
          pathname: '/bind-email',
          search: `?from=${searchPara.get('from')}`,
        })
    }
  }, [profile])

  return (
    <div className="loginContainer app-padding">
      <DeproHeader />
      <DeproLogin />
    </div>
  )
}

import './details.trade.css'
import { useState, useContext, useEffect, useRef } from 'react'
import AppContext from '../../context/AppContext'
import { Button, Dropdown, Modal } from 'semantic-ui-react'
import { SigninButton } from '../../components/Btn'
import { ConnectWalletMessage } from '../../components/ConnectWallet'
import {
  useDebounce,
  useTokenBalance,
  useEtherBalance,
} from '@usedapp/core/dist/esm/src/hooks'
import { getWalletAddress } from '../../api/cefi.api'
import { getTokenRates, getFiatRates } from '../../api/exrate.api'
import { EarnType, TokenCurrency, Trade } from '../../common/types'
import { Product, KYCStatus, PaymentChannel, PlatformMap, GetChainName, QuoteErrorType, FiatRate } from 'depro.common'
import { TradeConfirmModal } from './trade.confirm'
import { ethers } from 'ethers'
import { useTranslation } from 'react-i18next'
import { getAllProducts } from '../../api/product.api'
import { subscribeQuote } from '../../api/earn.api'
import { ToFixNum, trimAddress } from '../../common/utils'
import { QuoteResponse } from '../../components/RedeemDialog'
import usePrevious from '../../hooks/usePrevious'
import { ReactComponent as DownArrow } from '../../assets/images/down_arrow.svg'
import WalletBankcard from '../../assets/images/wallet-bankcard.svg'
import WalletOffBlack from '../../assets/images/wallet_off_black.svg'
import WalletMetamask from '../../assets/images/metamask.svg'
import WalletWC from '../../assets/images/wallet-connect.svg'
import CloseClear from '../../assets/images/close-clear.svg'
import WarningMsg from '../../assets/images/warning-msg.svg'
import { ReactComponent as ArrowRightBlue } from '../../assets/images/arrow-right-blue.svg'
import { VerificationMessage } from '../../components/VerificationMessage/VerificationMessage'
import { TradePaymentModal } from './trade.payment'

enum StakeChannel {
  Fiat = 'FIAT',
  Crypto = 'CRYPTO',
}

type SelectImageOption = {
  key: number
  text: string
  value: string
  image: { avatar: boolean, src: string }
  decimals: number
}

export const ProductDetailsTrade = ({ data }: { data: Product }) => {
  const { t } = useTranslation(['detail', 'common'])
  const { profile, address, provider, web3Provider, chainId, connectWallet } =
    useContext(AppContext)

  const [trade, setTrade] = useState<Trade>()
  const [stakeChannel, setStakeChannel] = useState<StakeChannel>(
    StakeChannel.Crypto
  )

  const fromInputRef = useRef<HTMLInputElement>(null)
  const [amount, setAmount] = useState<number>()
  const [amountUSD, setAmountUSD] = useState<number>(0)

  const debounceAmount = useDebounce(amount, 1000)
  const [isLoading, setIsLoading] = useState(false)
  const [receiveAmount, setReceiveAmount] = useState<string | QuoteErrorType>(QuoteErrorType.InvalidSwap)

  //* store rate and also provide option for fiat & token currency dropdown selection
  const [tokenRates, setTokenRates] = useState<Record<string, number>>({}) // for tokens currency choice
  const [fiatRates, setFiatRates] = useState<FiatRate[]>([]) // for fiat rates choice
  const [currency, setCurrency] = useState<FiatRate>()
  const [tokenCurrency, setTokenCurrency] = useState<TokenCurrency>()
  const [expireIn, setExpireIn] = useState<number>()
  const [remainingTime, setRemainingTime] = useState<number>()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [userValidBalance, setUserValidBalance] = useState('')
  const [quote, setQuote] = useState<QuoteResponse>()
  const previousQuote = usePrevious(quote)
  const [nextFetchId, setNextFetchId] = useState(0)
  const [isOpenVerifyDialog, setOpenVerifyDialog] = useState(false)
  const [actionState, setActionState] = useState<{ text: string, disabled: boolean }>()

  const currencyValue = currency?.Ask || currency?.Mid || NaN
  /* defi trade */
  const userTokenBalanceWei = useTokenBalance(
    !tokenCurrency || tokenCurrency.address === 'ETH'
      ? undefined
      : tokenCurrency?.address,
    address,
    {
      chainId: chainId,
    }
  )
  const userTokenBalance = ethers.utils.formatUnits(
    userTokenBalanceWei || 0,
    tokenCurrency?.decimals || 18
  )

  const userEtherBalanceWei = useEtherBalance(address, {
    chainId: chainId,
  })
  const userEtherBalance = ethers.utils.formatEther(userEtherBalanceWei || 0)

  const [tokenOptions, setTokenOptions] = useState<SelectImageOption[]>([])

  useEffect(() => {
    if (tokenCurrency) {
      if (tokenCurrency.address === 'ETH') {
        setUserValidBalance(userEtherBalance)
      } else {
        setUserValidBalance(userTokenBalance)
      }
    } else {
      setUserValidBalance('')
    }
  }, [userTokenBalance, userEtherBalance, tokenCurrency])

  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [receiveAddress, setReceiveAddress] = useState<string>()

  const handleContinue = async () => {
    if (
      profile?.KYCStatus === KYCStatus.Success ||
      stakeChannel === StakeChannel.Crypto
    ) {
      const trade: Trade = {
        amount: debounceAmount || 0,
        amountUSD: amountUSD,
        currency: stakeChannel === StakeChannel.Fiat ? currency : undefined,
        tokenCurrency:
          stakeChannel === StakeChannel.Crypto ? tokenCurrency : undefined,
        receiveAmount: Number(receiveAmount),
        receiveAmountUsd: amountUSD - data.SubscriptionCharge,
        proudctData: data,
        receiveAddress,
        currencyPerProductRate: currencyValue / tokenRates[data.TokenName] || NaN
      }
      setTrade(trade)
      setOpenConfirmModal(true)
    } else {
      setOpenVerifyDialog(true)
    }
  }

  const fetchRate = async () => {
    const [walletAddress, frates, trates] = await Promise.all([
      getWalletAddress(data.ChainId),
      getFiatRates(EarnType.Subscription),
      getTokenRates(undefined, true),
    ])

    if (walletAddress?.address) {
      setReceiveAddress(walletAddress.address)
    }
    setTokenRates(trates)
    setFiatRates(frates)
  }

  useEffect(() => {
    if (receiveAmount && expireIn) {
      const expired = setInterval(() => {
        var remainingSec = expireIn - new Date().getTime() / 1000
        if (remainingSec < 0) {
          remainingSec = 0
          setRemainingTime(0)
          return
        }
        setRemainingTime(Math.floor(remainingSec))
      }, 1000)

      return () => clearInterval(expired)
    } else {
      setRemainingTime(undefined)
    }
  }, [expireIn, receiveAmount])

  const checkTxnValid = async () => {
    if (debounceAmount) {
      try {
        setIsLoading(true)

        const newNextId = nextFetchId + 1
        setNextFetchId(newNextId)

        let channel = PaymentChannel.Bank

        if (stakeChannel === StakeChannel.Crypto) {
          channel = PaymentChannel.Crypto

          if (Number(userValidBalance) < Number(debounceAmount)) {
            throw new Error(`Invalid Amount`)
          }

          const rateTokenCurrency = tokenRates[tokenCurrency?.name!]
          if (rateTokenCurrency) {
            setAmountUSD(debounceAmount / rateTokenCurrency)
          }
        } else {
          if (currency) {
            setAmountUSD(debounceAmount / currencyValue)
          }
        }

        const quote = await subscribeQuote({
          productId: data.Id,
          channel,
          amount: debounceAmount.toString(),
          currency:
            stakeChannel === StakeChannel.Crypto ? tokenCurrency! : currency!.Currency,
          fetchId: newNextId,
        })

        if (quote && !quote.error) {
          setQuote(quote)
        } else {
          setQuote(undefined)
        }
      } catch (e) {
        console.error('Exception validation', e)
        setAmountUSD(0)
        setReceiveAmount('')
      } finally {
        setIsLoading(false)
      }
    } else {
      setAmountUSD(0)
      setReceiveAmount('')
    }
  }

  useEffect(() => {
    let disabled = true
    let text = "Continue"

    if (stakeChannel === StakeChannel.Fiat && profile?.KYCStatus !== KYCStatus.Success) {
      text = "Verify"
      disabled = false
    } else if (address && stakeChannel === StakeChannel.Crypto && chainId !== data.ChainId) {
      text = "Wrong Network"
    } else if (!debounceAmount || debounceAmount <= 0) {
      text = "Enter an amount"
    } else if (stakeChannel === StakeChannel.Crypto && Number(userValidBalance) < debounceAmount) {
      text = "Insufficient Balance"
    } else if (receiveAmount === QuoteErrorType.InsufficientBalance) {
      text = "Insufficient Balance"
    } else if (receiveAmount === QuoteErrorType.SoldOut) {
      text = "Sold out"
    } else if (receiveAmount === QuoteErrorType.InvalidSwap) {
      text = "Invalid swap"
    } else {
      disabled = false
    }
    setActionState({ text, disabled })

  }, [profile, stakeChannel, chainId, debounceAmount, receiveAmount, userValidBalance])

  useEffect(() => {
    if (quote) {
      const fetchId = quote.clientFetchId
      if (fetchId < (previousQuote?.clientFetchId || 0)) {
        console.warn('Abort late response')
      } else {
        if (isNaN(quote.amountUnit) || isNaN(quote.amountUsd)) {
          console.error(`Error res ${JSON.stringify(quote)}`)
        } else {
          if (quote.quoteError !== QuoteErrorType.None) {
            setReceiveAmount(quote.quoteError)
          } else {
            setReceiveAmount(
              quote.amountUnit > 0 ? quote.amountUnit.toString() : QuoteErrorType.InsufficientBalance
            )
          }
        }
      }
    } else {
      setReceiveAmount('')
    }
  }, [quote])

  useEffect(() => {
    checkTxnValid()
  }, [
    stakeChannel,
    debounceAmount,
    address,
    web3Provider,
    data,
    currency,
    tokenCurrency,
  ])

  const fetchTokenOptions = async () => {
    const products: Product[] = await getAllProducts()
    let options = products
      .filter((f) => f.ChainId === data.ChainId)
      .map((m) => {
        return {
          key: m.Id,
          text: m.TokenName,
          value: m.TokenAddress || m.TokenName,
          image: { avatar: false, src: m.IconURL },
          decimals: m.TokenDecimal,
        }
      })

    //TODO wait support of swapping token of avalanche
    if (data.ChainId === 43113 || data.ChainId === 43114) {
      options = options.filter((f) => f.value === data.TokenAddress)
    }

    setTokenOptions(options)
  }

  // refresh the expire time
  useEffect(() => {
    if (currency) {
      setExpireIn(new Date(currency.UpdatedAt).getTime() / 1000 + currency.ValidSec)
    } else {
      const defaultRate = fiatRates.find(f => f.Currency === "EUR") || fiatRates[0]
      setCurrency(defaultRate)
    }
  }, [currency, fiatRates]) // effect for change fiat currency

  useEffect(() => { }, [tokenCurrency]) //effect for change token currency

  useEffect(() => {
    if (tokenOptions && tokenOptions.length > 0) {
      let opt = tokenOptions.find((f: any) => f.value === data.TokenAddress)
      if (!opt) opt = tokenOptions[0]
      setTokenCurrency({
        address: opt.value || opt.text,
        symbol: opt.text,
        name: opt.text,
        decimals: opt.decimals,
        icon: opt.image.src
      })
    }
  }, [tokenOptions])

  useEffect(() => {
    fetchRate()
    fetchTokenOptions()
  }, [])

  return (
    <div className="details-trade">
      <div className="options">
        <div
          className={
            'crypto option' +
            (stakeChannel === StakeChannel.Crypto ? ' active' : '')
          }
          onClick={() => setStakeChannel(StakeChannel.Crypto)}
        >
          {t('Crypto currencies')}
        </div>
        <div
          className={
            'fiat option' +
            (stakeChannel === StakeChannel.Fiat ? ' active' : '')
          }
          onClick={() => setStakeChannel(StakeChannel.Fiat)}
        >
          {t('Fiat currencies')}
        </div>
      </div>

      {actionState?.text === "Wrong Network" &&
        <div className='message'>
          <img src={WarningMsg} />
          <p>Please switch to {GetChainName(data.ChainId)} Network！</p>
        </div>
      }

      <div className="content">
        {stakeChannel === StakeChannel.Crypto && !address && (
          <div className="crypto-connect">
            <ConnectWalletMessage />
          </div>
        )}

        {(stakeChannel === StakeChannel.Fiat || address) && (
          <>
            <div className="pay">
              <div className="input-wrapper">
                <div className="label">{t('From')}</div>
                <div className="token-wrapper">
                  <div className="amount">
                    <input
                      ref={fromInputRef}
                      type="number"
                      className="unit"
                      placeholder="0.00"
                      onChange={(event) => {
                        setAmount(Number(event.target.value))
                      }}
                    />
                    <div className="usd">
                      ${amountUSD?.toFixed(2) || 0}
                    </div>
                  </div>
                  {stakeChannel === StakeChannel.Fiat && (
                    <Dropdown
                      icon={null}
                      pointing="top right"
                      className='coin-selection'
                      value={currency?.Currency}
                      options={fiatRates.map((m) => {
                        return {
                          key: m.Id,
                          text: m.Currency,
                          value: m.Currency,
                          image: { avatar: false, src: m.IconURL },
                        }
                      })}
                      onChange={(_, val) => {
                        const c = fiatRates.find(f => f.Currency === val.value)
                        setCurrency(c)
                      }}
                      trigger={<div className='select-group' role="button">
                        <img src={currency?.IconURL || undefined} />{currency?.Currency}<DownArrow />
                      </div>
                      }
                    ></Dropdown>


                  )}
                  {stakeChannel === StakeChannel.Crypto && (

                    <Dropdown
                      icon={null}
                      className="coin-selection"
                      placeholder="Select token"
                      fluid
                      scrolling
                      pointing="top right"
                      value={tokenCurrency?.address}
                      token-address={tokenCurrency?.address}
                      onChange={(_, o) => {
                        const opt = tokenOptions.find(
                          (f: any) => f.value === o.value
                        )
                        if (opt) {
                          setTokenCurrency({
                            address: opt.value || opt.text,
                            symbol: opt.text,
                            name: opt.text,
                            decimals: opt.decimals,
                            icon: opt.image.src
                          })
                        }
                      }}
                      options={tokenOptions}
                      trigger={
                        <div className='select-group' role="button">
                          <img src={tokenOptions.find(f => f.text === tokenCurrency?.symbol)?.image.src} />
                          <div>{tokenCurrency?.symbol || "Select coin"}</div>
                          <DownArrow />
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
              <div className="add-info">
                <div className="left">
                  {stakeChannel === StakeChannel.Crypto && (
                    <div
                      className={
                        amount && amount > Number(userValidBalance)
                          ? 'insufficent-balance'
                          : ''
                      }
                    >
                      {t('Balance')}: {ToFixNum(userValidBalance)}{' '}
                      {` ${tokenCurrency?.symbol || ''}`}
                    </div>
                  )}
                </div>
                <div className="right">
                  {stakeChannel === StakeChannel.Crypto && (
                    <button
                      className="max-token-select"
                      onClick={() => {
                        if (fromInputRef.current) {
                          fromInputRef.current.value = ToFixNum(userValidBalance).toString()
                        }
                        setAmount(Number(userValidBalance))
                      }}
                    >
                      {t('Max')}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="get">
              <div className="input-wrapper">
                <div className="label-wrapper">
                  <div className="label">{t('To (estimate)')}</div>
                </div>
                <div className="token-wrapper">
                  <div className="amount">
                    <input
                      className="unit"
                      placeholder="0.00"
                      value={isNaN(Number(receiveAmount)) ? '' : ToFixNum(receiveAmount)}
                      disabled
                    />
                    <div className="usd">
                      {isNaN(Number(receiveAmount)) ? '0' : `$` + ToFixNum(Number(receiveAmount) / tokenRates[data.TokenName])}
                    </div>
                  </div>
                  <div className="unit">
                    <img src={data.IconURL} />
                    <p>{PlatformMap[data.PlatformId].ATokenPrefix + data.TokenName}</p>
                  </div>
                </div>
              </div>
              <div className="add-info">
                <div className="left">
                  {stakeChannel === StakeChannel.Fiat ? (
                    <>
                      <p>
                        1 {PlatformMap[data.PlatformId].ATokenPrefix}{data.TokenName} ≈{' '}
                        {ToFixNum(
                          currencyValue || NaN / tokenRates[data.TokenName] || NaN,
                          2
                        )}{' '}
                        {currency?.Currency}
                      </p>
                      <p>
                        {receiveAmount &&
                          remainingTime !== undefined &&
                          (remainingTime > 0 ? (
                            t('Quote updates in', {
                              second: remainingTime,
                            })
                          ) : (
                            t('Quote expired')
                          ))}
                      </p>
                    </>
                  ) : (
                    tokenCurrency && (
                      <>
                        1 {PlatformMap[data.PlatformId].ATokenPrefix}{data.TokenName} ≈{' '}
                        {ToFixNum(
                          (tokenRates[tokenCurrency.symbol] || NaN) /
                          (tokenRates[data.TokenName] || NaN)
                        )}{' '}
                        {tokenCurrency.symbol}
                      </>
                    )
                  )}
                </div>
                <div className="right">
                  Fee: {data.SubscriptionCharge ? `$${data.SubscriptionCharge}` : "-"}
                </div>
              </div>
            </div>

            <div className="est-interest">
              <div className="text">{t('Est. interest')}</div>
              <div className="est-value">
                <span>
                  {ToFixNum(
                    Number(receiveAmount) * (data.product_market?.Apy || 0)
                  )}
                </span>
                <span>&nbsp;{PlatformMap[data.PlatformId].ATokenPrefix + data.TokenName}</span>
                <span>/{t('Year')}</span>
              </div>
            </div>

            <div className='payment'>
              <label>Pay with</label>
              <div className='details'>
                {
                  stakeChannel === StakeChannel.Fiat &&
                  <>
                    <img src={WalletBankcard} />
                    <p>Third-party Payment</p>
                    <mark>Hot</mark>
                  </>
                }
                {
                  stakeChannel === StakeChannel.Crypto &&
                  <>
                    <img src={provider?.isMetaMask ? WalletMetamask : provider?.isWalletConnect ? WalletWC : WalletOffBlack} />
                    <p>{address ? trimAddress(address) : "Crypto wallet"}</p>
                    {address &&
                      <div role="button" className='switch-wallet' onClick={() => connectWallet()} >
                        {t('Switch wallet')} <ArrowRightBlue />
                      </div>
                    }
                  </>
                }
              </div>
            </div>

            <div className="action">
              {profile ?
                <Button
                  className="primary action-btn"
                  loading={isLoading}
                  disabled={actionState?.disabled}
                  onClick={() => {
                    handleContinue()
                  }}
                >
                  {actionState?.text}
                </Button>
                : (
                  <div className="action-btn">
                    <SigninButton />
                  </div>
                )}
            </div>
          </>
        )}
      </div >
      <Modal className='product-details-verify-dialog'
        onClose={() => setOpenVerifyDialog(false)}
        onOpen={() => setOpenVerifyDialog(true)}
        open={isOpenVerifyDialog}
        size="tiny"
        closeIcon
      >
        <Modal.Header>
          <img src={CloseClear} />
        </Modal.Header>
        <Modal.Content>
          <VerificationMessage
            onBack={() => { setOpenVerifyDialog(false) }}
          />
        </Modal.Content>

      </Modal>

      <TradeConfirmModal
        trade={trade}
        open={openConfirmModal}
        onClose={(isConfirm?: boolean) => {
          setOpenConfirmModal(false)
          if (isConfirm) {
            setOpenPaymentModal(true)
          } else {
            setTrade(undefined)
          }
        }}
      />

      {trade &&
        <TradePaymentModal
          open={openPaymentModal}
          onClose={() => {
            setOpenPaymentModal(false)
          }}
          trade={trade}
        />
      }
    </div >
  )
}
